import React, { useContext, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  makeStyles,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import useAbstractProvider from 'providers/AbstractProvider'
import useAbstractMutator from 'providers/AbstractMutator'
import { StoreContext } from 'App'
import ConfirmationDialog from 'components/organisms/Dialog/ConfirmationDialog'
import { ExpandMore, HighlightOff, Save, Add, AddCircle } from '@material-ui/icons'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { useHistory, useParams } from 'react-router-dom'
import ProductsApi from 'api/products'
import FullScreenLoader from 'components/organisms/FullScreenLoader/FullScreenLoader'
import { ProductFullDataType } from 'types/Product'
import { LearningMaterialsFullType } from 'stores'
import AdminLayout from 'layouts/AdminLayout'
import { showToast } from 'utils'
import { FALLBACK_LANGUAGE } from 'helpers/configuration'
import { useTranslation } from 'react-i18next'
import Breadcrumbs from 'components/organisms/CustomBreadcrumbs/CustomBreadcrumbs'

type ParamsType = {
  channelId: string
}

type ValuesByLanguageType = {
  code: string
  translations: {
    [key: string]: {
      name: string
    }
  }
}

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
  },
  buttons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  accordionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 15,
  },
  accordionTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  accordionName: { width: '90%' },
  accordionDetails: {
    display: 'flex',
    position: 'relative',
  },
  accordionPhotoDiv: {
    marginRight: '5%',
  },
  photoUploadImage: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '10px 0 0 0',
    boxShadow: '0px -1px 10px 1px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
  accordionDataDiv: {
    height: '100%',
  },
  accordionLoadingDiv: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    gap: 5,
  },
  languageChooser: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginRight: 20,
  },
  productCodeDiv: {
    width: 400,
    marginRight: 50,
  },
})

const defaultValueProperties = {
  name: '',
}

const NEW_PRODUCT_DEFAULT_ID = 123456789

const [DeleteConfirmationDialog, NewProductCancelConfirmationDialog] =
  Array(2).fill(ConfirmationDialog)

const Products = () => {
  const store = useContext(StoreContext) as {
    language: string
    languages: string[]
    learningMaterialsFull: LearningMaterialsFullType
    // eslint-disable-next-line no-unused-vars
    setProductsFullData: (values: ProductFullDataType[]) => void
    // eslint-disable-next-line no-unused-vars
    getChannelNameById: (channelId: number) => string
  }

  const { t } = useTranslation()

  const classes = useStyles()
  const history = useHistory()
  const { channelId: channelIdParam } = useParams<ParamsType>()
  const channelId = Number(channelIdParam)

  const [products, setProducts] = useState<ProductFullDataType[] | undefined>()

  const [selectedProduct, setSelectedProduct] = useState<ValuesByLanguageType>({
    code: '',
    translations: {
      [FALLBACK_LANGUAGE]: defaultValueProperties,
    },
  })
  const [selectedProductOriginalValues, setSelectedProductOriginalValues] =
    useState<ValuesByLanguageType>({
      code: '',
      translations: {
        [FALLBACK_LANGUAGE]: defaultValueProperties,
      },
    })

  const [expandedProductId, setExpandedProductId] = useState<number | undefined>()

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [productIdToBeDeleted, setProductIdToBeDeleted] = useState<number | undefined>()

  const [isNewProductCancelConfirmationModalOpen, setIsNewProductCancelConfirmationModalOpen] =
    useState(false)

  const [isNewProductBeingAdded, setIsNewProductBeingAdded] = useState(false)

  const productsProvider = useAbstractProvider(ProductsApi.getProductsFullData, null, false)
  const editProductMutator = useAbstractMutator(ProductsApi.editProduct)
  const addProductMutator = useAbstractMutator(ProductsApi.addProduct)
  const deleteProductMutator = useAbstractMutator(ProductsApi.deleteProduct)

  useEffect(() => {
    if (!store.learningMaterialsFull.products.length) {
      console.log('Fetch Products From DB')
      productsProvider.refetch()
    } else {
      console.log('Get Producs from Cache')
      setProducts(
        store.learningMaterialsFull.products.filter((item) => item.channelId === channelId),
      )
    }
  }, [])

  useEffect(() => {
    if (productsProvider.data) {
      store.setProductsFullData(productsProvider.data)
      setProducts(productsProvider.data.filter((item) => item.channelId === channelId))
    }
  }, [productsProvider.data])

  useEffect(() => {
    if (expandedProductId) {
      const correspondingProduct = products.find((item) => item.id === expandedProductId)
      const product = {
        translations: correspondingProduct.translations,
        code: correspondingProduct.code,
      }
      setSelectedProduct(product)
      setSelectedProductOriginalValues(product)
    }
  }, [expandedProductId])

  useEffect(() => {
    if (editProductMutator.data || addProductMutator.data || deleteProductMutator.data) {
      setIsNewProductBeingAdded(false)
      productsProvider.refetch(channelId)
    }
  }, [editProductMutator.data, addProductMutator.data, deleteProductMutator.data])

  const handleProductExpand = (productId: number) => {
    if (isNewProductBeingAdded) {
      setIsNewProductCancelConfirmationModalOpen(true)
    } else {
      setExpandedProductId((existingId) => (existingId === productId ? undefined : productId))
    }
  }

  const handleDelete = () => {
    deleteProductMutator.mutate(productIdToBeDeleted)
  }

  const handleProductAddingCancel = () => {
    setProducts((arr) => arr.slice(0, -1))
    setIsNewProductBeingAdded(false)
    setExpandedProductId(undefined)
  }

  const handleEdit = () => {
    if (
      !selectedProduct.code ||
      Object.keys(selectedProduct).some((item) => selectedProduct[item].name === '')
    )
      return showToast(t('fill_everything'))
    if (JSON.stringify(selectedProduct) === JSON.stringify(selectedProductOriginalValues))
      return showToast(t('admin_learning_panel.nothing_has_changed'))
    const data = { translations: selectedProduct.translations }
    editProductMutator.mutate(expandedProductId, data)
    setSelectedProductOriginalValues(selectedProduct)
    return null
  }

  const handleAdd = () => {
    if (
      !selectedProduct.code ||
      Object.keys(selectedProduct).some((item) => selectedProduct[item].name === '')
    )
      return showToast(t('fill_everything'))
    const data = {
      channelId,
      code: selectedProduct.code,
      translations: selectedProduct.translations,
    }
    addProductMutator.mutate(data)
    return null
  }

  const handleProductAdd = () => {
    if (isNewProductBeingAdded) {
      return handleAdd()
    }
    setProducts((arr) => [
      ...arr,
      {
        id: NEW_PRODUCT_DEFAULT_ID,
        code: '',
        channelId,
        createdAt: '',
        translations: {
          [FALLBACK_LANGUAGE]: {
            name: 'Add Product DE',
          },
        },
      },
    ])
    setIsNewProductBeingAdded(true)
    setExpandedProductId(NEW_PRODUCT_DEFAULT_ID)
    return null
  }

  const handleNameChange = (newName: string, lang: string) => {
    setSelectedProduct((obj) => ({
      ...obj,
      translations: {
        ...obj.translations,
        [lang]: { ...obj[lang], name: newName },
      },
    }))
  }

  const handleCodeChange = (newCode: string) => {
    setSelectedProduct((obj) => ({
      ...obj,
      code: newCode,
    }))
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <div className={classes.container}>
            {!products ? (
              <FullScreenLoader />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={10}>
                    <Breadcrumbs />
                    <Text variant="h3">{t('admin_learning_panel.products')}</Text>
                  </Grid>
                  <Grid item xs={2} className={classes.buttons}>
                    <IconButton onClick={handleProductAdd} color="primary" component="span">
                      <AddCircle />
                    </IconButton>
                  </Grid>
                  <hr />
                  <hr />
                  <Grid item xs={12}>
                    {products.map((product, index) => (
                      <Accordion
                        key={product.id}
                        expanded={expandedProductId === product.id}
                        disabled={editProductMutator.loading || addProductMutator.loading}
                      >
                        <AccordionSummary
                          IconButtonProps={{
                            onClick: () => handleProductExpand(product.id),
                          }}
                          expandIcon={<ExpandMore />}
                        >
                          <div
                            onClick={() => {
                              if (!isNewProductBeingAdded || index !== products.length - 1) {
                                history.push(
                                  `/admin-view/learning-material-managment/channelId=${channelId}/productId=${product.id}`,
                                )
                              }
                              return null
                            }}
                            className={classes.accordionHeader}
                          >
                            <div className={classes.accordionName}>
                              <Text variant="h4">
                                {product.translations[store.language]?.name ||
                                  product.translations[FALLBACK_LANGUAGE].name}
                              </Text>
                            </div>
                          </div>
                          <div className={classes.accordionTooltip}>
                            {product.id === NEW_PRODUCT_DEFAULT_ID ? (
                              <Tooltip placement="top" title={t('add')}>
                                <IconButton onClick={handleAdd} size="small">
                                  <Add fontSize="medium" color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : expandedProductId === product.id ? (
                              <Tooltip placement="top" title={t('edit')}>
                                <IconButton onClick={handleEdit} size="small">
                                  <Save fontSize="medium" color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <Tooltip placement="top" title={t('delete')}>
                              <IconButton
                                onClick={() => {
                                  if (isNewProductBeingAdded) {
                                    setIsNewProductCancelConfirmationModalOpen(true)
                                  } else {
                                    setProductIdToBeDeleted(product.id)
                                    setIsDeleteConfirmationModalOpen(true)
                                  }
                                }}
                                size="small"
                              >
                                <HighlightOff fontSize="medium" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {product.id === expandedProductId && (
                            <>
                              {expandedProductId && (
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <MaterialInput
                                      disabled={
                                        addProductMutator.loading ||
                                        editProductMutator.loading ||
                                        product.id !== NEW_PRODUCT_DEFAULT_ID
                                      }
                                      type="text"
                                      label={t('code')}
                                      name="code"
                                      value={selectedProduct.code}
                                      onChange={handleCodeChange}
                                      size="small"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MaterialInput
                                      disabled={
                                        editProductMutator.loading || addProductMutator.loading
                                      }
                                      value={selectedProduct.translations.de.name}
                                      size="small"
                                      onChange={(value) => handleNameChange(value, 'de')}
                                      type="text"
                                      label={t('name')}
                                      name="name"
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              <div className={classes.accordionLoadingDiv}>
                                {editProductMutator.loading || addProductMutator.loading ? (
                                  <CircularProgress size="1.5rem" />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </div>
          <DeleteConfirmationDialog
            open={isDeleteConfirmationModalOpen}
            handleClose={() => setIsDeleteConfirmationModalOpen(false)}
            title={`${t('admin_learning_panel.delete_product')} ${
              products?.find((item) => item.id === productIdToBeDeleted)?.translations[
                store.language
              ]?.name ||
              products?.find((item) => item.id === productIdToBeDeleted)?.translations[
                FALLBACK_LANGUAGE
              ]?.name
            } ?`}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleDelete}
          />
          <NewProductCancelConfirmationDialog
            open={isNewProductCancelConfirmationModalOpen}
            handleClose={() => setIsNewProductCancelConfirmationModalOpen(false)}
            title={t('admin_learning_panel.cancel_product_add_question')}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleProductAddingCancel}
          />
        </>,
      )}
    </ProtectedPage>
  )
}

export default Products
