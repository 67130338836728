import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getArgumentations(conversationGroupId) {
  const res = await API.get(`${API_URL}/conversation-argumentations/${conversationGroupId}`, true)
  return res
}

async function saveArgumentation(argumentation) {
  const res = await API.post(
    `${API_URL}/conversation-argumentations`,
    {
      ...argumentation,
    },
    true,
  )
  return res
}

async function editArgumentation(argumentation) {
  const res = await API.put(
    `${API_URL}/conversation-argumentations`,
    {
      ...argumentation,
    },
    true,
  )
  return res
}

async function deleteArgumentation(argumentationId) {
  const res = await API.remove(`${API_URL}/conversation-argumentations/${argumentationId}`, true)
  return res
}

const ArgumentationsApi = {
  getArgumentations,
  saveArgumentation,
  editArgumentation,
  deleteArgumentation,
}

export default ArgumentationsApi
