import React from 'react'
import { Route as ReactRoute } from 'react-router-dom'
import AdminSendEmail from 'pages/AdminSendEmail'
import AllUsersPage from 'pages/AllUsersPage'
import UserDetailPage from 'pages/UserDetailPage'
import AddNewUser from 'pages/AddNewUser'
import AddNewTeam from 'pages/AddNewTeam'
import ManagePage from 'pages/ManagePage'
import AllTeamsPage from 'pages/AllTeamsPage'
import TeamDetailPage from 'pages/TeamDetailPage'
import Channels from 'pages/Channels'
import ChannelImport from 'pages/ChannelImport'
import StaticContentsPage from 'pages/StaticContentsPage'
import StaticContentEditPage from 'pages/StaticContentEditPage'
import Products from 'pages/Products'
import Phases from 'pages/Phases'
import ConversationGroups from 'pages/ConversationGroups'
import Conversations from 'pages/Conversations'
import TestNotificationPage from 'pages/TestNotificationPage'
import AdminLicensing from 'pages/AdminLicensing'
import * as Sentry from '@sentry/react'
import AllAvatars from './pages/AllAvatarsPage'
import StatisticsDashboard from './pages/MentorsPage/StatisticsDashboard'

const Route = Sentry.withSentryRouting(ReactRoute)

const AdminRoutes = () => (
  <>
    <Route exact path="/admin-view/send-email">
      <AdminSendEmail />
    </Route>
    <Route exact path="/admin-view/statistics">
      <StatisticsDashboard />
    </Route>
    <Route exact path="/admin-view/all-users">
      <AllUsersPage />
    </Route>
    <Route exact path="/admin-view/user-detail/:userId">
      <UserDetailPage />
    </Route>
    <Route exact path="/admin-view/add-user">
      <AddNewUser />
    </Route>
    <Route exact path="/admin-view/add-team">
      <AddNewTeam />
    </Route>
    <Route exact path="/admin-view/all-teams">
      <AllTeamsPage />
    </Route>
    <Route exact path="/admin-view/team-detail/:teamId">
      <TeamDetailPage />
    </Route>
    <Route exact path="/admin-view/avatars">
      <AllAvatars />
    </Route>
    <Route exact path="/admin-view/learning-material-managment">
      <Channels />
    </Route>
    <Route exact path="/admin-view/learning-material-managment-import">
      <ChannelImport />
    </Route>
    <Route exact path="/admin-view/learning-material-managment/channelId=:channelId">
      <Products />
    </Route>
    <Route exact path="/admin-view/learning-material-managment/channelId=:channelId/productId=:productId">
      <Phases />
    </Route>
    <Route exact path="/admin-view/learning-material-managment/channelId=:channelId/productId=:productId/phaseId=:phaseId">
      <ConversationGroups />
    </Route>
    <Route
      exact
      path="/admin-view/learning-material-managment/channelId=:channelId/productId=:productId/phaseId=:phaseId/conversationGroupId=:conversationGroupId"
    >
      <Conversations />
    </Route>
    <Route exact path="/admin-view/static-contents">
      <StaticContentsPage />
    </Route>
    <Route exact path="/admin-view/static-contents/:staticContentId">
      <StaticContentEditPage />
    </Route>
    <Route exact path="/admin-view/test-notification">
      <TestNotificationPage />
    </Route>
    <Route exact path="/admin-view/licensing">
      <AdminLicensing />
    </Route>
    <Route exact path="/">
      <ManagePage />
    </Route>
  </>
)

export default AdminRoutes
