import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { StoreContext } from 'App'
import useAbstractProvider from 'providers/AbstractProvider'
import LearningTypesApi from 'api/learningTypes'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  bottomNavigation: {
    padding: '0 16px',
  },
  bottomNavigationBar: {
    top: 'auto',
    bottom: 0,
  },
  bottomNavigationAction: {
    borderRadius: 0,
    backgroundColor: '#00008f',
  },
  bottomNavigationActionSelected: {
    backgroundColor: '#1437bc',
  },
}))

const links = ['Produkte', 'Erarbeiten', 'Trainieren']
const linkLabels = ['products', 'exercise', 'training']

export interface LearningBottomNavigationProps {}

const LearningBottomNavigation: FC<LearningBottomNavigationProps> = () => {
  const store = useContext(StoreContext)
  const history = useHistory()
  const classes = useStyles()
  const { data: learningTypes } = useAbstractProvider(LearningTypesApi.getLearningTypes)
  const { t } = useTranslation()

  return (
    <AppBar
      position="static"
      color="inherit"
      classes={{ root: classes.bottomNavigation }}
      className={classes.bottomNavigationBar}
    >
      <Box display="flex" justifyContent="space-between" my={1}>
        {links?.map((link, index) => (
          <Button
            key={link}
            variant="contained"
            size="medium"
            color="primary"
            className={classNames(
              classes.bottomNavigationAction,
              links[index] === link ? classes.bottomNavigationActionSelected : null,
            )}
            onClick={() => {
              if (link === 'Produkte') {
                history.push(`/learn/channelId=${store.selectedChannel.id}/products`)
              } else if (link === 'Erarbeiten') {
                history.push(`/learn/channelId=${store.selectedChannel.id}`)
                store.setSelectedLearningType(learningTypes[0])
              } else if (link === 'Trainieren') {
                history.push(`/learn/channelId=${store.selectedChannel.id}`)
                store.setSelectedLearningType(learningTypes[1])
              }
            }}
          >
            {t(`learning.bottom_navigation_${linkLabels[links.indexOf(link)]}`)}
          </Button>
        ))}
      </Box>
    </AppBar>
  )
}

export default observer(LearningBottomNavigation)
