import { API_URL } from '../helpers/configuration'
import Api from '.'

export interface ChannelImportParams {
  file: File
  channelId?: number
  lang: string
}

export type ChannelImportStatusState = 'READY' | 'PROCESSING' | 'FINISHED' | 'ERROR'

export interface ChannelImportStatusInfo {
  state: ChannelImportStatusState
  completedSteps?: number
  totalSteps?: number
}

const API = Api()

async function getChannels() {
  const res = await API.get(`${API_URL}/channels`, true)
  return res
}

const getChannelById = async ({ channelId, query }) => {
  const res = await API.get(`${API_URL}/channels/${channelId}${query ? `?q=${query}` : ''}`, true)
  return res
}

const deleteChannel = async (channelId: number | string) => {
  const res = await API.remove(`${API_URL}/channels/${channelId}`, true)
  return res
}

const addChannel = async (data) => {
  const res = await API.post(`${API_URL}/channels/add`, data, true)
  return res
}

const editChannel = async (channelId: number | string, data) => {
  const res = await API.post(`${API_URL}/channels/${channelId}`, data, true)
  return res
}

const getChannelFullData = async () => {
  const res = await API.get(`${API_URL}/channels/full-data`, true)
  return res
}

const getChannelActivity = async (channelId: number | string) => {
  const res = await API.get(`${API_URL}/channels/activity/${channelId}`, true)
  return res
}

const importChannel = async (params: ChannelImportParams) => {
  const formData = new FormData()
  formData.append('data', params.file)
  formData.append('lang', params.lang)

  if (params.channelId) {
    formData.append('channelId', params.channelId.toString())
  }

  return API.post(`${API_URL}/channels/import`, formData, true)
}

const ChannelsApi = {
  getChannels,
  getChannelById,
  addChannel,
  editChannel,
  getChannelActivity,
  getChannelFullData,
  deleteChannel,
  importChannel,
}

export default ChannelsApi
