import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getRecordingsByGroupId(groupId) {
  const res = await API.get(`${API_URL}/recordings/${groupId}`, true)
  return res
}

async function editRecording(recording) {
  const res = await API.put(
    `${API_URL}/recordings`,
    {
      ...recording,
    },
    true,
  )
  return res
}

async function deleteRecording(id) {
  const res = await API.remove(`${API_URL}/recordings/${id}`, true)
  return res
}

const ConversationRecordingsApi = { getRecordingsByGroupId, editRecording, deleteRecording }

export default ConversationRecordingsApi
