import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getConversationAssets(conversationId) {
  const res = await API.get(`${API_URL}/conversation-assets/${conversationId}`, true)
  return res
}

async function uploadRecording(data, options) {
  const res = await API.post(`${API_URL}/files/recording`, data, true, options)
  return res
}

const ConversationAssetsApi = { getConversationAssets, uploadRecording }

export default ConversationAssetsApi
