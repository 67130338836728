import { makeStyles } from '@material-ui/core'
import React, { ReactElement } from 'react'

import Footer from 'components/organisms/Footer/Footer'
import Header from 'components/organisms/Header/Header'
import LearningBottomNavigation from 'components/learning/LearningBottomNavigation'
import useMedia from 'hooks/useMedia'
import { useLocation } from 'react-router-dom'
import BackButton from 'components/atoms/BackButton/BackButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
    marginBottom: 50,
  },
  adminLayoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  outerContent: {
    height: 'calc(100vh - 3.75rem - 4rem)',
    margin: '0px auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    width: '100%',
    padding: '0px 20px',
    maxWidth: '1320px',
    '@media (max-width: 600px)': {
      padding: '0px 16px',
    },
  },
})

const AdminLayout = ({ children }) => {
  const classes = useStyles()
  const { md: isDesktop, isLoading: isMediaLoading } = useMedia()
  const location = useLocation()
  const path = location.pathname
  const foundLearn = path.split('/')[1] === 'learn'

  const { t } = useTranslation()

  return (
    <div className={classes.adminLayoutContainer}>
      <Header />
      <div className={classes.outerContent}>
        <div className={classes.content}>
          <div className={classes.container}>
            <BackButton text={t('back')} />
            {children}
          </div>
        </div>
      </div>
      {!isDesktop && foundLearn && !isMediaLoading ? <LearningBottomNavigation /> : null}
      <Footer />
    </div>
  )
}

AdminLayout.getLayout = (page): ReactElement => <AdminLayout>{page}</AdminLayout>

export default AdminLayout
