import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function sendInvitationEmail(userId) {
  const res = await API.get(`${API_URL}/mail/sendInvitationEmail/${userId}`, true)
  return res
}

async function sendEmailToNewUsers(daysBefore) {
  const res = await API.get(`${API_URL}/mail/sendInvitationEmailToNewUsers/${daysBefore}`, true)
  return res
}

export default {
  sendInvitationEmail,
  sendEmailToNewUsers,
}
