import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

const getPhasesFullData = async () => {
  const res = await API.get(`${API_URL}/phases/full-data`, true)
  return res
}

const getPhaseById = async (phaseId: number) => {
  const res = await API.get(`${API_URL}/phases/${phaseId}`, true)
  return res
}

const editPhase = async (phaseId: number, data: any) => {
  const res = await API.post(`${API_URL}/phases/${phaseId}`, data, true)
  return res
}

const deletePhase = async (phaseId: number) => {
  const res = await API.remove(`${API_URL}/phases/${phaseId}`, true)
  return res
}

const addPhase = async (data: any) => {
  const res = await API.post(`${API_URL}/phases/`, data, true)
  return res
}

const editPhaseOrder = async (data: any) => {
  const res = await API.post(`${API_URL}/phases/order`, data, true)
  return res
}

const PhasesApi = {
  getPhasesFullData,
  getPhaseById,
  editPhase,
  deletePhase,
  addPhase,
  editPhaseOrder,
}

export default PhasesApi
