const customIndexOf = (string: string, value: string) => string.indexOf(value)

const isUserSoftwareAutomated = () => {
  if (
    /HeadlessChrome/.test(navigator.userAgent) ||
    /PhantomJS/.test(navigator.userAgent) ||
    navigator.webdriver ||
    !Function.prototype.bind ||
    Function.prototype.bind.toString().replace(/bind/g, 'Error') !== Error.toString() ||
    Function.prototype.toString.toString().replace(/toString/g, 'Error') !== Error.toString()
  ) {
    return true
  }
  try {
    // @ts-ignore
    null[0]()
  } catch (e) {
    if (customIndexOf(e.stack, 'phantomjs') > -1) {
      return true
    }
  }
  return false
}

export default isUserSoftwareAutomated
