import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { BrowserRouter } from 'react-router-dom'
import isUserSoftwareAutomated from 'helpers/isUserSoftwareAutomated'
import { recaptchaPublicSiteKey } from './constants'

import App from './App'
import * as serviceWorker from './serviceWorker'

import './i18n'

ReactDOM.render(
  // <React.StrictMode>
  <>
    {isUserSoftwareAutomated() ? null : (
      <Suspense fallback="">
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaPublicSiteKey}>
          <BrowserRouter>
            <App />
          </BrowserRouter>{' '}
        </GoogleReCaptchaProvider>
      </Suspense>
    )}
  </>,
  // </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
