import React, {useContext, useEffect, useState} from 'react'
import { Grid, Link, makeStyles, Paper, Typography } from '@material-ui/core'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { useHistory } from 'react-router-dom'
import AuthLayout from 'layouts/AuthLayout'
import {getTheRightRole, ROLES, showToast} from 'utils'
import UnprotectedPage from 'components/organisms/UnprotectedPage/UnprotectedPage'
import AuthApi from 'api/auth'
import useAbstractMutator from 'providers/AbstractMutator'
import { useTranslation } from 'react-i18next'
import validateString from 'helpers/validateString'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {StoreContext} from '../App'
import useAbstractProvider from '../providers/AbstractProvider'
import UserApi from '../api/user'

const useStyles = makeStyles({
  signInContainer: {
    height: 'auto',
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: "center",
    background: '#fff',
    padding: '30px 30px 30px 30px',
    borderRadius: '0px',
  },
  signInHeading: {
    fontWeight: 800,
    textAlign: 'center',
  },
  errorMessage: {
    height: '50px',
  },
  signInButton: {
    width: '100%',
    marginTop: 10,
  },
  signUpLink: {
    textDecoration: 'underline',
    marginLeft: 5,
    cursor: 'pointer',
  },
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 20,
  },
  externalLogin: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 10,
  },
  externalLoginButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  externalLoginText: {
    marginBottom: 10,
  },
  googleLogin: {
    marginRight: 10,
  },
})

const ClassicLoginPage = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const [error, setError] = useState('')
  const { t } = useTranslation()
  const {
    data: loginData,
    error: loginError,
    status: loginStatus,
    mutate: login,
  } = useAbstractMutator(AuthApi.login)
  const store = useContext(StoreContext)

  const { executeRecaptcha } = useGoogleReCaptcha()

  async function handleLogin() {
    await executeRecaptcha('classic_login')
    // history.push("/welcome");
    const areValuesValid = validateString.hasUrl(email, password)
    if (!areValuesValid) {
      showToast(t('url_detected_error_msg'))
    } else if (email && password) {
      const trimmedEmail = email.trim()
      login({ email: trimmedEmail, password })
    }
  }

  const { data: currentlyLoggedInUser, status, refetch: refetchCurrentUser } = useAbstractProvider(
    UserApi.getCurrentlyLoggedInUser, null, false
  )

  useEffect(() => {
    if (currentlyLoggedInUser) {
      if (getTheRightRole(currentlyLoggedInUser?.ssoData) === ROLES.manager) {
        store.setSelectedView('manager')
        history.push('/manager-view')
      } else {
        store.setSelectedView('mentor')
        history.push('/mentors-view')
      }
    }
  }, [currentlyLoggedInUser])

  useEffect(() => {
    if (loginData) {
      switch (loginData.role) {
        case 'mentor':
          refetchCurrentUser()
          break
        case 'admin':
        case 'trainee':
        default:
          store.setSelectedView(loginData.role)
          history.push('/')
      }
    } else if (loginError) {
      if (loginStatus === 401)
        setError(`Fehler: Falsche E-Mail oder Passwort. ${loginError.data.message}`)
      else if (loginStatus === 412) setError(loginError.data.message)
      else setError('Error: Something went wrong.')
    }
  }, [loginData, loginError])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleLogin()
      event.preventDefault()
    }
  }
  return (
    <UnprotectedPage>
      {AuthLayout.getLayout(
        <div onKeyDown={handleKeyDown}>
          <Paper className={classes.signInContainer}>
            <h1 className={classes.signInHeading}>{t('sign_in.heading')}</h1>
            <Typography
              align="center"
              variant="subtitle2"
              color="error"
              className={classes.errorMessage}
            >
              {error}
            </Typography>
            <Grid spacing={3} container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <MaterialInput label={t('email')} name="email" value={email} onChange={setEmail} />
              </Grid>

              <Grid item xs={12}>
                <MaterialInput
                  type="password"
                  label={t('password')}
                  name="password"
                  value={password}
                  onChange={setPassword}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomButton
                  color="primary"
                  className={classes.signInButton}
                  onClick={() => handleLogin()}
                >
                  {t('sign_in.button')}
                </CustomButton>
              </Grid>
            </Grid>
            <div className={classes.signUpContainer}>
              <Link href="/reset-password">
                <span className={classes.signUpLink}>{t('sign_in.forgot_password')}</span>
              </Link>
            </div>
          </Paper>
        </div>,
      )}
    </UnprotectedPage>
  )
}

export default ClassicLoginPage
