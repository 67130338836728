import { StoreContext } from 'App'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { Grid, Paper } from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  pointer: {
    cursor: 'pointer',
  },
  highlightOnHover: {
    padding: 4,
    border: '2px solid transparent',
    // border: '2px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      border: '2px solid #0000',
      boxShadow:
        '0px 0px 5px -2px #5c6bc0, 0px 2px 5px 0px #5c6bc0, 0px 1px 5px 0px #5c6bc0 !important',
    },
  },
  selectedItem: {
    border: '2px solid #2c5de5 !important',
  },
  text: {
    marginTop: 7,
    textAlign: 'center',
  },
  item: {
    marginRight: '16px',
  },
})

export interface AvatarSelectorProps {
  onChange: Function
  smallPictures?: boolean
}

const AvatarSelector: React.SFC<AvatarSelectorProps> = ({ onChange, smallPictures }) => {
  const classes = useStyles()
  const store = useContext(StoreContext) as any
  const [selectedAvatar, setSelectedAvatar] = useState({
    id: null,
  })

  useEffect(() => {
    setSelectedAvatar(store.selectedAvatar)
  }, [store.selectedAvatar])

  return (
    <Grid container spacing={smallPictures ? 1 : undefined}>
      {store.avatars
        .filter((avatar) => avatar.language === store.language)
        .map((avatar) => (
          <Grid
            item
            xs={smallPictures ? 6 : 3}
            sm={smallPictures ? 2 : 3}
            className={smallPictures ? undefined : classes.item}
            style={smallPictures ? {} : { maxWidth: '162px' }}
            key={avatar.id}
          >
            <Paper
              variant="outlined"
              square
              onClick={() => {
                setSelectedAvatar(avatar)
                onChange(avatar)
              }}
              style={smallPictures ? {} : { height: '162px' }}
              className={classNames(
                classes.pointer,
                classes.highlightOnHover,
                selectedAvatar?.id === avatar.id ? classes.selectedItem : null,
              )}
            >
              <img
                alt="avatar"
                style={smallPictures ? { display: 'block', maxWidth: '100%' } : {}}
                width={smallPictures ? undefined : '150'}
                src={`${store.cloudfrontUrl}/${avatar.profileImage}`}
              />
            </Paper>
            <div className={classes.text}>{avatar.name}</div>
          </Grid>
        ))}
    </Grid>
  )
}

export default observer(AvatarSelector)
