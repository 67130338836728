import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getArgumentationTemplateByGroudId(conversationGroupId) {
  const res = await API.get(`${API_URL}/argumentation-templates/${conversationGroupId}`, true)
  return res
}

const ArgumentationTemplatesApi = {
  getArgumentationTemplateByGroudId,
}

export default ArgumentationTemplatesApi
