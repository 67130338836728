import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getLearningTypes() {
  const res = await API.get(`${API_URL}/learning-types`, true)
  return res
}

const LearningTypesApi = { getLearningTypes }

export default LearningTypesApi
