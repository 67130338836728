import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx-js-style'
import moment from 'moment-timezone'

const exportXlsx = (fileName: string, data: { title: string; data: any[] }[]) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const wb = XLSX.utils.book_new()

  data.forEach((item) => {
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(item.data), item.title)
  })

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

  FileSaver.saveAs(new Blob([excelBuffer], { type: fileType }), fileName + fileExtension)
}

export const exportUserStatistics = (fileName: string, data: any, filterInfo: any) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const wb = XLSX.utils.book_new()

  // rows with filter info
  const rows = [[
    { v: "Start date", t: "s", s: { font: { bold: true } } },
    null,
    null,
    null,
    filterInfo.from,
  ],
    [
      { v: "End date", t: "s", s: { font: { bold: true } } },
      null,
      null,
      null,
      filterInfo.to,
    ],
    [
      { v: "Channel", t: "s", s: { font: { bold: true } } },
      null,
      null,
      null,
      filterInfo?.channel ?? 'all channels',
    ],
    [
      { v: "Product", t: "s", s: { font: { bold: true } } },
      null,
      null,
      null,
      filterInfo?.product ?? 'all products',
    ],
    [
      { v: "Created", t: "s", s: { font: { bold: true } } },
      null,
      null,
      null,
      (moment().format('DD/MM/YYYY')),
    ],
  ]
  const ws = XLSX.utils.aoa_to_sheet(rows)

  XLSX.utils.sheet_add_aoa(ws, [
    [null],[null],[null],
  ], {origin: -1})

  // header with styling
  const headerRow = [
      { v: "Instradation", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "C-Nummer", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "Nachname", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "Vorname", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "No of Logins", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "No of Argumentations", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "No of recorded Videos", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "No of Feedbacks", t: "s", s: { font: { bold: true }, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
      { v: "ø Time spent per Login (min)", t: "s", s: { font: { bold: true}, alignment: { wrapText: true, horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "deebf7" } }, border: {bottom: { style: 'thin', color: '000000' }} } },
    ]

  XLSX.utils.sheet_add_aoa(ws, [headerRow],{origin: -1})
  XLSX.utils.sheet_add_aoa(ws, data.map((user) => [
      user.instradation,
      user.cNumber,
      user.lastName, user.firstName,
      user.loginsCount,
      user.argumentationsCount,
      user.recordingsCount,
      user.feedbacksCount,
      user.avgLoginTime,
    ])
    , {origin: -1})

  XLSX.utils.book_append_sheet(wb, ws)

  // row height
  if(!ws["!rows"]) ws["!rows"] = [{}, {}, {}, {}, {}, {}, {}, {}, {hpx : 30}]

  // column styling
  if(!ws["!cols"]) ws["!cols"] = []
  for (let colIndex = 0; colIndex < 9; colIndex++) {
    if(!ws["!cols"][colIndex]) ws["!cols"][colIndex] = {wpx: 90}
  }
  
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true })

  FileSaver.saveAs(new Blob([excelBuffer], { type: fileType }), fileName + fileExtension)
}

export default exportXlsx
