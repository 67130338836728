import { Grid, Paper, Tabs, Tab, makeStyles } from '@material-ui/core'

import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import { StoreContext } from 'App'
import ConversationAssetsApi from 'api/conversationAssets'
import ConversationsProgressApi from 'api/conversationProgress'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import useAbstractMutator from 'providers/AbstractMutator'
import useAbstractProvider from 'providers/AbstractProvider'
import { useTranslation } from 'react-i18next'
import { sanitize } from 'dompurify'
import { observer } from 'mobx-react-lite'
import { isDesktop } from 'react-device-detect'

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: 20,
  },
  tab: {
    fontSize: '20px',
  },
  script: {
    overflow: 'scroll',
  },
  avatarSideContainer: {
    height: '100%',
  },
  conversationsLinkHidden: {
    visibility: 'hidden',
  },
  tabsSide: {
    height: 'calc(100% - 333px)',
  },
  assetsSide: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100% - 68px)',
  },
  customTabRoot: {
    '& .Mui-selected': {
      color: theme.palette.info.main,
      fontWeight: 700,
    },
  },
  customTabIndicator: {
    backgroundColor: theme.palette.info.main,
  },
}))

export interface LearningMainProps {
  selectedConversation: any
  videoSrc: any
  conversationText: string
}

const LearningMain: FC<LearningMainProps> = ({ selectedConversation, videoSrc, conversationText }) => {
  const store = useContext(StoreContext)
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState(0)
  const [modalStatus, setModalStatus] = useState(false)
  const [assets, setAssets] = useState(null)
  const { data: saveConversationProgressData, mutate: saveConversationProgress } =
    useAbstractMutator(ConversationsProgressApi.saveConversationsProgress)

  const { data: conversationsProgress, refetch: refetchConversationsProgress } =
    useAbstractProvider(ConversationsProgressApi.getConversationsProgress, null, false)

  const { data: conversationAssets, refetch: refetchConversationAssets } = useAbstractProvider(
    ConversationAssetsApi.getConversationAssets,
    null,
    false,
  )

  useEffect(() => {
    if (conversationAssets) {
      setAssets(conversationAssets)
    }
  }, [conversationAssets])

  useEffect(() => {
    if (!selectedConversation) {
      setAssets(null)
    } else {
      refetchConversationAssets(selectedConversation.id)
      // const foundVideo = selectedConversation.videos.find(
      //   (vid) => vid.languageCode === store.language && vid.gender === store.selectedAvatar.gender,
      // )
      // setScripts(foundVideo?.script)
    }
  }, [selectedConversation])

  useEffect(() => {
    if (saveConversationProgressData) {
      refetchConversationsProgress(store?.selectedProductCategory?.id)
    }
  }, [saveConversationProgressData])

  const componentHeight = 333

  useEffect(() => {
    if (conversationsProgress) {
      store.setConversationsProgress(conversationsProgress)
    }
  }, [conversationsProgress])

  function completeConversation(id) {
    saveConversationProgress(id)
  }

  const vidRef = useRef(null)

  useEffect(() => {
    if (vidRef) {
      store.setVideoPlayer(vidRef)
    }
  }, [vidRef, videoSrc])

  const classes = useStyles()

  function mouseEnter(e) {
    vidRef.current.setAttribute('controls', 'controls')
  }

  function mouseLeave(e) {
    vidRef.current.removeAttribute('controls')
  }

  return (
    <Grid container className={classes.avatarSideContainer}>
      <Grid item xs={12}>
        <Paper
          variant="outlined"
          square
          style={{ height: isDesktop ? componentHeight : null, padding: 0, border: '0px' }}
        >
          <img
            width="100%"
            style={{ display: videoSrc ? 'none' : 'block' }}
            src={`${store.cloudfrontUrl}/${store.selectedAvatar.posterImage}`}
            alt="avatarPoster"
          />
          <video
            style={{ display: videoSrc ? 'block' : 'none' }}
            ref={vidRef}
            width="100%"
            controls
            key={videoSrc}
            autoPlay
            playsInline
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            onEnded={() => completeConversation(selectedConversation.id)}
            poster={`${store.cloudfrontUrl}/${store.selectedAvatar.posterImage}`}
          >
            <track src="captions_en.vtt" kind="captions" label="english_captions" />
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        </Paper>
      </Grid>
      {selectedConversation ? (
        <Grid item xs={12} className={classes.tabsSide}>
          {store?.selectedProductCategory?.id && selectedConversation ? (
            <Tabs
              className={classes.tabs}
              value={selectedTab}
              onChange={(evt, value) => {
                setSelectedTab(value)
              }}
              classes={{
                root: classes.customTabRoot,
                indicator: classes.customTabIndicator,
              }}
            >
              <Tab className={classes.tab} label={t('learning.illustrations')} />
              <Tab className={classes.tab} label={t('learning.conversation')} />
            </Tabs>
          ) : null}
          {selectedTab === 0 && assets?.length > 0 ? (
            <Grid
              item
              xs={12}
              className={classes.assetsSide}
              onClick={() => setModalStatus(!modalStatus)}
            >
              {assets.map((conversationAsset) => (
                <img
                  style={{ cursor: 'pointer' }}
                  width="100%"
                  key={`asset-${conversationAsset?.id}`}
                  alt={`conversationAsset-${conversationAsset?.name}`}
                  src={`${store.cloudfrontUrl}/${conversationAsset?.url}`}
                />
              ))}
            </Grid>
          ) : null}
          {selectedTab === 1 && conversationText ? (
            <Grid item xs={12} className={classes.assetsSide}>
              <div
                className={classes.script}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitize(conversationText),
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      <CustomModal
        open={modalStatus}
        toggleModal={setModalStatus}
        maxWidth="sm"
        body={
          <Grid container>
            <Grid item xs={12} className={classes.assetsSide}>
              {assets?.map((conversationAsset) => (
                <img
                  width="100%"
                  key={`asset-${conversationAsset?.id}`}
                  alt={`conversationAsset-${conversationAsset?.name}`}
                  src={`${store.cloudfrontUrl}/${conversationAsset?.url}`}
                />
              ))}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
}

export default observer(LearningMain)
