// import Router, { useRouter } from "next/router";
import { CircularProgress } from '@material-ui/core'
import UserApi from 'api/user'
import { StoreContext } from 'App'
import { observer } from 'mobx-react-lite'
import useAbstractProvider from 'providers/AbstractProvider'
import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

function ProtectedPage({ children }) {
  const history = useHistory()
  const location = useLocation()
  const store = useContext(StoreContext)
  const { data: currentlyLoggedInUser, status } = useAbstractProvider(
    UserApi.getCurrentlyLoggedInUser,
  )

  useEffect(() => {
    if (currentlyLoggedInUser) {
      store.setCurrentUser(currentlyLoggedInUser)
      if (location.pathname === '/' && currentlyLoggedInUser.role.name === 'mentor') {
        if (store.selectedView === 'manager') {
          history.push('/manager-view')
        } else if (store.selectedView === 'mentor') {
          history.push('/mentors-view')
        } else {
          history.push('/')
        }
      }
    }
  }, [currentlyLoggedInUser, store.selectedView])

  useEffect(() => {
    if (status === 401) {
      history.push('/sign-in')
    }
  }, [status])

  if (!store.currentUser.email) {
    return <CircularProgress />
  }
  return children
}

export default observer(ProtectedPage)
