import { makeStyles } from '@material-ui/styles'
import React, { FC, useContext } from 'react'
import { Link } from '@material-ui/core'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { StoreContext } from 'App'
import useMedia from 'hooks/useMedia'
import { useTranslation } from 'react-i18next'
import { removeLocalStorage } from 'utils'

export interface FooterProps {}

const useStyles = makeStyles(() => ({
  footerContainer: {
    background: '#3032C1 0% 0% no-repeat padding-box',
    height: '4rem',
    width: '100%',
    bottom: 0,
    left: 0,
    '@media (max-width: 600px)': {
      height: '6rem',
    },
  },
  footerContent: {
    maxWidth: '1320px',
    margin: 'auto',
    padding: '0rem 16px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  footerContentDesktop: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1rem',
  },
  divider: {
    marginRight: 5,
    marginLeft: 5,
    color: 'white',
    opacity: '0.64',
    display: 'inline-block',
  },
  footerLeft: {
    display: 'flex',
  },
  footerRight: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  link: {
    color: '#FFFFFF',
    opacity: '0.64',
    cursor: 'pointer',
    '&:hover': {
      opacity: '1',
    },
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  selectedLink: {
    opacity: '1',
  },
  text: {
    color: '#FFFFFF',
    opacity: '0.64',
    whiteSpace: 'nowrap',
    fontSize: '0.6rem',
    display: 'inline-block',
  },
}))

const Footer: FC<FooterProps> = () => {
  const classes = useStyles()
  const store = useContext(StoreContext) as any
  const gitCommit = process.env.REACT_APP_GIT_COMMIT

  const { md: isDesktop } = useMedia()
  const { t, i18n } = useTranslation()

  const { languages } = store
  const changeLanguage = (language) => {
    store.setLanguage(language)
    i18n.changeLanguage(language)
    store.setSelectedChannel(null)
    removeLocalStorage('selectedChannel')
    window.location.replace('/')
  }

  return (
    <div className={classes.footerContainer}>
      <div className={classNames(classes.footerContent, isDesktop && classes.footerContentDesktop)}>
        <div className={classes.footerLeft}>
          {languages.map((language, i) => (
            <React.Fragment key={language}>
              {i > 0 ? <div className={classes.divider}>|</div> : <></>}
              <div
                onClick={() => changeLanguage(language)}
                className={classNames(
                  classes.link,
                  store.language === language ? classes.selectedLink : null,
                )}
              >
                {language.toUpperCase()}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className={classes.footerRight}>
          <div>
            { gitCommit && (
              <>
                <p className={classes.text}>VERSION {gitCommit.slice(0,7)}</p>
                <div className={classes.divider}>|</div>
              </>
            )}
            <Link className={classNames(classes.link)} href="/terms">
              {t('footer.terms_of_use')}
            </Link>
            <div className={classes.divider}>|</div>
            <Link className={classNames(classes.link)} href="/gdpr">
              {t('footer.privacy_policy')}
            </Link>
            <div className={classes.divider}>|</div>
            <Link className={classNames(classes.link)} href="/">
              © PEPPERMILL.CLOUD
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Footer)
