import { Box, LinearProgress, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { StoreContext } from 'App'
import ProductsApi from 'api/products'
import Text from 'components/atoms/Text/Text'
import useMedia from 'hooks/useMedia'
import useAbstractProvider from 'providers/AbstractProvider'
import { setLocalStorage } from 'utils'

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: '#cce3e6',
  },
  product: {
    fontWeight: 800,
    cursor: 'pointer',
  },
  productDesktop: {
    padding: 17,
  },
  productName: {
    marginBottom: 5,
  },
}))

const ProductItem = ({ product }) => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const history = useHistory()

  const { md: isDesktop } = useMedia()
  const { data: progress } = useAbstractProvider(() =>
    ProductsApi.getProductProgressByCode(product.code),
  )

  let totalProgress = progress ? progress.arguments + progress.records + progress.conversations : 0
  if (totalProgress >= 99) totalProgress = 100

  const getProductNameHtml = () => {
    if (/^ID\sKMU/.test(product.name)) {
      return (
        <span>
          <b>{product.name.substring(0, 6)}</b>
          {product.name.substring(6)}
        </span>
      )
    }
    if (/^ID\sPlus/.test(product.name)) {
      return (
        <span>
          <b>{product.name.substring(0, 7)}</b>
          {product.name.substring(7)}
        </span>
      )
    }
    if (/^ID/.test(product.name)) {
      return (
        <span>
          <b>{product.name.substring(0, 2)}</b>
          {product.name.substring(2)}
        </span>
      )
    }
    return <span>{product.name}</span>
  }

  return (
    <Box
      className={classNames(
        classes.product,
        isDesktop && classes.productDesktop,
        isDesktop && store.selectedProductCategory?.id === product.id ? classes.selected : null,
      )}
      key={product.id}
      onClick={() => {
        store.setSelectedProductCategory(product.id)
        setLocalStorage('selectedProductCategory', product.id)
        history.push(`/learn/channelId=${store.selectedChannel.id}/productId=${product.id}`)
      }}
    >
      <Text variant="body2" key={`product-${product?.id}`} className={classes.productName}>
        {getProductNameHtml()}
      </Text>
      <LinearProgress variant="determinate" value={totalProgress} />
    </Box>
  )
}
export default observer(ProductItem)
