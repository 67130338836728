import { Dialog } from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import React from 'react'

type PropType = {
  open: boolean
  handleClose: () => void
  title: string
  children
}

const SimpleDialog = ({ open, handleClose, title, children }: PropType) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <div style={{ padding: '1rem 1rem 0 1rem', minWidth: 400 }}>
      <Text variant="h4">{title}</Text>
      <br />
      {children}
      <br />
    </div>
  </Dialog>
)

export default SimpleDialog
