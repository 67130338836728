import React, { FC, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import MaterialSelect from 'components/atoms/MaterialSelect/MaterialSelect'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import useAbstractMutator from 'providers/AbstractMutator'
import AuthApi from 'api/auth'
import { useHistory } from 'react-router-dom'
import { showToast } from 'utils'
import AdminLayout from 'layouts/AdminLayout'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
})

interface AddNewUserProps {}

const AddNewUser: FC<AddNewUserProps> = () => {
  const classes = useStyles()
  const [type, setType] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const newUser = useAbstractMutator(AuthApi.addNewUser)
  const history = useHistory()

  const { t } = useTranslation()

  const options = [
    { name: t('trainee'), value: '1' },
    { name: t('mentor'), value: '2' },
  ]

  useEffect(() => {
    if (newUser.data) {
      showToast(t('admin_users_and_teams.add_user_success_msg'))
      history.push(`/admin-view/user-detail/${newUser.data.id}`)
    }
  }, [newUser.data])

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Text variant="h2" className={classes.heading}>
            {t('admin_users_and_teams.add_user')}
          </Text>
          <Grid container>
            <Grid item xs={12}>
              <MaterialInput
                className={classes.search}
                type="text"
                label={t('email')}
                name="email"
                value={email}
                onChange={setEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialInput
                className={classes.search}
                type="text"
                label={t('first_name')}
                name="firstName"
                value={firstName}
                onChange={setFirstName}
              />
            </Grid>
            <Grid item xs={12}>
              <MaterialInput
                className={classes.search}
                type="text"
                label={t('last_name')}
                name="lastName"
                value={lastName}
                onChange={setLastName}
              />
              {options ? (
                <MaterialSelect
                  value={type}
                  label={t('type')}
                  onChange={(val) => setType(val)}
                  options={options}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                className={classes.saveBtn}
                onClick={() => {
                  if (!firstName || !lastName || !type || !email) {
                    showToast(t('required_fields_alert'))
                  } else {
                    newUser.mutate({ email, firstName, lastName, role: parseInt(type, 10) })
                  }
                }}
              >
                {t('save')}
              </CustomButton>
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default AddNewUser
