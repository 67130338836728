import React, { FC, useEffect, useState } from 'react'
import StaticContentsApi, { StaticContentEditDto } from 'api/staticContents'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import {makeStyles} from '@material-ui/core'
import useAbstractProvider from 'providers/AbstractProvider'
import { useParams, useHistory } from 'react-router-dom'
import useAbstractMutator from 'providers/AbstractMutator'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@material-ui/lab'
import TranslationEditBar from 'components/organisms/TranslationEditBar/TranslationEditBar'
import AdminLayout from 'layouts/AdminLayout'
import { sanitize } from 'dompurify'
import HtmlEditor from 'components/organisms/HtmlEditor/HtmlEditor'
import CancelButton from '../components/atoms/ColorButton/CancelButton'

interface ParamsProps {
  staticContentId: string
}

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  editor: {
    marginBottom: 20,
  },
  spaceAfter: {
    marginBottom: '1em',
  },
  textarea: {
    width: '100%',
  },
})

type ValidationResult = { [lang: string]: string[] }

interface StaticContentEditPageProps {}

const StaticContentEditPage: FC<StaticContentEditPageProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const { staticContentId } = useParams<ParamsProps>()
  const [data, setData] = useState<StaticContentEditDto>(null)
  const [validationResult, setValidationResult] = useState<ValidationResult>({})
  const [currentLang, setCurrentLang] = useState<string>('de')

  const editData = useAbstractProvider(StaticContentsApi.getEdit, staticContentId)
  const updateData = useAbstractMutator(StaticContentsApi.update)
  const { t } = useTranslation()

  const addTranslation = (lang) => {
    const newData = { ...data }
    newData.translations[lang] = { name: '', content: '' }

    setData(newData)
  }

  const deleteTranslation = (lang) => {
    const newData = { ...data }
    delete newData.translations[lang]

    setData(newData)
  }

  const validate = (dataToValidate: StaticContentEditDto): ValidationResult => {
    const result: ValidationResult = {}

    Object.entries(dataToValidate.translations).forEach((langEntry) => {
      Object.entries(langEntry[1]).forEach((fieldEntry) => {
        if (!fieldEntry[1]) {
          if (!result[langEntry[0]]) {
            result[langEntry[0]] = []
          }

          result[langEntry[0]].push(`static_content.${fieldEntry[0]}`)
        }
      })
    })

    return result
  }

  const save = async () => {
    const currentValidationResult = validate(data)
    setValidationResult(currentValidationResult)

    if (Object.keys(currentValidationResult).length === 0) {
      // sanitize inputs
      Object.keys(data.translations).forEach((languageCode) => {
        data.translations[languageCode].content = sanitize(data.translations[languageCode].content)
      })

      await updateData.mutate({ id: staticContentId, data })
      history.push('/admin-view/static-contents')
    }
  }

  useEffect(() => {
    if (staticContentId) {
      editData.refetch(staticContentId)
    }
  }, [staticContentId])

  useEffect(() => {
    setData(editData.data)
  }, [editData.data])

  useEffect(() => {
    setData(updateData.data)
  }, [updateData.data])

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Text variant="h2" className={classes.heading}>
            {t('admin_static_contents.heading_edit')}
          </Text>
          {Object.keys(validationResult).length > 0 ? (
            <Alert className={classes.spaceAfter} severity="error">
              <AlertTitle>{t('required_fields_alert')}</AlertTitle>
              {Object.keys(validationResult).map((lang) => (
                <p key={lang}>
                  <strong>{lang.toLocaleUpperCase()}</strong>:{' '}
                  {validationResult[lang].map((field) => t(field)).join(', ')}
                </p>
              ))}
            </Alert>
          ) : (
            <></>
          )}
          {data ? (
            <>
              <div className={classes.spaceAfter}>
                <TranslationEditBar
                  languages={Object.keys(data.translations)}
                  language={currentLang}
                  onLanguageAdd={addTranslation}
                  onLanguageChange={setCurrentLang}
                  onLanguageDelete={deleteTranslation}
                />
              </div>
              {Object.keys(data.translations)
                .filter((lang) => lang === currentLang)
                .map((lang) => (
                  <div key={lang}>
                    <MaterialInput
                      className={classes.spaceAfter}
                      label={t('static_content.name')}
                      name="name"
                      value={data.translations[lang].name}
                      onChange={(newName) => {
                        data.translations[lang].name = newName
                        setData({ ...data })
                      }}
                    />
                    {data.html ? (
                      <HtmlEditor
                        admin
                        className={classes.editor}
                        value={data.translations[lang].content}
                        onChange={(newContent) => {
                          const newData = {...data}
                          newData.translations[lang].content = newContent
                          setData({...newData})
                        }}
                      />
                    ) : (
                      <MaterialInput
                        className={`${classes.textarea} ${classes.spaceAfter}`}
                        multiline
                        name="content"
                        onChange={(newContent) => {
                          data.translations[lang].content = newContent
                          setData({ ...data })
                        }}
                        rows={8}
                        value={data.translations[lang].content}
                      />
                    )}
                  </div>
                ))}
              <CustomButton color='primary' onClick={save}>{t('save')}</CustomButton>&nbsp;
              <CancelButton
                variant="outlined"
                onClick={() => history.push('/admin-view/static-contents')}
              >
                {t('cancel')}
              </CancelButton>
            </>
          ) : (
            <></>
          )}
        </>,
      )}
    </ProtectedPage>
  )
}

export default StaticContentEditPage
