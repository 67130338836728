import React, { FC, useEffect, useState } from 'react'
import UserApi from 'api/user'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import useAbstractProvider from 'providers/AbstractProvider'
import Loader from 'components/atoms/Loader/Loader'
import MaterialTable from 'components/organisms/MaterialTable/MaterialTable'
import { useHistory, Link } from 'react-router-dom'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import AdminLayout from 'layouts/AdminLayout'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
  addUser: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

interface AllUsersPageProps {}

const AllUsersPage: FC<AllUsersPageProps> = () => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const history = useHistory()

  const { t } = useTranslation()

  const users = useAbstractProvider(UserApi.getAllUsersPagination, { search, offset, limit })

  const head = [
    { name: t('first_name'), dbName: 'firstName' },
    { name: t('last_name'), dbName: 'lastName' },
    { name: t('email'), dbName: 'email' },
    { name: t('active'), dbName: 'active' },
  ]

  useEffect(() => {
    if (users?.data) {
      setData(users?.data.data)
    }
  }, [users.data])

  useEffect(() => {
    users.refetch({ search, offset, limit })
  }, [search, offset, limit])

  function handleChangePage(pageToChange) {
    setOffset(pageToChange * limit)
  }

  const selectItem = (selectedItem) => {
    history.push(`/admin-view/user-detail/${selectedItem.id}`)
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Grid container>
            <Grid item xs={6}>
              <Text variant="h2" className={classes.heading}>
                {t('admin_users_and_teams.all_users')}
              </Text>
            </Grid>
            <Grid item xs={6} className={classes.addUser}>
              <Link to="/admin-view/add-user">
                <CustomButton onClick={() => {}}>
                  {t('admin_users_and_teams.add_user')}
                </CustomButton>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <MaterialInput
                className={classes.search}
                type="text"
                label={t('search')}
                name="search"
                value={search}
                onChange={setSearch}
              />
            </Grid>

            <Grid item xs={12}>
              {users.loading ? (
                <Loader />
              ) : (
                <div className={classes.table}>
                  <MaterialTable
                    head={head}
                    data={data}
                    onRowClick={selectItem}
                    count={users?.data?.count}
                    onChangePage={handleChangePage}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={(value) => {
                      setLimit(value)
                      setOffset(0)
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default AllUsersPage
