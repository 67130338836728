import {API_URL} from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getAvatars() {
  return API.get(`${API_URL}/avatars`, true)
}

async function getVoices() {
  return API.get(`${API_URL}/avatars/voices`, true)
}

const addAvatar = async (data) => API.post(`${API_URL}/avatars/add`, data, true)

const editAvatar = async (avatarId, data) => API.post(`${API_URL}/avatars/${avatarId}`, data, true)

const deleteAvatar = async (avatarId) => API.remove(`${API_URL}/avatars/${avatarId}`, true)

const AvatarsApi = {
  getAvatars,
  addAvatar,
  getVoices,
  editAvatar,
  deleteAvatar
}

export default AvatarsApi
