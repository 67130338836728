import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import React from 'react'

export interface SelectOptions {
  value: string
  name: string
  disabled?: boolean
}

export interface MaterialSelectProps {
  name?: string
  value: any
  label?: string
  onChange: Function
  options: Array<SelectOptions>
  classNameContainer?: any
  classNameSelect?: any
  disabled?: boolean
  className?: string
  renderValue?: any
  fullWidth?: any
  displayEmpty?: boolean
  error?: boolean
  errorMessage?: string
}

const useStyles = makeStyles({
  root: {
    minWidth: '100px',
    background: 'white',
    borderRadius: `0px`,
    '& fieldset': {
      borderRadius: `0px`,
    },
  },
})

const MaterialSelect: React.FC<MaterialSelectProps> = ({
  name,
  value,
  label,
  onChange,
  options,
  classNameContainer,
  classNameSelect,
  disabled,
  className,
  renderValue,
  fullWidth,
  displayEmpty,
  error,
  errorMessage,
}) => {
  const classes = useStyles()

  return (
    <FormControl fullWidth={fullWidth ?? true} variant="outlined" className={classNameContainer} error={error}>
      <InputLabel id="selectLabelId">{label}</InputLabel>
      <Select
        name={name}
        labelId="selectLabelId"
        label={label}
        classes={{ root: classnames(classes.root, classNameSelect) }}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        className={classnames(className)}
        renderValue={renderValue}
        style={{borderRadius: '0px'}}
        displayEmpty={displayEmpty}
      >
        {options.map((option) => (
          <MenuItem disabled={option.disabled} key={option.name} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default MaterialSelect
