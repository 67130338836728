import React, { useContext, useEffect, useState } from 'react'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { StoreContext } from 'App'
import classNames from 'classnames'

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
  },
  pointer: {
    cursor: 'pointer',
  },
  highlightOnHover: {
    padding: 4,
    border: '2px solid transparent',
    '&:hover': {
      border: '2px solid #0000',
      boxShadow:
        '0px 0px 5px -2px #5c6bc0, 0px 2px 5px 0px #5c6bc0, 0px 1px 5px 0px #5c6bc0 !important',
    },
  },
  selectedItem: {
    border: '2px solid #2c5de5 !important',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  centerBoth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '16px',
  },
  text: {
    marginTop: 13,
  },
})

export interface ChannelSelectorProps {
  onChange: Function
  smallPictures?: boolean
  channelIds?: number[]
  showSpecificLanguageChannels?: boolean
}

const ChannelSelector: React.SFC<ChannelSelectorProps> = ({
  onChange,
  smallPictures,
  channelIds,
  showSpecificLanguageChannels,
}) => {
  const classes = useStyles()
  const store = useContext(StoreContext) as any
  const [selectedChannel, setSelectedChannel] = useState({
    id: null,
  })

  useEffect(() => {
    setSelectedChannel(store.selectedChannel)
  }, [store.selectedChannel])

  const channels = channelIds
    ? store.channels.filter((item) => channelIds.includes(item.id))
    : store.channels

  return (
    <Grid container spacing={smallPictures ? 1 : undefined}>
      {(showSpecificLanguageChannels
        ? channels.filter((item) => item.contentLanguage === store.language)
        : channels
      ).map((channel) => (
        <Grid
          item
          key={`channel-${channel.id}`}
          xs={smallPictures ? 6 : 3}
          sm={smallPictures ? 2 : 3}
          className={classes.itemContainer}
          style={{ maxWidth: '162px' }}
        >
          <Paper
            variant="outlined"
            square
            onClick={() => {
              if (channel.isActive) {
                setSelectedChannel(channel)
                onChange(channel)
              }
            }}
            style={{ height: '162px' }}
            className={classNames(
              classes.paper,
              classes.centerBoth,
              classes.pointer,
              classes.highlightOnHover,
              selectedChannel?.id === channel.id ? classes.selectedItem : null,
            )}
          >
            <div
              style={{
                height: '150px',
                minWidth: '150px',
                position: 'relative',
              }}
            >
              <img
                alt={`channel-${channel.id}`}
                src={`${store.cloudfrontUrl}/${channel.imageUrl}`}
                width={150}
                height={150}
                style={smallPictures ? { maxWidth: '100%', display: 'block' } : {}}
              />
              {!channel.isActive && (
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    backgroundColor: 'rgba(128, 128, 128, 0.6)',
                    zIndex: 10,
                  }}
                />
              )}
            </div>
          </Paper>
          <div className={classes.text}>{channel.name}</div>
        </Grid>
      ))}
    </Grid>
  )
}

export default observer(ChannelSelector)
