import React, { FC, useContext } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import MainLayout from 'layouts/MainLayout'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import NavigationBox from 'components/manage/NavigationBox'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'App'
import { ROLES, getTheRightRole } from 'utils'
import { School } from '@emotion-icons/ionicons-sharp/School'
import { BarChartAlt2 } from '@emotion-icons/boxicons-solid/BarChartAlt2'
import { MailSend } from '@emotion-icons/remix-line/MailSend'
import { InsertEmoticon } from '@emotion-icons/material/InsertEmoticon'
import { Verified } from '@emotion-icons/material/Verified'
import { NotificationAdd } from '@emotion-icons/material-sharp/NotificationAdd'
import { Article } from '@emotion-icons/material/Article'
import { ManageAccounts } from '@emotion-icons/material/ManageAccounts'
import { SupervisorAccount } from '@emotion-icons/material/SupervisorAccount'


const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
    marginBottom: 50,
  },
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

interface ManagePageProps {}

const ManagePage: FC<ManagePageProps> = () => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const { t } = useTranslation()

  const navigation =
    getTheRightRole(store.currentUser.role.name) === ROLES.contentmanager
      ? [
          {
            text: t('admin_learning_panel.title'),
            link: '/admin-view/learning-material-managment',
            icon: <School size="82"/>,
          },
          { text: t('admin_static_contents.title'), link: '/admin-view/static-contents', icon: <Article size="82"/> },
          { text: t('admin_statistics.title'), link: '/admin-view/statistics', icon: <BarChartAlt2 size="82"/> },
        ]
      : [
          { text: t('admin_users_and_teams.users'), link: '/admin-view/all-users', icon: <ManageAccounts size="82"/> },
          { text: t('admin_users_and_teams.teams'), link: '/admin-view/all-teams', icon: <SupervisorAccount size="82"/> },
          { text: `${t('admin_avatars.title')}`, link: '/admin-view/avatars', icon: <InsertEmoticon size="82"/> },
          {
            text: t('admin_learning_panel.title'),
            link: '/admin-view/learning-material-managment',
            icon: <School size="82"/>
          },
          { text: t('admin_statistics.title'), link: '/admin-view/statistics', icon: <BarChartAlt2 size="82"/> },
          { text: t('admin_send_email.title'), link: '/admin-view/send-email', icon: <MailSend size="82"/> },
          { text: t('admin_static_contents.title'), link: '/admin-view/static-contents', icon: <Article size="82"/> },
          { text: 'Test notification', link: '/admin-view/test-notification', icon: <NotificationAdd size="82"/> },
            { text: t('admin_licensing.title'), link: '/admin-view/licensing', icon: <Verified size="82"/> },
        ]

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <>
          <div className={classes.container}>
            <Text variant="h2" className={classes.heading}>
              {t('admin_management.heading')}
            </Text>
            <Grid container spacing={8}>
              {navigation.map((navItem) => (
                <Grid
                  key={navItem.text}
                  item
                  xs={12}
                  md={3}
                  sm={6}
                  className={classes.itemContainer}
                >
                  <NavigationBox text={navItem.text} link={navItem.link} icon={navItem.icon} />
                </Grid>
              ))}
            </Grid>
          </div>
        </>,
      )}
    </ProtectedPage>
  )
}

export default ManagePage
