import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function approveUser(data) {
  const res = await API.post(`${API_URL}/user-approvals/approve`, data, true)
  return res
}

async function rejectUser(approvalId) {
  const res = await API.post(`${API_URL}/user-approvals/reject`, { approvalId }, true)
  return res
}

export default {
  approveUser,
  rejectUser,
}
