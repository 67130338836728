import Typography from '@material-ui/core/Typography'
import React from 'react'
import {makeStyles} from '@material-ui/core'

export interface TextProps {
  className?: any
  children: any
  variant: any
  onClick?: any,
  wrap?: boolean
}

const useStyles = makeStyles({
  base: {
    overflowWrap: 'break-word',
    hyphens: 'auto',
  },
})

const Text: React.SFC<TextProps> = ({ children, className, variant, onClick, wrap }) => {
  const classes = useStyles()
  return (
  <Typography variant={variant} className={`${wrap ? classes.base: ''} ${className}`} onClick={onClick}>
    {children}
  </Typography>
)}

export default Text
