import React, { FC, useEffect, useState } from 'react'
import StaticContentsApi, { StaticContentDto } from 'api/staticContents'
import useAbstractProvider from 'providers/AbstractProvider'
import ReactTooltip from 'react-tooltip'
import {makeStyles} from '@material-ui/core'

export interface LogoProps {
  width: number
  height: number
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    maxWidth: '900px',
    paddingTop: '2em',
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageText: {
    marginTop: '0.6rem',
    textAlign: 'center',
    width: '8em',
    fontSize: '0.875rem',
    color: theme.palette.primary.main
  },
  line: {
    position: 'absolute',
    height: '27px',
    width: '90%',
    zIndex: -1,
    borderBottom: '1px solid #bac2d7',
    margin: '0 5%',
  },
}))

const Logo: FC<LogoProps> = () => {
  const classes = useStyles()
  const [staticContentCodes] = useState<string[]>([1, 2, 3, 4, 5].map((i) => `intro_${i}`))
  const [contents, setContents] = useState<StaticContentDto[]>([])
  const editData = useAbstractProvider(StaticContentsApi.getByCodes, staticContentCodes)

  useEffect(() => {
    if (editData.data) {
      setContents(editData.data.sort((c1, c2) => c1.code.localeCompare(c2.code)))
    }
  }, [editData.data])

  return (
    <div className={classes.container}>
      <div className={classes.line} />
      <div className={`${classes.imagesContainer}`}>
        {contents.map((content, i) => (
          <div key={content.id} className={classes.imageContainer}>
            <img data-tip data-for={`tooltip${i}`} src={`intro/image${i + 1}.svg`} alt="" />
            <ReactTooltip type="info" id={`tooltip${i}`} aria-haspopup="true">
              <p style={{ width: 400 }}>{content.content}</p>
            </ReactTooltip>
            <div className={classes.imageText}>{content.name}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Logo
