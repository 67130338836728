import React from 'react'
import { CircularProgress } from '@material-ui/core'

const FullScreenLoader = () => (
  <div
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
    }}
  >
    <CircularProgress />
  </div>
)

export default FullScreenLoader
