import React, { FC, useContext, useState } from 'react'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { AppBar, makeStyles, Menu, MenuItem, Tab, Tabs } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'App'

// eslint-disable-next-line no-unused-vars
type LanguageHandler = (lang: string) => void

interface TranslationEditBarProps {
  languages: string[];
  language: string;
  onLanguageChange: LanguageHandler;
  onLanguageAdd: LanguageHandler;
  onLanguageDelete: LanguageHandler;
}

const useStyles = makeStyles({
  spaceAfter: {
    marginBottom: '1em'
  },
})

const TranslationEditBar: FC<TranslationEditBarProps> = ({ languages, language, onLanguageChange, onLanguageAdd, onLanguageDelete }: TranslationEditBarProps) => {
  const store = useContext(StoreContext) as any
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { t } = useTranslation()

  const openTranslationMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeTranslationMenu = () => {
    setAnchorEl(null)
  }

  const handleCurrentTabChange = (event, newValue) => {
    setCurrentTab(newValue)
    onLanguageChange(languages[newValue])
  }

  return (<>
    <CustomButton
      onClick={openTranslationMenu}
      className={classes.spaceAfter}
      disabled={languages.length === store.languages.length}
    >
      {t('add_translation')}
    </CustomButton>
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeTranslationMenu}
    >
      {store.languages.filter(lang => languages.indexOf(lang) === -1).map(lang =>
        <MenuItem key={lang} onClick={() => {
          closeTranslationMenu()
          setCurrentTab(Object.keys(languages).length)
          onLanguageAdd(lang)
          onLanguageChange(lang)
        }}>{lang.toUpperCase()}</MenuItem>
      )}
    </Menu>
    <AppBar position="static" className={classes.spaceAfter}>
      <Tabs value={currentTab} onChange={handleCurrentTabChange}>
        {languages.map(lang => <Tab key={lang} label={lang} />)}
      </Tabs>
    </AppBar>
    <CustomButton
      disabled={language === 'de'}
      onClick={() => {
        const newTabValue = languages.indexOf(language) === languages.length - 1 ? languages.length - 2 : languages.indexOf(language)
        const newLanguage = languages.indexOf(language) === languages.length - 1 ? languages[languages.length - 2] : languages[languages.indexOf(language) + 1]

        onLanguageDelete(language)
        onLanguageChange(newLanguage)
        setCurrentTab(newTabValue)
      }}
      className={classes.spaceAfter}
    >
      {t('delete_translation')}
    </CustomButton>
  </>)
}

export default TranslationEditBar