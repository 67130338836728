import React from 'react'
// LIBRARIES
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core'
import c from 'classnames'
// GLOBAL COMPONENTS
// INTERNAL COMPONENTS

const useStyles = makeStyles(() => ({
  customButton: {
    color: 'white',
    height: '44px',
    borderRadius: '0px',
    // background: 'linear-gradient(45deg, #4240bc 30%, #06028C 90%)',
  },
}))

/**
 * A Material UI Button
 * @param {element} children - what to display in the button(text)
 * @param {function} onClick - function defining what to do when a user presses the button
 * @returns {component} return a Material UI Button with children
 */
const MaterialButton = ({ children, onClick, className, variant, color, ...props }) => (
  <Button onClick={onClick} className={className} variant={variant} color={color} disabled={props.disabled} startIcon={props.startIcon}>
    {children}
  </Button>
)
export default MaterialButton

export const CustomButton = ({ onClick, children, ...props }) => {
  const classes = useStyles()

  return (
    <MaterialButton
      variant={props.variant ? props.variant : 'contained'}
      onClick={onClick}
      color={props.color ? props.color : 'primary'}
      className={c(classes.customButton, props.className)}
      disabled={props.disabled}
      startIcon={props.startIcon ? props.startIcon : undefined}
    >
      {children}
    </MaterialButton>
  )
}
