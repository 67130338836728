import { observer } from 'mobx-react-lite'
import React, { FC, useContext } from 'react'
import { Grid } from '@material-ui/core'

import { StoreContext } from 'App'
import ConversationBrowser from 'components/conversation/ConversationBrowser'

export interface LearningConversationsProps {
  conversations: any
  completedConversations: any
  conversationsWithFiles: any
  productId: any
  handleSampleSelect: Function
}

const LearningConversations: FC<LearningConversationsProps> = ({
  conversations,
  completedConversations,
  conversationsWithFiles,
  productId,
  handleSampleSelect,
}) => {
  const store = useContext(StoreContext)
  const { phase } = store

  return (
    <Grid container direction="column" alignItems="stretch" style={{ height: '100%' }}>
      <Grid item xs={12}>
          <ConversationBrowser
            completedConversations={completedConversations}
            conversationsWithFiles={conversationsWithFiles.filter(
              (conversation) =>
                conversation.phase.id === phase &&
                conversation.product_conversation[0].product.id === parseInt(productId, 10) &&
                conversation.learning_type_conversation[0].learning_type.id ===
                  store?.selectedLearningType?.id,
            )}
            conversations={conversations.filter(
              (conversation) =>
                conversation.phase.id === phase &&
                conversation.product_conversation[0].product.id === parseInt(productId, 10) &&
                conversation.learning_type_conversation[0].learning_type.id ===
                  store?.selectedLearningType?.id,
            )}
            onSampleSelect={handleSampleSelect}
            phase={phase}
          />
      </Grid>
    </Grid>
  )
}

export default observer(LearningConversations)
