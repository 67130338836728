import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { makeStyles } from '@material-ui/core'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import useAbstractMutator from 'providers/AbstractMutator'
import NotificationsApi from 'api/notifications'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { Alert } from '@material-ui/lab'
import AdminLayout from 'layouts/AdminLayout'

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
    marginBottom: 50,
  },
  heading: {
    marginBottom: 45,
  },
  spaceAfter: {
    marginBottom: '1em',
  },
  textarea: {
    width: '100%',
  },
})

interface TestNotificationPageProps {}

const TestNotificationPage: FC<TestNotificationPageProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const [notification, setNotification] = useState<{
    email?: string
    message?: string
    link?: string
  }>({})
  const [success, setSuccess] = useState<boolean>(false)
  const submitNotification = useAbstractMutator(NotificationsApi.createTestNotification)

  useEffect(() => {
    setSuccess(submitNotification.status === 201)
  }, [submitNotification.status])

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <div className={classes.container}>
            <Text variant="h2" className={classes.heading}>
              Test notification
            </Text>
            {success ? (
              <Alert className={classes.spaceAfter} severity="success">
                Notification created successfully
              </Alert>
            ) : null}
            {submitNotification.error ? (
              <Alert className={classes.spaceAfter} severity="error">
                {submitNotification.error.data.message}
              </Alert>
            ) : null}
            <MaterialInput
              className={`${classes.textarea} ${classes.spaceAfter}`}
              multiline
              name="message"
              onChange={(newMessage) => {
                notification.message = newMessage
                setNotification({ ...notification })
              }}
              rows={8}
              value={notification.message}
            />
            <MaterialInput
              className={classes.spaceAfter}
              label="E-mail recipient (optional)"
              name="email"
              value={notification.email}
              onChange={(newEmail) => {
                notification.email = newEmail
                setNotification({ ...notification })
              }}
            />
            <MaterialInput
              className={classes.spaceAfter}
              label="Frontend link (optional)"
              name="link"
              value={notification.link}
              onChange={(newLink) => {
                notification.link = newLink
                setNotification({ ...notification })
              }}
            />
            <CustomButton
              onClick={async () => {
                await submitNotification.mutate(notification)
              }}
            >
              Submit
            </CustomButton>
            &nbsp;
            <CustomButton onClick={() => history.push('/')} color="default">
              Cancel
            </CustomButton>
          </div>
        </>,
      )}
    </ProtectedPage>
  )
}

export default TestNotificationPage
