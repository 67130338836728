import { API_URL } from '../helpers/configuration'
import Api from '.'
import { ApiResponse } from './staticContents'

export interface NotificationData {
  id: number;
  type: string;
  created: Date;
  message: string;
  link?: string;
  customData?: Record<string, any>;
}

const API = Api()

async function readNotifications(): Promise<ApiResponse<{ count: number }>> {
  return API.put(`${API_URL}/notifications/read-notifications`, null, true)
}

async function deleteNotification(id: number): Promise<ApiResponse<void>> {
  return API.remove(`${API_URL}/notifications/${id}`, true)
}

async function createTestNotification(data: { email?: string, message: string }): Promise<ApiResponse<void>> {
  return API.post(`${API_URL}/notifications/test-notification`, data, true)
}

export default {
  readNotifications,
  deleteNotification,
  createTestNotification,
}
