import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getProductsByChannelId(channelId) {
  const res = await API.get(`${API_URL}/products/channelId/${channelId}`, true)
  return res
}

async function getProductByCode(code) {
  const res = await API.get(`${API_URL}/products/${code}`, true)
  return res
}

async function getProductProgressByCode(code) {
  const res = await API.get(`${API_URL}/products/${code}/progress`, true)
  return res
}

const deleteProduct = async (productId) => {
  const res = await API.remove(`${API_URL}/products/${productId}`, true)
  return res
}

const addProduct = async (data) => {
  const res = await API.post(`${API_URL}/products/add`, data, true)
  return res
}

const editProduct = async (productId, data) => {
  const res = await API.put(`${API_URL}/products/${productId}`, data, true)
  return res
}

const getProductsFullData = async () => {
  const res = await API.get(`${API_URL}/products/full-data`, true)
  return res
}

const getProductPhases = async (productId: number) => {
  const res = await API.get(`${API_URL}/products/${productId}/phases`, true)
  return res
}

const ProductsApi = {
  getProductsByChannelId,
  getProductProgressByCode,
  addProduct,
  editProduct,
  getProductsFullData,
  getProductByCode,
  deleteProduct,
  getProductPhases,
}

export default ProductsApi
