import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, makeStyles } from '@material-ui/core'
import useAbstractProvider from 'providers/AbstractProvider'
import React, { FC, useEffect, useState } from 'react'
import NotificationsApi, { NotificationData } from 'api/notifications'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Delete } from '@material-ui/icons'

const useStyles = makeStyles({
  primary: {
    fontSize: '1rem',
  },
  secondary: {
    fontSize: '0.8rem',
  },
  padded: {
    padding: '1em',
  }
})

interface NotificationsListProps {}

const NotificationsList: FC<NotificationsListProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [ notifications, setNotifications ] = useState<NotificationData[]>([])
  const primaryTypographyProps = { className: classes.primary }
  const secondaryTypographyProps = { className: classes.secondary }
  const notificationData = useAbstractProvider(NotificationsApi.readNotifications)

  useEffect(() => {
    setNotifications(notificationData.data || [])
  }, [notificationData.data])

  const handleLink = (notification: NotificationData) => {
    if (notification.link) {
      history.push(notification.link)
    }
  }

  const handleDelete = async (notification: NotificationData) => {
    const notificationIndex = notifications.indexOf(notification)
    await NotificationsApi.deleteNotification(notification.id)

    notifications.splice(notificationIndex, 1)
    setNotifications(notifications.slice(0))
  }

  return notifications.length > 0 ? (
    <List component="nav">
      {notifications.map(notification =>
      <ListItem dense button key={notification.id} onClick={() => handleLink(notification)}>
        <ListItemText primary={t(`notifications.${notification.message}`, notification.customData)} primaryTypographyProps={primaryTypographyProps} secondary={moment(notification.created).format('LLL')} secondaryTypographyProps={secondaryTypographyProps} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => handleDelete(notification)}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      )}
    </List>
    ) : (
    <p className={classes.padded}><em>{t('notifications.no_notifications')}</em></p>
    )
}

export default NotificationsList