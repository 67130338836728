import React from 'react'
// LIBRARIES
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import cs from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
// GLOBAL COMPONENTS
// INTERNAL COMPONENTS

/**
 * Basic input
 * @param {string} value - The value of the input
 * @param {string} [label] - The label of the input
 * @param {function} onChange - The function that fires when user writes to the input
 * @param {string} [multiline] - Specifies if the input should be multiline
 * @param {string} [helperText] - The helpter text is a text that is displayed under the input
 * @param {string} [className] - Custom className styling
 * @param {number} [rows] - Number of rows a multiline input should have
 */
export interface MaterialInputProps {
  value: string
  label?: string
  error?: boolean
  onChange: Function
  onChange2?: Function
  name: string
  multiline?: boolean
  className?: string
  helperText?: string
  rows?: number
  variant?: TextFieldProps['variant']
  type?: string
  disabled?: boolean
  size?: 'small' | 'medium'
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: 'white',
    '& fieldset': {
      borderRadius: `0px`,
    },
  },
})

const MaterialInput: React.FC<MaterialInputProps> = ({
  value,
  label,
  error,
  onChange,
  onChange2,
  name,
  multiline,
  className,
  helperText,
  rows,
  variant,
  type,
  disabled,
  size,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div className={cs(className)}>
      <TextField
        size={size || 'medium'}
        classes={{ root: classes.root }}
        {...props}
        type={type}
        id={name}
        name={name}
        disabled={disabled}
        label={label}
        className={cs(className)}
        value={value}
        // onChange={(event) => onChange(event.target.value)}
        onChange={(event) => onChange2 ? onChange2(event) : onChange(event.target.value)}
        margin="none"
        multiline={multiline || false}
        minRows={rows}
        helperText={helperText}
        variant={variant}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  )
}

export default MaterialInput

MaterialInput.defaultProps = {
  className: '',
  value: '',
  label: '',
  onChange: () =>
    // eslint-disable-next-line no-console
    console.log('Material Input onChange default function: You have changed the input'),
  multiline: false,
  helperText: '',
  rows: 4,
  variant: 'outlined',
}
