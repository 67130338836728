import axios from 'axios'
import i18n from 'i18next'

function API() {
  function handleOffline(error) {
    if (!error.response && window.location.pathname !== '/offline') {
      window.location.pathname = '/offline'
    }
  }

  function handleForbidden(error) {
    if (error?.response?.status === 403) {
      window.location.pathname = '/sign-in'
    }
  }

  async function get(url, auth = false, params?) {
    let err
    const headers = auth ? {} : {}
    headers['Accept-Language'] = i18n.resolvedLanguage

    const res = await axios
      .get(url, {
        headers,
        params,
        withCredentials: true,
      })
      .catch((error) => {
        handleOffline(error)
        handleForbidden(error)
        err = error
      })

    return { res, err }
  }

  async function post(url, data, auth = false, options?) {
    let err

    const headers = auth ? { ...options } : { ...options }

    headers['Accept-Language'] = i18n.resolvedLanguage

    const res = await axios
      .post(url, data, {
        headers,
        withCredentials: true,
      })
      .catch((error) => {
        handleOffline(error)
        handleForbidden(error)
        err = error
      })

    return { res, err }
  }

  async function put(url, data, auth = false) {
    let err

    const headers = auth ? {} : {}
    headers['Accept-Language'] = i18n.resolvedLanguage

    const res = await axios
      .put(
        url,
        { ...data },
        {
          headers,
          withCredentials: true,
        },
      )
      .catch((error) => {
        handleOffline(error)
        handleForbidden(error)
        err = error
      })

    return { res, err }
  }

  async function remove(url, auth = false) {
    let err

    const headers = auth ? {} : {}
    headers['Accept-Language'] = i18n.resolvedLanguage

    const res = await axios
      .delete(url, {
        headers,
        withCredentials: true,
      })
      .catch((error) => {
        handleOffline(error)
        handleForbidden(error)
        err = error
      })

    return { res, err }
  }

  return { get, post, put, remove }
}

export default API
