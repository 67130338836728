import { makeStyles, Paper } from '@material-ui/core'
import PasswordChange from 'components/organisms/PasswordChange/PasswordChange'
import UnprotectedPage from 'components/organisms/UnprotectedPage/UnprotectedPage'
import AuthLayout from 'layouts/AuthLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  signInContainer: {
    height: 'auto',
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: "center",
    background: '#fff',
    padding: '30px 30px 30px 30px',
    borderRadius: '0px',
  },
  signInHeading: {
    margin: '0px 0px 45px 0px',
    fontWeight: 800,
    textAlign: 'center',
  },
})

export interface ChangePasswordPageProps {}

const ChangePasswordPage: React.SFC<ChangePasswordPageProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <UnprotectedPage>
      {AuthLayout.getLayout(
        <div>
          <Paper className={classes.signInContainer}>
            <h1 className={classes.signInHeading}>{t('reset_password.heading')}</h1>
            <PasswordChange />
          </Paper>
        </div>,
      )}
    </UnprotectedPage>
  )
}

export default ChangePasswordPage
