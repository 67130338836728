import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function addUserToTeam(body) {
  const res = await API.post(
    `${API_URL}/user-teams/add-user-to-team`,
    {
      ...body,
    },
    true,
  )
  return res
}

async function removeUserToTeam({ teamId, userId }) {
  const res = await API.remove(
    `${API_URL}/user-teams/remove-user-to-team/${teamId}/${userId}`,
    true,
  )
  return res
}

async function getAllUsersOfTeam(teamId) {
  const res = await API.get(`${API_URL}/user-teams/getAllUsersOfTeam/${teamId}`, true)
  return res
}

async function getAllUsersNotOfTeam(teamId) {
  const res = await API.get(`${API_URL}/user-teams/getAllUsersNotOfTeam/${teamId}`, true)
  return res
}

export default {
  addUserToTeam,
  removeUserToTeam,
  getAllUsersOfTeam,
  getAllUsersNotOfTeam,
}
