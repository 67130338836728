import {API_URL} from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getCurrentlyLoggedInUser() {
  const res = await API.get(`${API_URL}/auth/me`, true)
  return res
}

async function getAllTeamUsersData(params) {
  const res = await API.get(
    `${API_URL}/users/getAllTeamUsersData?offset=${params.offset}&limit=${params.limit}&year=${params.year}&startingMonth=${params.startingMonth}&endingMonth=${params.endingMonth}&sortBy=${params.sortBy}&search=${params.search}`,
    true,
  )
  return res
}

async function getStatistics(params) {
  const searchParams = new URLSearchParams(params)
  return API.get(`${API_URL}/users/getStatistics?${searchParams.toString()}`, true)
}

async function getLoginStatistics(params) {
  const searchParams = new URLSearchParams(params)
  return API.get(`${API_URL}/users/getLoginStatistics?${searchParams.toString()}`, true)
}

async function getExport(params) {
  const searchParams = new URLSearchParams(params)
  return API.get(`${API_URL}/users/getXlsExportData?${searchParams.toString()}`, true)
}

async function getTeamUserDetailData(params) {
  const res = await API.get(
    `${API_URL}/users/getTeamUserDetailData/${params.userId}/${params.channelId}`,
    true,
  )
  return res
}

async function getUserDetailData(params) {
  const res = await API.get(
    `${API_URL}/users/getUserDetailData/${params.offset}/${params.limit}`,
    true,
  )
  return res
}

async function getAllUsers() {
  const res = await API.get(`${API_URL}/users/allUsers`, true)
  return res
}

async function getUserBydId(userId) {
  console.log('userId: ', userId)
  const res = await API.get(`${API_URL}/users/getUserBydId/${userId}`, true)
  return res
}

async function getAllUsersPagination(params) {
  const res = await API.get(
    `${API_URL}/users/allUsersPagination/${params.offset}/${params.limit}/${params.search}`,
    true,
  )
  return res
}

async function deactivateUser(data: any) {
  const res = await API.post(`${API_URL}/users/deactivate`, data, true)
  return res
}

async function activateUser(data: any) {
  const res = await API.post(`${API_URL}/users/activate`, data, true)
  return res
}

async function getLicensingData(params) {
  const res = await API.get(
    `${API_URL}/users/licensingData/${params.offset}/${params.limit}/${params.year}/${
      params.month
    }/${params.licenseOption}/${params.requestCount ? 1 : 0}`,
    true,
  )
  return res
}

export default {
  getCurrentlyLoggedInUser,
  getAllTeamUsersData,
  getTeamUserDetailData,
  getUserDetailData,
  getAllUsers,
  getStatistics,
  getLoginStatistics,
  getAllUsersPagination,
  getUserBydId,
  deactivateUser,
  activateUser,
  getLicensingData,
  getExport,
}
