import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getConversationGroupsByProductId(data) {
  const res = await API.get(
    `${API_URL}/conversation-groups/${data.productId}/${data.phaseId}`,
    true,
  )
  return res
}

const getConversationById = async (conversationGroupId) => {
  const res = await API.get(`${API_URL}/conversation-groups/${conversationGroupId}`, true)
  return res
}

const addConversationGroup = async (data) => {
  const res = await API.post(`${API_URL}/conversation-groups/add`, data, true)
  return res
}

const editConversationGroup = async (conversationGroupId, data) => {
  const res = await API.put(`${API_URL}/conversation-groups/${conversationGroupId}`, data, true)
  return res
}

const getConversationGroupsFullData = async () => {
  const res = await API.get(`${API_URL}/conversation-groups/full-data`, true)
  return res
}

const deleteConversationGroup = async (conversationGroupId: number | string) => {
  const res = await API.remove(`${API_URL}/conversation-groups/${conversationGroupId}`, true)
  return res
}

const ConversationGroupsApi = {
  getConversationGroupsByProductId,
  addConversationGroup,
  editConversationGroup,
  getConversationGroupsFullData,
  getConversationById,
  deleteConversationGroup,
}

export default ConversationGroupsApi
