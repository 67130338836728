import {Grid, makeStyles} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import Text from 'components/atoms/Text/Text'
import { getRangeOfNumbers } from 'utils'
import moment, {Moment} from 'moment-timezone'
import MaterialSelect from 'components/atoms/MaterialSelect/MaterialSelect'
import { useTranslation } from 'react-i18next'

interface IProps {
  from: Moment,
  // eslint-disable-next-line no-unused-vars
  setFrom: (from) => void,
  to: Moment,
  // eslint-disable-next-line no-unused-vars
  setTo: (to) => void,
}

const useStyles = makeStyles({
  betweenMonths: {
    margin: '0 1rem',
  },
})

const MonthRangeSelector = ({
  from,
  setFrom,
  to,
  setTo,
}: IProps) => {
  const classes = useStyles()
  const [startingMonth, setStartingMonth] = useState(moment().month())
  const [endingMonth, setEndingMonth] = useState(moment().month())
  const [startingYear, setStartingYear] = useState(String(moment().year()))
  const [endingYear, setEndingYear] = useState(String(moment().year()))
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    if (startingYear || endingYear || startingMonth || endingMonth) {
      const fromDate = moment.tz(`${startingMonth + 1}/1/${startingYear}`, 'UTC')
      const toDate = (endingMonth < 11)
        ? moment.tz(`${endingMonth + 2}/1/${endingYear}`, 'UTC').subtract(1, 'seconds')
        : moment.tz(endingYear, 'UTC').endOf('year').subtract(1, 'hour') // UTC fix workaround

      if (toDate < fromDate) {
        setError(true)
      }
      setFrom(fromDate)
      setTo(toDate)
    }
  }, [startingYear, endingYear, startingMonth, endingMonth])


  const { t } = useTranslation()

  const months = moment.months().map((month, index) => ({ name: month, value: String(index) }))
  const years = getRangeOfNumbers(2020, moment().year()).map((yearItem) => ({
    name: String(yearItem),
    value: String(yearItem),
  }))

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text variant="h4">{t('mentor_dashboard.period')}</Text>
      </Grid>
      <Grid item xs={3}>
        <MaterialSelect
          value={months[startingMonth].value}
          label={t('mentor_dashboard.starting_month')}
          onChange={(monthIndex) => setStartingMonth(Number(monthIndex))}
          options={months}
        />
      </Grid>
      <Grid item xs={3}>
        <MaterialSelect
          value={startingYear}
          label={t('mentor_dashboard.year')}
          onChange={(selectedYear) => setStartingYear(selectedYear)}
          options={years}
        />
      </Grid>
      <Grid item xs={3}>
        <MaterialSelect
          value={months[endingMonth].value}
          label={t('mentor_dashboard.ending_month')}
          onChange={(monthIndex) => setEndingMonth(Number(monthIndex))}
          options={months}
          error={from.isAfter(to)}
        />
      </Grid>
      <Grid item xs={3}>
        <MaterialSelect
          value={endingYear}
          label={t('mentor_dashboard.year')}
          onChange={(selectedYear) => setEndingYear(selectedYear)}
          options={years}
          error={from.isAfter(to)}
        />
      </Grid>
    </Grid>
  )
}

export default MonthRangeSelector
