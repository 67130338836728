import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import Text from 'components/atoms/Text/Text'
import { useTranslation } from 'react-i18next'
import SubjectIcon from '@material-ui/icons/Subject'
import { StoreContext } from 'App'
import { observer } from 'mobx-react-lite'
import { setLocalStorage } from 'utils'
import { useHistory } from 'react-router-dom'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ConversationItem from './ConversationItem'
import { CustomButton } from '../atoms/MaterialButton/MaterialButton'
import ArgumentationModal from '../organisms/ArgumentationModal/ArgumentationModal'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    flexGrow: 1,
    width: '100%',
    textAlign: 'left',
    marginRight: theme.spacing(2),
  },
  argumentationsBtn: {
    marginTop: 2,
  },
}))

const itemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    // fontWeight: theme.typography.fontWeightMedium,

    '&$selected > $content': {
      backgroundColor: `#e5e5e5`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'unset',
    },
    '&$selected > $content $label:hover, &$selected:focus > $content $label': {
      backgroundColor: 'unset',
    },
  },
  content: {
    width: 'auto',
    display: 'block',
    color: theme.palette.text.primary,
  },
  group: {
    marginleft: 0,
    marginBottom: 55,
    paddingLeft: 5,
  },
  expanded: {
    // fontWeight: theme.typography.fontWeightMedium
  },
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    padding: 0,
    background: 'transparent',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: 20,
  },
  labelIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  chevron: {
    color: '#7f7f7f',
  },
}))

function ConversationBrowser({
  conversations,
  conversationsWithFiles,
  completedConversations,
  onSampleSelect,
  phase,
}) {
  const history = useHistory()
  const store = useContext(StoreContext)
  const classes = useStyles()
  const [argumentationModalStatus, setArgumentationModalStatus] = useState(false)
  const itemClasses = itemStyles()
  const [groupConversations, setGroupConversation] = React.useState({})
  const [openedConversations, setOpenedConversations] = useState([])
  const [selected, setSelected] = React.useState('')
  const [conversationGroup, setConversationGroup] = useState({ id: null, name: null })
  const [selectedSample, setSelectedSample] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    setGroupConversation(
      conversations.reduce((out, e) => {
        const res = out
        const name = e.group != null ? e.group.name : null

        ;(res[name] = out[name] || []).push(e)

        return res
      }, {}),
    )
  }, [conversations])

  const handleSelect = (event, nodeId) => {
    if (!openedConversations.includes(nodeId)) {
      setOpenedConversations((array) => [...array, nodeId])
    } else {
      const newOpenedConversations = openedConversations.filter(
        (openedConv) => openedConv !== nodeId,
      )
      setOpenedConversations(newOpenedConversations)
    }
    if (!nodeId.startsWith('grp')) {
      setSelected(nodeId)
    } else if (event) {
      event.stopPropagation()
    }
  }

  const handleClick = (conversation) => {
    onSampleSelect(conversation)
    setSelectedSample(conversation)
  }

  function handleGroupSelect(groupId) {
    store.setSelectedGroup(groupId)
    setLocalStorage('selectedGroup', groupId)
    history.push(
      `/learn/channelId=${store.selectedChannel.id}/productId=${store.selectedProductCategory.id}/phaseId=${store.phase}/conversationGroupId=${groupId}`,
    )
  }

  useEffect(() => {
    setTimeout(() => {
      if (store.selectedGroup) {
        handleGroupSelect(store.selectedGroup)
        handleSelect(null, `grp-${store.selectedGroup}`)
      }
    }, 500)
  }, [])

  return (
    <>
      <TreeView
        defaultExpanded={[`grp-${store.selectedGroup}`]}
        selected={selected}
        onNodeSelect={handleSelect}
        className={classes.root}
      >
        {Object.keys(groupConversations)
          .filter((key) => key !== 'null')
          .map((groupName) => (
            <TreeItem
              nodeId={`grp-${groupConversations[groupName][0].conversation_group_id}`}
              key={`grp-${groupConversations[groupName][0].conversation_group_id}`}
              label={
                <div
                  className={itemClasses.labelRoot}
                  onClick={() =>
                    handleGroupSelect(groupConversations[groupName][0].conversation_group_id)
                  }
                >
                  {openedConversations.find(
                    (openedConv) =>
                      openedConv ===
                      `grp-${groupConversations[groupName][0].conversation_group_id}`,
                  ) ? (
                    <ExpandLess className={itemClasses.chevron} />
                  ) : (
                    <ExpandMore className={itemClasses.chevron} />
                  )}
                  <Text variant="h4">{groupName}</Text>
                </div>
              }
              classes={{
                root: itemClasses.root,
                content: itemClasses.content,
                expanded: itemClasses.expanded,
                selected: itemClasses.selected,
                group: itemClasses.group,
                label: itemClasses.label,
              }}
            >
              {groupConversations[groupName].length > 0 &&
                groupConversations[groupName].map((conversation) => {
                  const foundConversationsWithFiles = conversationsWithFiles.find(
                    (conversationWithFiles) => conversationWithFiles.id === conversation.id,
                  )
                  const foundCompletedConversation = completedConversations?.find(
                    (completedConversation) =>
                      completedConversation.conversation_progress[0].conversation.id ===
                        conversation.id && completedConversation.completed,
                  )

                  return (
                    <ConversationItem
                      selected={selectedSample?.id === conversation.id}
                      completed={foundCompletedConversation}
                      nodeId={conversation.name}
                      key={conversation.name}
                      itemClasses={itemClasses}
                      playable={foundConversationsWithFiles?.videos?.length > 0}
                      conversation={conversation}
                      onClick={() => handleClick(conversation)}
                    />
                  )
                })}
              <CustomButton
                color="primary"
                className={classes.argumentationsBtn}
                onClick={() => {
                  setArgumentationModalStatus(!argumentationModalStatus)
                  setConversationGroup(groupConversations[groupName][0].group)
                }}
                startIcon={<SubjectIcon />}
              >
                {t('learning.arguments_and_screenplay')}
              </CustomButton>
            </TreeItem>
          ))}
      </TreeView>
      <ArgumentationModal
        modalStatus={argumentationModalStatus}
        setModalStatus={setArgumentationModalStatus}
        conversationGroupId={conversationGroup?.id}
        conversationGroupName={conversationGroup?.name}
        phase={phase}
      />
    </>
  )
}

export default observer(ConversationBrowser)
