import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import InboxIcon from '@material-ui/icons/Inbox'

import { Item } from './types'

const useStyles = makeStyles({
  draggingListItem: {
    background: 'rgb(235,235,235)',
  },
})

export type DraggableListItemProps = {
  item: Item
  index: number
}

const DraggableListItem = ({ item, index }: DraggableListItemProps) => {
  const classes = useStyles()
  return (
    <Draggable draggableId={item.title} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          <ListItemAvatar>
            <Avatar>
              <InboxIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.title} />
        </ListItem>
      )}
    </Draggable>
  )
}

export default DraggableListItem
