import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    wrapper: {
        flex: '0 1 auto',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto'
    },
    scrollableBody: {
        flex: '0 1 auto',
        overflowY: 'auto',
        padding: '20px',
        paddingBottom: '10px'
    },
    footer: {
        padding: '20px',
        paddingTop: '10px'
    }
})

export interface ScrollableModalBodyProps {
    body?: React.ReactNode;
    footer?: React.ReactNode;
    className?: string
}

const ScrollableModalBody: React.FC<ScrollableModalBodyProps> = ({ body, footer, className }) => {
    const classes = useStyles()

    return (
        <div className={`${classes.wrapper} ${className}`}>
            <div className={classes.scrollableBody}>{body}</div>
            <div className={classes.footer}>{footer}</div>
        </div>
    )
}

export { ScrollableModalBody }