import { Grid, makeStyles, Paper } from '@material-ui/core'
import AuthApi from 'api/auth'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import UnprotectedPage from 'components/organisms/UnprotectedPage/UnprotectedPage'
import AuthLayout from 'layouts/AuthLayout'
import useAbstractMutator from 'providers/AbstractMutator'
import React, { useEffect, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  signInContainer: {
    height: 'auto',
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: "center",
    background: '#fff',
    padding: '30px 30px 30px 30px',
    borderRadius: '0px',
  },
  signInHeading: {
    margin: '0px 0px 45px 0px',
    fontWeight: 800,
    textAlign: 'center',
  },
  signInButton: {
    width: '100%',
    marginTop: 10,
  },

  error: {
    color: '#B00020',
  },
  successMessage: {
    color: '#4BB543',
  },
})

export interface ResetPasswordPageProps {}

const ResetPasswordPage: React.SFC<ResetPasswordPageProps> = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [buttonActivationTime, setButtonActivationTime] = useState(new Date().getTime())
  // eslint-disable-next-line no-undef
  const intervalState = useRef<NodeJS.Timeout>()

  // Used to initiate component re-render for the button timeout purposes
  const [, setRenderer] = useState(0)

  const resetPassword = useAbstractMutator(AuthApi.resetPassword)
  const { t } = useTranslation()

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => () => clearInterval(intervalState.current), [])

  const handleSubmit = async () => {
    await executeRecaptcha('reset_password_request')
    resetPassword.mutate({ email })
    setButtonActivationTime(new Date().getTime() + 10 * 1000)
    setIsButtonDisabled(true)
    intervalState.current = setInterval(() => {
      setRenderer((c) => c + 1)
    }, 1000)
  }

  useEffect(() => {
    if (resetPassword.status) {
      if (resetPassword.status === 201) {
        setSuccessMessage(resetPassword.data.message)
        setError('')
      } else {
        setError(resetPassword.message)
        setSuccessMessage('')
      }
    }
  }, [resetPassword.status])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit()
    }
  }

  const timeLeftUntilButtonActivation = () => {
    const delta = buttonActivationTime - new Date().getTime()
    if (delta <= 0) {
      setIsButtonDisabled(false)
      clearInterval(intervalState.current)
    }
    return Math.ceil(delta / 1000)
  }

  return (
    <UnprotectedPage>
      {AuthLayout.getLayout(
        <div onKeyDown={handleKeyDown}>
          <Paper className={classes.signInContainer}>
            <h1 className={classes.signInHeading}>{t('reset_password.heading')}</h1>
            <Grid
              onKeyDown={handleKeyDown}
              spacing={3}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {error ? (
                <Grid item xs={12} className={classes.error}>
                  {error}
                </Grid>
              ) : null}
              {successMessage ? (
                <Grid item xs={12} className={classes.successMessage}>
                  {successMessage}
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <MaterialInput
                  type="text"
                  label={t('email')}
                  name="email"
                  value={email}
                  onChange={setEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  color="primary"
                  className={classes.signInButton}
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled
                    ? t('reset_password.disabledButton', {
                        seconds: timeLeftUntilButtonActivation(),
                      })
                    : t('reset_password.button')}
                </CustomButton>
              </Grid>
            </Grid>
          </Paper>
        </div>,
      )}
    </UnprotectedPage>
  )
}

export default ResetPasswordPage
