import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CheckBox } from '@material-ui/icons'
import TreeItem from '@material-ui/lab/TreeItem'
import sprintf from 'voca/sprintf'
import { makeStyles } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import classNames from 'classnames'
import Text from 'components/atoms/Text/Text'
import { StoreContext } from 'App'
import { observer } from 'mobx-react-lite'

const useStyles = makeStyles((theme) => ({
  disabled: {
    opacity: 0.3,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  centerBoth: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  completed: {
    color: '#2c5de5',
    marginRight: theme.spacing(1),
  },
}))

function ConversationItem({ conversation, itemClasses, playable, completed, selected, ...other }) {
  const store = useContext(StoreContext)
  const classes = useStyles()
  // const mins = useMemo(() => Math.trunc(conversation.samples[0]?.audio?.duration / 60), [conversation.samples[0]?.audio?.duration])
  // const secs = useMemo(() => conversation.samples[0]?.audio?.duration % 60, [conversation.samples[0]?.audio?.duration])
  const duration = sprintf('%d:%02d', 1, 0)

  function renderIcon() {
    let icon
    if (!selected && completed) {
      return <CheckBox className={classes.completed} />
    }
    if (selected && store.playing) {
      icon = (
        <PauseCircleOutlineIcon style={{ color: '#00008f' }} className={itemClasses.labelIcon} />
      )
    } else {
      icon = (
        <PlayCircleOutlineIcon style={{ color: '#00008f' }} className={itemClasses.labelIcon} />
      )
    }

    return icon
  }

  return (
    <TreeItem
      nodeId="0"
      label={
        <div className={itemClasses.labelRoot}>
          <div className={classNames(classes.container, playable ? null : classes.disabled)}>
            <div className={classes.centerBoth}>
              {renderIcon()}
              <Text variant="body2">{conversation.name}</Text>
            </div>
            <Text variant="caption">{duration}</Text>
          </div>
        </div>
      }
      classes={{
        root: itemClasses.root,
        content: itemClasses.content,
        expanded: itemClasses.expanded,
        selected: itemClasses.selected,
        group: itemClasses.group,
        label: itemClasses.label,
      }}
      {...other}
    />
  )
}

export default observer(ConversationItem)
