import { Button, DialogActions, DialogContent, DialogContentText, Dialog } from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import React from 'react'
import { useTranslation } from 'react-i18next'

type PropType = {
  open: boolean
  handleClose: () => void
  title: string
  description: string
  actionOnDeny: () => void
  actionOnConfirm: () => void
}

const ConfirmationDialog = ({
  open,
  handleClose,
  title,
  description,
  actionOnConfirm,
  actionOnDeny,
}: PropType) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ padding: '1rem 1rem 0 1rem', minWidth: 400 }}>
        <Text variant="h4">{title}</Text>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              actionOnDeny()
              handleClose()
            }}
            autoFocus
          >
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              actionOnConfirm()
              handleClose()
            }}
          >
            {t('ok')}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ConfirmationDialog
