import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core'

export interface LoaderProps {}

const useStyles = makeStyles({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const Loader: React.SFC<LoaderProps> = () => {
  const classes = useStyles()

  return (
    <div className={classes.loaderContainer}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default Loader
