import { uniqBy } from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import ConversationsApi from 'api/conversations'
import useAbstractProvider from 'providers/AbstractProvider'
import { StoreContext } from 'App'

const useLearningConversations = (productId) => {
  const store = useContext(StoreContext)
  const [conversations, setConversations] = useState([])
  const [conversationsWithFiles, setConversationsWithFiles] = useState([])
  const [completedConversations, setCompletedConversations] = useState([])

  useEffect(() => {
    setTimeout(() => {
      if (store?.conversationsProgress) {
        setCompletedConversations(store?.conversationsProgress)
      }
    }, 500)
  }, [store?.conversationsProgress])

  const { data: conversationsByProductId, refetch: refetchConversationsByProductId } =
    useAbstractProvider(() => ConversationsApi.getConversationsByProductId(productId), {}, false)

  // process fetched conversations
  useEffect(() => {
    if (!conversationsByProductId) return
    if (conversationsByProductId.length < 1) return

    // if (conversations.length > 0) return
    conversationsByProductId.sort((a, b) => a.id - b.id)
    const newConversations = conversations.concat(conversationsByProductId)
    setConversations(uniqBy(newConversations, 'id'))
    setConversationsWithFiles(newConversations)
  }, [conversationsByProductId])

  // refetch conversations by product if there is a valid productId and a foundProduct
  useEffect(() => {
    const foundProduct = store.products.find((product) => product.id === parseInt(productId, 10))

    if (!productId || productId === 'undefined' || !foundProduct) return
    refetchConversationsByProductId(productId)
  }, [store.selectedProductCategory, productId, store.products])

  return useMemo(
    () => [conversations, conversationsWithFiles, completedConversations],
    [conversations, conversationsWithFiles, completedConversations],
  ) as [any[], any[], any[]]
}

export default useLearningConversations
