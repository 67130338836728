import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from 'App'
import { makeStyles } from '@material-ui/styles'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import MainLayout from 'layouts/MainLayout'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Intro from 'assets/Intro'
import classNames from 'classnames'
import AvatarSelector from 'components/organisms/AvatarSelector/AvatarSelector'
import ChannelSelector from 'components/organisms/ChannelSelector/ChannelSelector'
import { setLocalStorage } from 'utils'
import Text from 'components/atoms/Text/Text'
import useAbstractProvider from 'providers/AbstractProvider'
import ProductsApi from 'api/products'
import useMedia from 'hooks/useMedia'
import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
  },
  heading2: {
    fontSize: '1.75rem',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 'normal',
    color: '#7F7F7F',
  },
  heading3: {
    fontWeight: 600,
  },
  heading4: {
    marginTop: '3rem',
    fontWeight: 400,
  },
  mainHeading: {
    margin: '60px 0px 13px 0px',
    letterSpacing: '0px',
  },
  selector: {
    marginTop: '2.5rem',
  },
  avatarContainer: {
    marginTop: '13px',
  },
  productContainer: {
    margin: '13px 0px 31px 0px',
  },
  continueBtn: {
    marginTop: '14px',
    paddingBottom: '100px',
  },
})

const WelcomePage = () => {
  const classes = useStyles()
  const store = useContext(StoreContext) as any
  const history = useHistory()
  const { t } = useTranslation()
  const [selectedAvatar, setSelectedAvatar] = useState({
    id: null,
  })
  const [selectedChannel, setSelectedChannel] = useState({
    id: null,
  })
  const { data: productsByChannelId, refetch: getProductsByChannelId } = useAbstractProvider(
    ProductsApi.getProductsByChannelId,
    {},
    false,
  )

  useEffect(() => {
    setSelectedChannel(store.selectedChannel)
  }, [store.selectedChannel])

  const [step, setStep] = useState('selectAvatar')

  function saveSettings() {
    store.setSelectedAvatar(selectedAvatar.id)

    setLocalStorage('selectedAvatar', selectedAvatar.id)
    store.setSelectedChannel(selectedChannel.id)
    setLocalStorage('selectedChannel', selectedChannel?.id)
    store.setOpenSettingsModal(false)
    history.push(`/learn/channelId=${selectedChannel.id}`)
  }

  const url = store?.selectedProductCategory?.id
    ? `/learn/channelId=${store.selectedChannel.id}/productId=${store?.selectedProductCategory?.id}`
    : `/learn/channelId=${store?.selectedChannel?.id}`

  useEffect(() => {
    if (productsByChannelId) {
      store.setProducts(productsByChannelId)
      saveSettings()
    }
  }, [productsByChannelId])

  const { md: isDesktop, isLoading: isUseMediaLoading } = useMedia()

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <div className={classes.container}>
          <Text variant="h1" className={classNames(classes.mainHeading)}>
            {t('welcome.greeting')} {store?.currentUser?.person?.firstName}!
          </Text>

          <Typography className={classes.heading2}>
            {t('welcome.paragraph1')}
          </Typography>
          {isDesktop && !isUseMediaLoading && <Intro width={900} height={163} />}
          <div className={classes.selector}>
            {step === 'selectAvatar' ? (
              <Text variant="h3" className={classes.heading3}>
                {t('welcome.select_avatar')}:
              </Text>
            ) : (
              <Text variant="h3" className={classes.heading3}>
                {t('welcome.select_training_area')}:
              </Text>
            )}
            {step === 'selectAvatar' ? (
              <div className={classes.avatarContainer}>
                <AvatarSelector
                  onChange={(value) => {
                    setSelectedAvatar(value)
                    setStep('selectProduct')
                  }}
                />
              </div>
            ) : (
              <div className={classes.productContainer}>
                <ChannelSelector
                  onChange={(channel) => {
                    setSelectedChannel(channel)
                    store.setSelectedChannel(channel.id)
                    getProductsByChannelId(channel.id)
                  }}
                  showSpecificLanguageChannels
                />
              </div>
            )}

            {step === 'selectAvatar' && store?.selectedChannel?.id ? (
              <Text variant="h4" className={classes.heading4}>
                {t('welcome.continue_text')}:
              </Text>
            ) : null}
          </div>

          {step === 'selectAvatar' && store?.selectedChannel?.id ? (
            <div className={classes.continueBtn}>
              {step === 'selectAvatar' && store?.selectedChannel?.id ? (
                <CustomButton onClick={() => history.push(url)} color="primary">
                  {t('continue')}
                </CustomButton>
              ) : null}
            </div>
          ) : null}
          {step !== 'selectAvatar' && store?.selectedChannel?.id ? (
            <div className={classes.continueBtn}>
              <CustomButton onClick={() => saveSettings()} color="primary">
                {t('welcome.proceed_button')}
              </CustomButton>
            </div>
          ) : null}
        </div>,
      )}
    </ProtectedPage>
  )
}

export default observer(WelcomePage)
