import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { FC, useContext } from 'react'
import { observer } from 'mobx-react-lite'

import { StoreContext } from 'App'
import Text from 'components/atoms/Text/Text'
import useMedia from 'hooks/useMedia'
import { useTranslation } from 'react-i18next'
import ProductItem from './ProductItem'

const useStyles = makeStyles(() => ({
  learningProducts: {
    margin: '15px 0 5px',
  },
  productsHeading: {
    marginBottom: '15px',
  },
  productsHeadingDesktop: {
    padding: '0px 17px',
  },
  text: {
    padding: '0px 17px',
  },
}))

export interface LearningProductsProps {}

const LearningProducts: FC<LearningProductsProps> = () => {
  const store = useContext(StoreContext)
  const { products } = store
  const classes = useStyles()

  const { t } = useTranslation()
  const { md: isDesktop } = useMedia()

  return (
    <div className={classes.learningProducts}>
      <Text
        variant="h3"
        className={classNames(classes.productsHeading, isDesktop && classes.productsHeadingDesktop)}
      >
        {t('learning.products')}
      </Text>
      {products.length < 1 ? (
        <Text variant="body1" className={classes.text}>
          {t('learning.no_products')}
        </Text>
      ) : (
        products.map((product) => <ProductItem key={product.id} product={product} />)
      )}
    </div>
  )
}

export default observer(LearningProducts)
