import { action, makeObservable, observable } from 'mobx'
import { ChannelFullDataType } from 'types/Channel'
import { ConversationFullDataType } from 'types/Conversation'
import { ConversationGroupFullDataType } from 'types/ConversationGroup'
import { ProductFullDataType } from 'types/Product'
import { PhaseFullDataType } from 'types/Phase'
import { getLocalStorage } from 'utils'

const cookiePhase = parseInt(getLocalStorage('selectedPhase'), 10)
const cookieGroup = getLocalStorage('selectedGroup')
const cookieConversation = getLocalStorage('selectedConversation')
const selectedView = getLocalStorage('selectedView')

export type LearningMaterialsFullType = {
  channels: ChannelFullDataType[]
  products: ProductFullDataType[]
  conversationGroups: ConversationGroupFullDataType[]
  conversations: ConversationFullDataType[]
  phases: PhaseFullDataType[]
}

export type ConversationPhaseType = {
  id: number
  name: string
}

class Store {
  currentUser = {}

  language = 'de'

  languages = ['de', 'fr', 'it']

  openSettingsModal = false

  selectedAvatar = { id: null, gender: null }

  selectedProductCategory = { id: null }

  selectedLearningType = { id: 1 }

  products = []

  productPhases: ConversationPhaseType[] = []

  avatars = []

  configs = null

  logo = null

  conversationsProgress = []

  channels = []

  selectedChannel = { id: null }

  phase = cookiePhase > 0 ? cookiePhase : 1

  selectedGroup = cookieGroup ?? ''

  selectedConversation = cookieConversation ?? ''

  selectedView: 'mentor' | 'trainee' | 'manager' = selectedView

  videoPlayer: any = null

  cloudfrontUrl = null
  
  initialPhases = true

  learningMaterialsFull: LearningMaterialsFullType = {
    channels: [],
    products: [],
    phases: [],
    conversationGroups: [],
    conversations: [],
  }

  presenters = []

  voices = []

  voiceStyles = []

  playing = false

  setCurrentUser(user) {
    this.currentUser = user
  }

  setInitialPhases(initialPhase){
    this.initialPhases = initialPhase
  }

  setOpenSettingsModal(value) {
    this.openSettingsModal = value
  }

  setSelectedAvatar(value) {
    const foundAvatar = this.avatars.find((avatar) => avatar.id === value)

    this.selectedAvatar = foundAvatar
  }

  setSelectedProductCategory(value) {
    const foundProduct = this.products.find((product) => product.id === value)

    this.selectedProductCategory = foundProduct
  }

  setSelectedLearningType(value) {
    this.selectedLearningType = value
  }

  setProducts(value) {
    this.products = value
  }

  setProductPhases(value: ConversationPhaseType[]) {
    this.productPhases = value
  }

  setAvatars(value) {
    this.avatars = value
  }

  setConfigs(value) {
    this.configs = value
  }

  setConversationsProgress(value) {
    this.conversationsProgress = value
  }

  setLanguage(value) {
    this.language = value
  }

  setLanguages(value) {
    this.languages = value
  }

  setChannels(value) {
    this.channels = value
  }

  setSelectedChannel(value) {
    const foundChannel = this.channels.find((channel) => channel?.id === value)
    this.selectedChannel = foundChannel
  }

  setPhase(value) {
    this.phase = value
  }

  setSelectedConversation(value) {
    this.selectedConversation = value
  }

  setSelectedGroup(value) {
    this.selectedGroup = value
  }

  setSelectedView(value) {
    this.selectedView = value
  }

  setChannelsFullData(value) {
    this.learningMaterialsFull = {
      ...this.learningMaterialsFull,
      channels: value,
    }
  }

  setProductsFullData(value) {
    this.learningMaterialsFull = {
      ...this.learningMaterialsFull,
      products: value,
    }
  }

  setConversationGroupsFullData(value) {
    this.learningMaterialsFull = {
      ...this.learningMaterialsFull,
      conversationGroups: value,
    }
  }

  setConversationsFullData(value) {
    this.learningMaterialsFull = {
      ...this.learningMaterialsFull,
      conversations: value,
    }
  }

  setPhasesFullData(value) {
    this.learningMaterialsFull = {
      ...this.learningMaterialsFull,
      phases: value,
    }
  }

  getChannelNameById(channelId: number) {
    const channel = this.learningMaterialsFull.channels.find((item) => item.id === channelId)
    if (channel) return channel.translations[this.language]?.name || channel.translations.de.name
    return null
  }

  getProductNameById(productId: number) {
    const product = this.learningMaterialsFull.products.find((item) => item.id === productId)
    if (product) return product.translations[this.language]?.name || product.translations.de.name
    return null
  }

  getPhaseNameById(phaseId: number) {
    const phase = this.learningMaterialsFull.phases.find((item) => item.id === phaseId)
    if (phase) return phase.translations[this.language]?.name || phase.translations.de.name
    return null
  }

  getConversationGroupNameById(conversationGroupId: number) {
    const conversationGroup = this.learningMaterialsFull.conversationGroups.find(
      (item) => item.id === conversationGroupId,
    )
    if (conversationGroup)
      return (
        conversationGroup.translations[this.language]?.name ||
        conversationGroup.translations.de.name
      )
    return null
  }

  setLogo(value: string) {
    this.logo = value
  }

  setCloudfrontUrl(value: string) {
    this.cloudfrontUrl = value
  }

  setVideoPlayer(value: any) {
    this.videoPlayer = value
  }

  handlePlayVideo() {
    if (this.videoPlayer.current) {
      this.videoPlayer.current.play()
      this.setPlaying(true)
    }
  }

  handleStopVideo() {
    if (this.videoPlayer.current) {
      this.videoPlayer.current.pause()
      this.setPlaying(false)
    }
  }

  setPresenters(value) {
    this.presenters = value
  }

  setVoices(value) {
    this.voices = value
  }

  setVoiceStyles(value) {
    this.voiceStyles = value
  }

  setPlaying(value) {
    this.playing = value
  }

  constructor() {
    makeObservable(this, {
      currentUser: observable,
      initialPhases: observable,
      setInitialPhases: action,
      setCurrentUser: action,
      openSettingsModal: observable,
      setOpenSettingsModal: action,
      selectedAvatar: observable,
      setSelectedAvatar: action,
      selectedProductCategory: observable,
      setSelectedProductCategory: action,
      products: observable,
      productPhases: observable,
      selectedLearningType: observable,
      setSelectedLearningType: action,
      setProducts: action,
      avatars: observable,
      setAvatars: action,
      setConversationsProgress: action,
      language: observable,
      setLanguage: action,
      languages: observable,
      setLanguages: action,
      channels: observable,
      setChannels: action,
      selectedChannel: observable,
      setSelectedChannel: action,
      selectedConversation: observable,
      setSelectedConversation: action,
      phase: observable,
      setPhase: action,
      selectedGroup: observable,
      setSelectedGroup: action,
      selectedView: observable,
      setSelectedView: action,
      configs: observable,
      setConfigs: action,
      learningMaterialsFull: observable,
      setChannelsFullData: action,
      setProductsFullData: action,
      setConversationGroupsFullData: action,
      setConversationsFullData: action,
      setPhasesFullData: action,
      getChannelNameById: action,
      getProductNameById: action,
      getPhaseNameById: action,
      getConversationGroupNameById: action,
      setLogo: action,
      logo: observable,
      cloudfrontUrl: observable,
      setCloudfrontUrl: action,
      videoPlayer: observable,
      setVideoPlayer: action,
      handlePlayVideo: action,
      handleStopVideo: action,
      presenters: observable,
      setPresenters: action,
      voices: observable,
      setVoices: action,
      voiceStyles: observable,
      setVoiceStyles: action,
      playing: observable,
      setPlaying: action,
    })
  }
}

export default Store
