import * as React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Button, Dialog } from '@material-ui/core'
import { DropResult } from 'react-beautiful-dnd'
import DraggableList from './DraggableList'

export interface SimpleDialogProps {
  open: boolean
  // eslint-disable-next-line no-unused-vars
  handleClose: (data?: { id: number; title: string }[]) => void
  phases: { id: number; title: string }[]
}

const useStyles = makeStyles({
  flexPaper: {
    flex: 1,
    margin: 16,
    minWidth: 350,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const PhaseOrderChangeDialog: React.FC<SimpleDialogProps> = ({ handleClose, open, phases }) => {
  const classes = useStyles()
  const [items, setItems] = React.useState(phases)

  const { t } = useTranslation()

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return

    const result = Array.from(items)
    const [removed] = result.splice(source.index, 1)
    result.splice(destination.index, 0, removed)

    setItems(result)
  }

  React.useEffect(() => {
    setItems(phases)
  }, [open])

  return (
    <Dialog onClose={() => handleClose()} open={open}>
      <div
        style={{
          padding: '1rem 1rem 1rem 1rem',
        }}
      >
        <div className={classes.root}>
          <DraggableList items={items} onDragEnd={onDragEnd} />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <Button
            onClick={async () => {
              handleClose(items)
            }}
            variant="contained"
            color="primary"
          >
            {t('save')}
          </Button>
          <Button color="secondary" onClick={() => handleClose()} variant="contained">
            {t('cancel')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default PhaseOrderChangeDialog
