import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function create(text, languageCode, os, screen, browser, ip, cookie) {
  const res = await API.post(
    `${API_URL}/contact-messages`,
    { text, os, screen, browser, ip, cookie, languageCode }
    , true)

  return res
}

const ContactMessagesApi = {
  create,
}

export default ContactMessagesApi
