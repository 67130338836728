import React, { FC, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import useAbstractMutator from 'providers/AbstractMutator'
import TeamsApi from 'api/teams'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { useHistory } from 'react-router-dom'
import AdminLayout from 'layouts/AdminLayout'
import { showToast } from 'utils'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
})

interface AddNewTeamProps {}

const AddNewTeam: FC<AddNewTeamProps> = () => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const newTeam = useAbstractMutator(TeamsApi.addNewTeam)
  const history = useHistory()

  const { t } = useTranslation()

  useEffect(() => {
    if (newTeam.data) {
      showToast(t('admin_users_and_teams.add_team_success_msg'))
      history.push('/admin-view/all-teams')
    }
  }, [newTeam.data])

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Text variant="h2" className={classes.heading}>
            {t('admin_users_and_teams.add_team')}
          </Text>
          <Grid container>
            <Grid item xs={12}>
              <MaterialInput
                type="text"
                label={t('name')}
                name="name"
                value={name}
                onChange={setName}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                className={classes.saveBtn}
                onClick={() => {
                  if (!name) {
                    showToast(t('required_fields_alert'))
                  } else {
                    newTeam.mutate({ name })
                  }
                }}
              >
                {t('save')}
              </CustomButton>
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default AddNewTeam
