import React from 'react'

const LoggedInContext = React.createContext({
  loggedIn: false,
  setLoggedIn: () => {},
})

const TagsContext = React.createContext({
  tags: [],
  setTags: () => {},
})

export { LoggedInContext, TagsContext }
