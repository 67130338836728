import React, { FC, useEffect, useState } from 'react'
import UserApi from 'api/user'
import TeamsApi from 'api/teams'
import UserTeamsApi from 'api/userTeams'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import useAbstractProvider from 'providers/AbstractProvider'
import { useParams } from 'react-router-dom'
import Loader from 'components/atoms/Loader/Loader'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import useAbstractMutator from 'providers/AbstractMutator'
import { showToast } from 'utils'
import AdminLayout from 'layouts/AdminLayout'
import { useTranslation } from 'react-i18next'

interface ParamsProps {
  userId: string
}

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
  teams: {
    marginTop: 30,
    marginBottom: 30,
  },
  activity: {
    marginTop: 30,
    fontSize: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
})

interface UserDetailPageProps {}

const UserDetailPage: FC<UserDetailPageProps> = () => {
  const classes = useStyles()
  const { userId } = useParams<ParamsProps>()

  const [data, setData] = useState(null)

  const user = useAbstractProvider(UserApi.getUserBydId, { userId }, false)
  const teams = useAbstractProvider(TeamsApi.getUsersTeams, { userId }, false)
  const teamsUserIsNotIn = useAbstractProvider(TeamsApi.getTeamsUserIsNotIn, { userId }, false)
  const addUserTeam = useAbstractMutator(UserTeamsApi.addUserToTeam)
  const removeUserTeam = useAbstractMutator(UserTeamsApi.removeUserToTeam)
  const deactivateUserMutator = useAbstractMutator(UserApi.deactivateUser)
  const activateUserMutator = useAbstractMutator(UserApi.activateUser)

  const { t } = useTranslation()

  useEffect(() => {
    if (userId) {
      user.refetch(userId)
      teams.refetch(userId)
      teamsUserIsNotIn.refetch(userId)
    }
  }, [userId])

  useEffect(() => {
    if (user?.data) {
      const newUserData = user.data
      if (newUserData.ssoData) {
        delete newUserData.ssoData?.email
        delete newUserData.ssoData?.given_name
        delete newUserData.ssoData?.family_name
      }
      setData(user?.data)
    }
  }, [user.data])

  useEffect(() => {
    if (addUserTeam?.data) {
      teams.refetch(userId)
      teamsUserIsNotIn.refetch(userId)
      showToast(t('admin_users_and_teams.add_to_team_success_msg'))
    }
  }, [addUserTeam.data])

  useEffect(() => {
    if (removeUserTeam?.data) {
      teams.refetch(userId)
      teamsUserIsNotIn.refetch(userId)
      showToast(t('admin_users_and_teams.remove_from_team_success_msg'))
    }
  }, [removeUserTeam.data])

  useEffect(() => {
    if (deactivateUserMutator?.data || activateUserMutator?.data) {
      window.location.reload()
    }
  }, [deactivateUserMutator, activateUserMutator])

  const handleUserActivity = (isCurrentlyActive: boolean) => {
    if (isCurrentlyActive) {
      deactivateUserMutator.mutate({ id: userId })
    } else {
      activateUserMutator.mutate({ id: userId })
    }
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Text variant="h2" className={classes.heading}>
            {t('admin_users_and_teams.user_detail')}
          </Text>
          <Grid container>
            <Grid item xs={12}>
              {user.loading ? (
                <Loader />
              ) : (
                <div>
                  <div>
                    {t('first_name')}: {data?.firstName}
                  </div>
                  <div>
                    {t('last_name')}: {data?.lastName}
                  </div>
                  <div>
                    {t('email')}: {data?.email}
                  </div>
                  <br />
                  <div>
                    <b>{t('ssoData')}:</b>
                    {data?.ssoData ? (
                      Object.keys(data.ssoData).map((key) => (
                        <div>
                          {key}: {data?.ssoData[key]}
                        </div>
                      ))
                    ) : (
                      <b> N/A</b>
                    )}
                  </div>
                  <div className={classes.activity}>
                    <p>
                      {t('active')}: {data?.active ? t('yes') : t('no')}
                    </p>
                    <CustomButton onClick={() => handleUserActivity(data?.active)}>
                      {!data?.active ? t('activate') : t('deactivate')}
                    </CustomButton>
                  </div>
                  <div className={classes.teams}>
                    <h2>{t('teams')}</h2>
                    {teams?.data?.map((team) => (
                      <Grid key={`team-${team.id}`} container alignItems="center" spacing={4}>
                        <Grid item xs={4}>
                          {team.id} - {team.name}
                        </Grid>
                        <Grid item xs={4}>
                          <CustomButton
                            onClick={() => {
                              removeUserTeam.mutate({ teamId: team.id, userId: data?.id })
                            }}
                          >
                            {t('delete')}
                          </CustomButton>
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <h2>{t('admin_users_and_teams.add_user_to_team')}</h2>

              {teamsUserIsNotIn?.data?.map((team) => (
                <Grid key={`teamsUserIsNotIn-${team.id}`} container alignItems="center" spacing={4}>
                  <Grid item xs={4}>
                    {team.id} - {team.name}
                  </Grid>
                  <Grid item xs={4}>
                    <CustomButton
                      onClick={() => {
                        addUserTeam.mutate({ teamId: team.id, userId: data?.id })
                      }}
                    >
                      {t('add')}
                    </CustomButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default UserDetailPage
