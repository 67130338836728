import React from 'react'
import { Paper, makeStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'transparent',
  },
  pointer: {
    cursor: 'pointer',
  },
  highlightOnHover: {
    padding: 4,
    '&:hover': {
      boxShadow:
        '0px 0px 5px -2px #5c6bc0, 0px 2px 5px 0px #5c6bc0, 0px 1px 5px 0px #5c6bc0 !important',
    },
  },
  selectedItem: {
    border: '2px solid #2c5de5 !important',
  },

  centerBoth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '16px',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}))

export interface NavigationBoxProps {
  text: string
  link?: string,
  icon: any,
}

const NavigationBox: React.SFC<NavigationBoxProps> = ({ text, link, icon }) => {
  const classes = useStyles()

  return (
    <Link to={link}>
      <Paper
        square
        onClick={() => {}}
        className={classNames(
          classes.paper,
          classes.centerBoth,
          classes.pointer,
          classes.highlightOnHover,
        )}
      >
        <div
          className={classes.container}
          style={{
            height: '230px',
            minWidth: '230px',
          }}
        >
          <div>
            <div className={classes.icon}>
              {icon}
            </div>
            <h2 style={{ textAlign: 'center' }}>{text}</h2>
          </div>
        </div>
      </Paper>
    </Link>
  )
}

export default observer(NavigationBox)
