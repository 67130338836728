import AdminRoutes from 'AdminRoutes'
import UserApi from 'api/user'
import { StoreContext } from 'App'
import ContentManagerRoutes from 'ContentManagerRoutes'
import InitStore from 'hooks/InitStore'
import MentorsRoutes from 'MentorsRoutes'
import { observer } from 'mobx-react-lite'
import useAbstractProvider from 'providers/AbstractProvider'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import TraineeRoutes from 'TraineeRoutes'

const MainRoutes = () => {
  const history = useHistory()

  const store = useContext(StoreContext)
  const { data: currentlyLoggedInUser, status } = useAbstractProvider(
    UserApi.getCurrentlyLoggedInUser,
  )

  useEffect(() => {
    if (currentlyLoggedInUser) {
      store.setCurrentUser(currentlyLoggedInUser)
    }

    if (status === 401) {
      history.push('/sign-in')
    }
  }, [currentlyLoggedInUser, status])

  const generateMainRoutes = () => {
    if (!store.currentUser?.role) return <div />
    if (store.currentUser.role.name === 'mentor') {
      return (
        <>
          <MentorsRoutes />
          <TraineeRoutes />
        </>
      )
    }
    if (store.currentUser.role.name === 'trainee') {
      return <TraineeRoutes />
    }
    if (store.currentUser.role.name === 'admin') {
      return <AdminRoutes />
    }
    if (store.currentUser.role.name === 'contentmanager') {
      return <ContentManagerRoutes />
    }
    return <div />
  }

  return (
    <>
      {generateMainRoutes()}
      <InitStore />
    </>
  )
}

export default observer(MainRoutes)
