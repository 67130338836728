import {CircularProgress, Grid, makeStyles, Tooltip as Tooltip2} from '@material-ui/core'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Text from 'components/atoms/Text/Text'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { useTranslation } from 'react-i18next'
import MonthRangeSelector from 'components/organisms/MonthRangeSelector/MonthRangeSelector'
import UserApi from 'api/user'
import useAbstractProvider from 'providers/AbstractProvider'
import { dhm } from 'utils'
import moment, {Moment} from 'moment-timezone'
import StatBoxComponent from './StatBoxComponent'
import Loader from '../../components/atoms/Loader/Loader'
import ChannelSelectbox from '../../components/organisms/ChannelSelectbox/ChannelSelectbox'
import MaterialSelect from '../../components/atoms/MaterialSelect/MaterialSelect'
import {StoreContext} from '../../App'
import ProductsApi from '../../api/products'
import {ProductFullDataType} from '../../types/Product'
import {CustomButton} from '../../components/atoms/MaterialButton/MaterialButton'
import MainLayout from '../../layouts/MainLayout'
import ProtectedPage from '../../components/organisms/ProtectedPage/ProtectedPage'
import {exportUserStatistics} from '../../utils/exportXlsx'

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    marginBottom: 50,
  },
  heading: {
    marginBottom: 45,
  },
  barChartHeading: {
    marginBottom: 20,
  },
  betweenMonths: {
    margin: '0 1rem',
  },
  statBox: {
    height: '200px',
  },
  barChart: {
    width: '100%',
  },
  loadingText: {
    // fontStyle: 'italic',
    marginTop: 10,
    color: '#00008f'
  },
  exportButton: {
    width: '50%',
  },
})

const StatisticsDashboard = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const store = useContext(StoreContext) as any
  const productsProvider = useAbstractProvider(ProductsApi.getProductsFullData, null, false)

  const [channelId, setChannelId] = useState('')
  const prevChannelIdRef = useRef('')
  const [productId, setProductId] = useState('')
  const prevProductIdRef = useRef('')
  const [totalLogins, setTotalLogins] = useState(0)
  const [totalTime, setTotalTime] = useState<any>()
  const [totalArgumentations, setTotalArgumentations] = useState(0)
  const [totalRecordings, setTotalRecordings] = useState(0)
  const [totalFeedbacks, setTotalFeedbacks] = useState(0)
  const [activeUsers, setActiveUsers] = useState(0)
  const [allUsers, setAllUsers] = useState(0)
  const [loginData, setLoginData] = useState(null)
  const [from, setFrom] = useState<Moment>(moment())
  const [to, setTo] = useState<Moment>(moment())
  const [products, setProducts] = useState<ProductFullDataType[] | undefined>([])

  const users = useAbstractProvider(
    UserApi.getStatistics,
    {
      from,
      to,
      channelId,
      productId,
    },
    false,
  )
  const loginStatistics = useAbstractProvider(
    UserApi.getLoginStatistics,null, false,
  )

  const [exportLoading, setExportLoading] = useState<boolean>(false)
  const exportXlsx = useAbstractProvider(
    UserApi.getExport,null, false,
  )

  const handleExportStatistics = () => {
    setExportLoading(true)
    exportXlsx.refetch({from, to, channelId, productId})
  }

  useEffect(() => {
    if (exportXlsx?.data) {
      setExportLoading(false)
      const channel = store.channels.find(({id}) => id === channelId)
      const product = products.find(({id}) => id === Number(productId))
      exportUserStatistics(
        `user_statistics_export_${from.format('M/Y')}-${to.format('M/Y')}`, exportXlsx.data,
        {channel: channel?.name, product: product?.translations.de?.name, from: from.format('DD/MM/YYYY'), to: to.format('DD/MM/YYYY')}
      )
    }
  }, [exportXlsx.data])

  useEffect(() => {
    const channelIdChanged = prevChannelIdRef.current !== channelId
    if (channelIdChanged) {
      setProductId('')
    }
    if (from || to) users.refetch({ from, to, channelId, productId: (channelIdChanged ? '' : productId) })
  }, [channelId])

  useEffect(() => {
    const productIdChanged = prevProductIdRef.current !== productId
    if (productIdChanged || from || to) {
      users.refetch({ from, to, channelId, productId })
    }
  }, [productId])

  useEffect(() => {
    if (from || to) {
      users.refetch({ from, to, channelId, productId })
      loginStatistics.refetch({from, to})
    }
  }, [from, to])

  useEffect(() => {
    if (!store.learningMaterialsFull.products.length) {
      productsProvider.refetch()
    } else {
      setProducts(
        store.learningMaterialsFull.products.filter((item) => item.channelId === channelId),
      )
    }
  }, [])

  useEffect(() => {
    if (productsProvider.data) {
      store.setProductsFullData(productsProvider.data)
      setProducts(productsProvider.data.filter((item) => item.channelId === channelId))
    }
  }, [productsProvider.data])

  useEffect(() => {
    const filteredProducts = store.learningMaterialsFull.products.filter((item) => item.channelId === channelId)
    if (products) {
      setProducts(filteredProducts)
    }
  }, [channelId])

  useEffect(() => {
    if (users?.data) {
      setTotalLogins(users.data.logins)
      setActiveUsers(users.data.activeUsersCount)
      setTotalTime(dhm(users.data.timeSpent))
      setTotalArgumentations(users.data.argumentationsCount)
      setTotalRecordings(users.data.recordingsCount)
      setTotalFeedbacks(users.data.feedbacksCount)
      setAllUsers(users.data.count)
    }
  }, [users.data])

  useEffect(() => {
    if (loginStatistics.data) {
      setLoginData(loginStatistics.data ?? null)
    }
  }, [loginStatistics.data])

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
          <div className={classes.container}>
            <Text variant="h2" className={classes.heading}>
              {t('mentor_dashboard.statistics_dashboard')}
            </Text>
            <Grid container spacing={2}>
              <Tooltip2 title={from.isAfter(to) ? t('mentor_dashboard.date_range_error') : ''}>
              <Grid container item xs={12} alignItems="center">
                <MonthRangeSelector
                  from={from}
                  setFrom={setFrom}
                  to={to}
                  setTo={setTo}
                />
              </Grid>
              </Tooltip2>
              <Grid container item xs={4} alignItems="center">
                <ChannelSelectbox channel={channelId} setChannel={setChannelId} />
              </Grid>
              <Grid container item xs={4} alignItems="center">
                <Grid item xs={12}>
                  <Text variant="h4">{t('product')}</Text>
                </Grid>
                <Grid item xs={12}>
                  <MaterialSelect
                    value={productId ?? ''}
                    onChange={(pid) => setProductId(pid)}
                    options={[
                      {name: t('mentor_dashboard.all_products'), value: ''},
                      ...products.map(({id, translations}) => ({name: translations?.de?.name, value: String(id)})),
                    ]}
                    displayEmpty
                  />
                </Grid>
              </Grid>
              <Grid container item xs={4} alignItems="flex-end" justifyContent="flex-end">
                {exportLoading && <CircularProgress />}
                <CustomButton
                  className={classes.exportButton}
                  color="primary"
                  onClick={handleExportStatistics}
                  disabled={exportLoading}
                >
                  {t('mentor_dashboard.export_as_excel')}
                </CustomButton>
              </Grid>
              {users.loading ? (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: '50%', minHeight: '300px'}}>
                  <Loader />
                  <Text variant="body2" className={classes.loadingText}>{t('mentor_dashboard.loading_statistics')}</Text>
                </div>

              ) : (
                <>
                  <Grid container item xs={12} spacing={2} style={{ marginTop: '1rem' }}>
                    {[
                      {
                        title: t('mentor_dashboard.finished_argumentations'),
                        subtitle: t('mentor_dashboard.finished_argumentations_subtitle'),
                        value: <span style={{ fontSize: '4rem', color: '#00018f', fontWeight: 800 }}>{totalArgumentations}</span>
                      },
                      {
                        title: t('mentor_dashboard.finished_recordings'),
                        subtitle: t('mentor_dashboard.finished_recordings_subtitle'),
                        value: <span style={{ fontSize: '4rem', color: '#00018f', fontWeight: 800 }}>{totalRecordings}</span> },
                      {
                        title: t('mentor_dashboard.feedbacks'),
                        subtitle: t('mentor_dashboard.feedbacks_subtitle'),
                        value: <span style={{ fontSize: '4rem', color: '#00018f', fontWeight: 800 }}>{totalFeedbacks}</span>
                      },
                      {
                        title: t('mentor_dashboard.number_of_logins'),
                        subtitle: t('mentor_dashboard.number_of_logins_subtitle'),
                        value: <span style={{ fontSize: '4rem', color: '#00018f', fontWeight: 800 }}>{totalLogins}</span> },
                      {
                        title: t('mentor_dashboard.training_duration'),
                        subtitle: t('mentor_dashboard.training_duration_subtitle'),
                        value: <>
                          <span style={{ fontSize: '4rem', color: '#00018f', fontWeight: 800 }}>{totalTime.days}</span>
                          <span style={{ fontSize: '1rem', color: '#000000' }}>{t('mentor_dashboard.days')}</span>
                          <span style={{ fontSize: '4rem', color: '#00018f', fontWeight: 800 }}>{totalTime.hours}</span>
                          <span style={{ fontSize: '1rem', color: '#000000' }}>{t('mentor_dashboard.hours')}</span>
                        </>
                      },
                      {
                        title: t('mentor_dashboard.active_workers'),
                        subtitle: `(${t('mentor_dashboard.out_of')} ${allUsers})`,
                        value: <span style={{ fontSize: '4rem', color: '#00018f', fontWeight: 800 }}>{activeUsers}</span>
                      },
                    ].map((item) => (
                      <Grid key={item.title} item xs={12} sm={6} md={4} className={classes.statBox}>
                        <StatBoxComponent title={item.title} subtitle={item.subtitle} value={item.value} />
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '0.5rem' }}>
                    <Text variant="h3"  className={classes.barChartHeading}>{`${t('mentor_dashboard.verlauf')} ${from.year() === to.year() ? from.year() : `${from.year()}-${to.year()}`}`}</Text>
                    {loginData ? (
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart className={classes.barChart} data={loginData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Bar dataKey="Login Count" stackId="a" fill="#4040ab" />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : null}
                  </Grid>
                </>
              )
              }
            </Grid>
          </div>
      )}
    </ProtectedPage>
  )
}

export default StatisticsDashboard
