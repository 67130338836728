import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getRecordingFeedbackByRecordingId(recordingId) {
  const res = await API.get(`${API_URL}/recording-feedback/${recordingId}`, true)
  return res
}

async function saveRecordingFeedack(recordingFeedback) {
  const res = await API.post(
    `${API_URL}/recording-feedback`,
    {
      ...recordingFeedback,
    },
    true,
  )
  return res
}

async function editRecordingFeedback(recordingFeedback) {
  const res = await API.put(
    `${API_URL}/recording-feedback`,
    {
      ...recordingFeedback,
    },
    true,
  )
  return res
}

async function deleteRecordingFeedback(recordingFeedbackId) {
  const res = await API.remove(`${API_URL}/recording-feedback/${recordingFeedbackId}`, true)
  return res
}

const RecordingFeedbackApi = {
  getRecordingFeedbackByRecordingId,
  saveRecordingFeedack,
  editRecordingFeedback,
  deleteRecordingFeedback,
}

export default RecordingFeedbackApi
