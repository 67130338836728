import { Grid, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'

import { StoreContext } from 'App'
import ArgumentationsApi from 'api/argumentations'
import ConversationGroupsApi from 'api/conversationGroups'
import ConversationRecordingsApi from 'api/conversationRecordings'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Text from 'components/atoms/Text/Text'
import WebcamStreamCapture from 'components/learning/WebcamStreamCapture'
import ConfirmModal from 'components/atoms/ConfirmModal/ConfirmModal'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import useMedia from 'hooks/useMedia'
import useAbstractMutator from 'providers/AbstractMutator'
import useAbstractProvider from 'providers/AbstractProvider'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { sanitize } from 'dompurify'
import { v4 } from 'uuid'
import SendIcon from '@material-ui/icons/Send'
import CustomBreadcrumbs from 'components/organisms/CustomBreadcrumbs/CustomBreadcrumbs'
import { useHistory } from 'react-router-dom'
import { setLocalStorage } from 'utils'
import { MAX_RECORDING_LIMIT } from '../../constants'
import CancelButton from '../atoms/ColorButton/CancelButton'

export interface RecordingMainProps {
  productId: any
}

const videoIcon = {
  width: '100%',
  height: '80px',
  background: '#99999999 0% 0% no-repeat padding-box',
  border: '1px solid #0000001F',
  borderRadius: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '2rem',
  '@media (min-width: 601px)': {
    width: '124px',
    height: '73px',
    marginBottom: 0,
  },
}

const useStyles = makeStyles((theme) => ({
  placeholder: videoIcon,
  videoIcon: {
    ...videoIcon,
    backgroundColor: theme.palette.info.main,
  },
  groupTitle: {
    marginBottom: 14,
  },
  icon: {
    fontSize: '60px',
    color: 'white',
  },
  headerContainer: {
    paddingLeft: 10,
  },
  modalContainer: {
    padding: 30,
  },
  stepText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  step: {
    backgroundColor: '#f07662',
    width: '60px',
    height: '60px',
    borderRadius: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  stepNumber: {
    color: '#fff',
    fontSize: '40px',
  },
  text: {
    // width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #0000001F',
    borderRadius: '0px',
    opacity: 1,
    padding: '12px',
    height: '73px',
    overflowY: 'scroll',
    // maxWidth: '200px',
    '@media (max-width:600px)': {
      maxWidth: 'calc(100vw - 39px)',
      maxHeight: '150px',
      height: 'auto',
    },
  },
  textModal: {
    width: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #0000001F',
    borderRadius: '0px',
    opacity: 1,
    padding: '20px',
  },
  groupContainer: {
    marginBottom: 63,
  },
  argumentationButton: {
    marginTop: 30,
  },
  myArgumentation: {
    marginBottom: 7,
  },
  groupName: {
    marginBottom: 18,
  },
  myArgumentationHeadingModal: {
    marginBottom: 20,
    verticalAlign: 'bottom',
    lineHeight: '30px',
  },
  webcam: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #0000001F',
    borderRadius: '0px',
    maxHeight: '100%',
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  video: {
    maxHeight: '75vh',
    // objectFit: 'fill'
  },
  published: {
    display: 'flex',
    alignItems: 'center',
    color: '#41B883',
  },
  checkbox: {
    marginRight: 5,
  },
  error: {
    color: '#B00020',
    marginLeft: 10,
  },
  removeBtnAndError: {
    display: 'flex',
    alignItems: 'center',
  },
  scrollableContainer: {
    maxHeight: '100%',
    display: 'flex',
    flex: '0 1 auto',
    overflowY: 'auto',
    minHeight: '70vh',
  },
  argumentationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
  },
  textScrollableContainer: {
    flex: '0 1 auto',
    '@media (min-width: 960px)': {
      overflowY: 'auto',
    },
  },
  limitHeight: {
    display: 'flex',
    maxHeight: '100%',
  },
  publishBtn: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  title: {
    marginBottom: 9,
  },
  modalHeading: {
    marginTop: 29,
    marginBottom: 10,
  },
}))

const RecordingMain: React.FC<RecordingMainProps> = ({ productId }) => {
  const classes = useStyles()
  const store = useContext(StoreContext)
  const history = useHistory()
  const { t } = useTranslation()
  const { phase } = store
  const [videoModalStatus, setVideoModalStatus] = useState<boolean>(false)
  const [recordingModalStatus, setRecordingModalStatus] = useState<boolean>(false)
  const [deleteConfirmModalStatus, setDeleteConfirmModalStatus] = useState<boolean>(false)
  const [recordingToDelete, setRecordingToDelete] = useState(null)
  const [allArgumentations, setAllArgumentations] = useState([])
  const [allRecordings, setAllRecordings] = useState([])
  const [selectedRecording, setSelectedRecording] = useState(null)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [foundRecordings, setFoundRecordings] = useState(null)
  const [error, setError] = useState(null)
  const { data: deleteRecordingData, mutate: deleteRecording } = useAbstractMutator(
    ConversationRecordingsApi.deleteRecording,
  )

  const {
    mutate: editRecording,
    status: editRecordingStatus,
    data: editRecordingData,
  } = useAbstractMutator(ConversationRecordingsApi.editRecording)

  const { data: conversationGroups, refetch } = useAbstractProvider(
    ConversationGroupsApi.getConversationGroupsByProductId,
    { productId, phaseId: phase },
  )

  const { data: recordings, refetch: refetchConversationRecordingsByGroupId } = useAbstractProvider(
    ConversationRecordingsApi.getRecordingsByGroupId,
    {},
    false,
  )

  const { data: argumentations, refetch: refetchArgumentations } = useAbstractProvider(
    ArgumentationsApi.getArgumentations,
    {},
    false,
  )

  const refetchConversationRecordings = () => {
    setAllRecordings([])
    for (let i = 0; i < conversationGroups.length; i++) {
      refetchConversationRecordingsByGroupId(conversationGroups[i].id)
    }
  }

  useEffect(() => {
    if (productId && phase) {
      refetch({ productId, phaseId: phase })
    }
  }, [productId, phase])

  useEffect(() => {
    refetch({ productId, phaseId: phase })
  }, [deleteRecordingData])

  useEffect(() => {
    if (conversationGroups?.length > 0) {
      refetchConversationRecordings()
    }
  }, [conversationGroups])

  useEffect(() => {
    if (recordings?.length > 0) {
      const newAllRecordings = [...allRecordings]
      newAllRecordings.push(recordings)
      setAllRecordings(newAllRecordings)
    }
  }, [recordings])

  useEffect(() => {
    if (conversationGroups?.length > 0) {
      setAllArgumentations([])

      for (let i = 0; i < conversationGroups.length; i++) {
        refetchArgumentations(conversationGroups[i].id)
      }
    }
  }, [conversationGroups])

  useEffect(() => {
    if (argumentations?.length > 0) {
      const newAllArgumentations = [...allArgumentations]
      newAllArgumentations.push(argumentations)
      setAllArgumentations(newAllArgumentations)
    }
  }, [argumentations])

  useEffect(() => {
    if (editRecordingStatus === 200) {
      setSelectedRecording(editRecordingData.data)

      refetchConversationRecordings()
    }
  }, [editRecordingStatus])

  const handleRemoveRecordingConfirm = (recording) => {
    if (recording.published) {
      setError(t('recording_section.delete_published_error'))
    }

    setRecordingToDelete(recording)
    setDeleteConfirmModalStatus(true)
  }

  const handleRemoveRecording = (recording) => {
    deleteRecording(recording.id)
    setVideoModalStatus(false)
    setRecordingModalStatus(false)
  }

  const publishRecording = (recording) => {
    const newRecording = { ...recording }
    newRecording.published = true
    editRecording(newRecording)
  }

  function handleGroupSelect(groupId) {
    store.setSelectedGroup(groupId)
    setLocalStorage('selectedGroup', groupId)
    history.push(
      `/learn/channelId=${store.selectedChannel.id}/productId=${store.selectedProductCategory.id}/phaseId=${store.phase}/conversationGroupId=${groupId}`,
    )
  }

  const openRecording = (recording, group) => {
    handleGroupSelect(group.id)
    setSelectedGroup(group)
    setSelectedRecording(recording)
    setError(null)

    setVideoModalStatus(true)
  }

  const { md: isDesktop } = useMedia()

  const getRecordingsLeft = (groupId) => {
    const groupRecordings = allRecordings.find(
      (recording) => recording[0].conversationGroupId === groupId,
    )

    const recordingsLeft = new Array(
      groupRecordings ? MAX_RECORDING_LIMIT - groupRecordings?.length : MAX_RECORDING_LIMIT,
    ).fill({})

    return recordingsLeft?.map(() => (
      <Grid key={v4()} item xs={4}>
        <div className={classNames(classes.placeholder)} />
      </Grid>
    ))
  }

  const renderVideos = (group) => {
    const groupRecordings = allRecordings.find(
      (recording) => recording[0].conversationGroupId === group.id,
    )

    return groupRecordings?.map((recording) => (
      <Grid item xs={4} key={recording.id}>
        {recording.conversationGroupId === group.id ? (
          <div
            className={classNames(classes.videoIcon)}
            onClick={() => openRecording(recording, group)}
          >
            <PlayArrowIcon className={classes.icon} />
          </div>
        ) : null}
      </Grid>
    ))
  }

  const htmltext = (group) => {
    const foundArgumentations = allArgumentations.find(
      (argum) => argum[0].conversationGroupId === group?.id,
    )
    const argumentationText = foundArgumentations
      ? (foundArgumentations[foundArgumentations.length - 1].text as string)
      : '...'

    // const htmlArgumentationText =
    //   argumentationText.length > 200 ? `${argumentationText.slice(0, 200)} ...` : argumentationText
    return argumentationText
  }

  const handleUpload = () => {
    refetch({ productId, phaseId: phase })
  }

  const openRecordingModal = (group) => {
    handleGroupSelect(group.id)
    setSelectedGroup(group)
    setError(null)

    setRecordingModalStatus(true)
  }

  useEffect(() => {
    if (recordingModalStatus) {
      const newFoundRecordings = allRecordings.find(
        (recording) => recording[0].conversationGroupId === selectedGroup?.id,
      )
      setFoundRecordings(newFoundRecordings)
    }
  }, [recordingModalStatus])

  return (
    <div>
      <Grid container>
        {conversationGroups?.map((group) => (
          <Grid item xs={12} className={classes.groupContainer} key={group.id}>
            <Text className={classes.groupName} variant="h3">
              {group.name}
            </Text>
            <Grid container>
              <Grid item xs={isDesktop ? 6 : 12}>
                <Text className={classes.myArgumentation} variant="body2">
                  {t('recording_section.my_recordings')}
                </Text>
                <Grid container spacing={1}>
                  {renderVideos(group)}
                  {getRecordingsLeft(group.id)}
                </Grid>
              </Grid>
              {/* {isDesktop && ( */}
              <Grid item xs={isDesktop ? 6 : 12}>
                <>
                  <Typography className={classes.myArgumentation} variant="body2">
                    {t('learning.my_arguments_and_screenplay')}
                  </Typography>
                  <div
                    className={classes.text}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: sanitize(htmltext(group)) }}
                  />
                </>
              </Grid>
              {/* )} */}
            </Grid>
            <CustomButton
              className={classes.argumentationButton}
              onClick={() => {
                openRecordingModal(group)
              }}
            >
              {t('recording_section.record_my_argumentation_button').toUpperCase()}
            </CustomButton>
          </Grid>
        ))}
      </Grid>
      <CustomModal
        open={videoModalStatus}
        maxWidth="lg"
        toggleModal={() => setVideoModalStatus(!videoModalStatus)}
        header={<CustomBreadcrumbs disableLinks />}
        body={
          <div className={classes.modalContainer}>
            <Grid container>
              <Grid item xs={12}>
                <>
                  <video className={classes.video} playsInline muted loop width="100%" autoPlay controls onMouseUp={() => {}}>
                    <source
                      src={`${store.cloudfrontUrl}/recordings/${selectedRecording?.recordingUrl}`}
                      type="video/mp4"
                    />
                    <track src="captions_en.vtt" kind="captions" label="english_captions" />
                    {t('recording_section.video_error')}
                  </video>
                  <div className={classes.btns}>
                    <div className={classes.removeBtnAndError}>
                      <CancelButton
                        variant="outlined"
                        onClick={() => {
                          handleRemoveRecordingConfirm(selectedRecording)
                        }}
                      >
                        {t('delete')}
                      </CancelButton>
                      <Text variant="body1" className={classes.error}>
                        {error}
                      </Text>
                    </div>
                    {selectedRecording?.published ? (
                      <Text variant="body1" className={classes.published}>
                        <CheckIcon className={classes.checkbox} /> {t('published')}
                      </Text>
                    ) : (
                      <CustomButton
                        startIcon={<SendIcon />}
                        onClick={() => publishRecording(selectedRecording)}
                        className={classNames(classes.publishBtn)}
                      >
                        {t('publish')}
                      </CustomButton>
                    )}
                  </div>
                </>
              </Grid>
            </Grid>
          </div>
        }
      />
      <CustomModal
        open={recordingModalStatus}
        maxWidth="lg"
        toggleModal={() => setRecordingModalStatus(!recordingModalStatus)}
        header={
          <div className={`${classes.headerContainer}`}>
            <CustomBreadcrumbs disableLinks />
            <Text variant="h3" className={classes.modalHeading}>
              {t('learning.my_arguments_and_screenplay')}
            </Text>
          </div>
        }
        body={
          <div className={`${classes.modalContainer}  ${classes.scrollableContainer}`}>
            <Grid container className={classes.limitHeight}>
              <Grid item xs={12} className={classes.limitHeight}>
                {foundRecordings?.length >= MAX_RECORDING_LIMIT ? (
                  <div>
                    <Text variant="body1">
                      {t('recording_section.maximum_slots_error', { count: MAX_RECORDING_LIMIT })}.
                    </Text>
                  </div>
                ) : (
                  <Grid container spacing={4} className={classes.limitHeight}>
                    <Grid item xs={12} md={6}>
                      <Text variant="h4" className={classes.myArgumentationHeadingModal}>
                        {selectedGroup?.name}
                      </Text>
                      <WebcamStreamCapture
                        className={classes.webcam}
                        groupId={selectedGroup?.id}
                        onUpload={() => handleUpload()}
                        editRecording={(recording) => publishRecording(recording)}
                        removeRecording={(recording) => handleRemoveRecordingConfirm(recording)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.argumentationWrapper}>
                      <Text className={classes.myArgumentationHeadingModal} variant="body2">
                        {t('learning.my_arguments_and_screenplay')}
                      </Text>
                      <div
                        className={`${classes.textModal} ${classes.textScrollableContainer}`}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: sanitize(htmltext(selectedGroup)) }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        }
      />
      <ConfirmModal
        open={deleteConfirmModalStatus}
        toggleModal={() => setDeleteConfirmModalStatus(!deleteConfirmModalStatus)}
        message={t('delete_confirm')}
        confirmHandler={() => handleRemoveRecording(recordingToDelete)}
      />
    </div>
  )
}

export default observer(RecordingMain)
