import React, { useContext, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import useAbstractProvider from 'providers/AbstractProvider'
import { useHistory, useParams } from 'react-router-dom'
import FullScreenLoader from 'components/organisms/FullScreenLoader/FullScreenLoader'
import { LearningMaterialsFullType } from 'stores'
import { StoreContext } from 'App'
import AdminLayout from 'layouts/AdminLayout'
import { Add, AddCircle, Save, ExpandMore, HighlightOff } from '@material-ui/icons'
import { showToast } from 'utils'
import PhasesApi from 'api/phases'
import { PhaseFullDataType } from 'types/Phase'
import { FALLBACK_LANGUAGE } from 'helpers/configuration'
import useAbstractMutator from 'providers/AbstractMutator'
import ConfirmationDialog from 'components/organisms/Dialog/ConfirmationDialog'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import PhaseOrderChangeDialog from 'components/organisms/PhaseOrderChangeModal'
import { useTranslation } from 'react-i18next'
import Breadcrumbs from 'components/organisms/CustomBreadcrumbs/CustomBreadcrumbs'

type ParamsType = {
  channelId: string
  productId: string
}

type ValuesByLanguageType = {
  translations: {
    [key: string]: {
      name: string
    }
  }
}

const defaultValueProperties = {
  name: '',
}

const NEW_PHASE_DEFAULT_ID = 123456789

const [DeleteConfirmationDialog, NewPhaseCancelConfirmationDialog] =
  Array(2).fill(ConfirmationDialog)

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
  },
  buttons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  accordionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 15,
  },
  accordionName: { width: '90%' },
  accordionTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  accordionDataDiv: {
    height: '100%',
  },
  accordionLoadingDiv: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    gap: 5,
  },
  languageChooser: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginRight: 20,
  },
  accordionDetails: {
    display: 'flex',
    position: 'relative',
  },
})

const Phases = () => {
  const store = useContext(StoreContext) as {
    language: string
    languages: string[]
    learningMaterialsFull: LearningMaterialsFullType
    // eslint-disable-next-line no-unused-vars
    getChannelNameById: (channelId: number) => string
    // eslint-disable-next-line no-unused-vars
    getProductNameById: (productId: number) => string
    // eslint-disable-next-line no-unused-vars
    setPhasesFullData: (value: PhaseFullDataType[]) => void
  }

  const { t } = useTranslation()

  const classes = useStyles()
  const history = useHistory()
  const { channelId: channelIdParam, productId: productIdParam } = useParams<ParamsType>()
  const channelId = Number(channelIdParam)
  const productId = Number(productIdParam)

  const [phases, setPhases] = useState<PhaseFullDataType[]>([])

  // eslint-disable-next-line
  const [selectedPhase, setSelectedPhase] = useState<ValuesByLanguageType>({
    translations: {
      [FALLBACK_LANGUAGE]: defaultValueProperties,
    },
  })

  // eslint-disable-next-line
  const [selectedPhaseOriginalValues, setSelectedPhaseOriginalValues] =
    useState<ValuesByLanguageType>({
      translations: {
        [FALLBACK_LANGUAGE]: defaultValueProperties,
      },
    })

  // eslint-disable-next-line
  const [expandedPhaseId, setExpandedPhaseId] = useState<number | undefined>()

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [isOrderChangeDialogOpen, setIsOrderChangeDialogOpen] = useState(false)
  const [phaseIdToBeDeleted, setPhaseIdToBeDeleted] = useState<number | undefined>()

  const [isNewPhaseCancelConfirmationModalOpen, setIsNewPhaseCancelConfirmationModalOpen] =
    useState(false)

  const [isNewPhaseBeingAdded, setIsNewPhaseBeingAdded] = useState(false)

  const phasesProvider = useAbstractProvider(PhasesApi.getPhasesFullData, null, false)

  const editPhaseMutator = useAbstractMutator(PhasesApi.editPhase)
  const addPhaseMutator = useAbstractMutator(PhasesApi.addPhase)
  const deletePhaseMutator = useAbstractMutator(PhasesApi.deletePhase)
  const editPhaseOrderMutator = useAbstractMutator(PhasesApi.editPhaseOrder)

  useEffect(() => {
    if (!store.learningMaterialsFull.phases.length) {
      console.log('Fetch Phases From DB')
      phasesProvider.refetch()
    } else {
      console.log('Get Phases from Cache')
      setPhases(store.learningMaterialsFull.phases.filter((item) => item.productId === productId))
    }
  }, [])



  useEffect(() => {
    if (phasesProvider.data) {
      store.setPhasesFullData(phasesProvider.data)
      setPhases(phasesProvider.data.filter((item) => item.productId === productId))
    }
  }, [phasesProvider.data])

  useEffect(() => {
    if (expandedPhaseId) {
      const correspondingPhase = phases.find((item) => item.id === expandedPhaseId)
      const phase = {
        translations: correspondingPhase.translations,
      }
      setSelectedPhase(phase)
      setSelectedPhaseOriginalValues(phase)
    }
  }, [expandedPhaseId])

  useEffect(() => {
    if (
      editPhaseMutator.data ||
      addPhaseMutator.data ||
      deletePhaseMutator.data ||
      editPhaseOrderMutator
    ) {
      setIsNewPhaseBeingAdded(false)
      phasesProvider.refetch(channelId)
    }
  }, [
    editPhaseMutator.data,
    addPhaseMutator.data,
    deletePhaseMutator.data,
    editPhaseOrderMutator.data,
  ])

  const handlePhaseExpand = (selectedProductId: number) => {
    console.log('Handle Expand')
    if (isNewPhaseBeingAdded) {
      setIsNewPhaseCancelConfirmationModalOpen(true)
    } else {
      setExpandedPhaseId((existingId) =>
        existingId === selectedProductId ? undefined : selectedProductId,
      )
    }
  }

  const handleDelete = () => {
    deletePhaseMutator.mutate(phaseIdToBeDeleted)
  }

  const handlePhaseAddingCancel = () => {
    setPhases((arr) => arr.slice(0, -1))
    setIsNewPhaseBeingAdded(false)
    setExpandedPhaseId(undefined)
  }

  const handleEdit = () => {
    if (Object.keys(selectedPhase).some((item) => selectedPhase[item].name === ''))
      throw showToast('Fill Everything')
    if (JSON.stringify(selectedPhase) === JSON.stringify(selectedPhaseOriginalValues))
      throw showToast('Nothing has changed')
    const data = { translations: selectedPhase.translations }
    editPhaseMutator.mutate(expandedPhaseId, data)
    setSelectedPhaseOriginalValues(selectedPhase)
  }

  const handleAdd = () => {
    if (Object.keys(selectedPhase).some((item) => selectedPhase[item].name === ''))
      throw showToast('Fill Everything')
    const data = {
      productId,
      translations: selectedPhase.translations,
    }
    addPhaseMutator.mutate(data)
  }

  const handlePhaseAdd = () => {
    if (isNewPhaseBeingAdded) {
      return handleAdd()
    }
    setPhases((arr) => [
      ...arr,
      {
        id: NEW_PHASE_DEFAULT_ID,
        position: 10000,
        productId,
        createdAt: '',
        translations: {
          [FALLBACK_LANGUAGE]: {
            name: 'Add New Phase',
          },
        },
      },
    ])
    setIsNewPhaseBeingAdded(true)
    setExpandedPhaseId(NEW_PHASE_DEFAULT_ID)
    return null
  }

  const handleNameChange = (newName: string, lang: string) => {
    setSelectedPhase((obj) => ({
      translations: {
        ...obj.translations,
        [lang]: { ...obj[lang], name: newName },
      },
    }))
  }


  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <div className={classes.container}>
            {!phases ? (
              <FullScreenLoader />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={8}>
                    <Breadcrumbs/>
                    <Text variant="h3">{t('admin_learning_panel.phases')}</Text>

                  </Grid>
                  <Grid item xs={4} className={classes.buttons}>
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => setIsOrderChangeDialogOpen(true)}
                    >
                      Order Change
                    </Button>
                    <IconButton onClick={handlePhaseAdd} color="primary" component="span">
                      <AddCircle />
                    </IconButton>
                  </Grid>
                  <hr />
                  <hr />
                  <Grid item xs={12}>
                    {phases
                      .sort((a, b) => a.position - b.position)
                      .map((phase) => (
                        <Accordion
                          expanded={expandedPhaseId === phase.id}
                          disabled={editPhaseMutator.loading || addPhaseMutator.loading}
                          key={phase.id}
                        >
                          <AccordionSummary
                            IconButtonProps={{
                              onClick: () => handlePhaseExpand(phase.id),
                            }}
                            expandIcon={<ExpandMore />}
                          >
                            <div className={classes.accordionHeader}>
                              <div
                                onClick={() =>
                                  history.push(
                                    `/admin-view/learning-material-managment/channelId=${channelId}/productId=${productId}/phaseId=${phase.id}`,
                                  )
                                }
                                className={classes.accordionName}
                              >
                                <Text variant="h4">
                                  {phase.translations[store.language]?.name ||
                                    phase.translations[FALLBACK_LANGUAGE].name}
                                </Text>
                              </div>
                              <div className={classes.accordionTooltip}>
                                {phase.id === NEW_PHASE_DEFAULT_ID ? (
                                  <Tooltip placement="top" title="Add">
                                    <IconButton size="small" onClick={handleAdd}>
                                      <Add fontSize="medium" color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                ) : expandedPhaseId === phase.id ? (
                                  <Tooltip placement="top" title="Edit">
                                    <IconButton onClick={handleEdit} size="small">
                                      <Save fontSize="medium" color="primary" />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                                <Tooltip placement="top" title="Delete">
                                  <IconButton
                                    onClick={() => {
                                      if (isNewPhaseBeingAdded) {
                                        setIsNewPhaseCancelConfirmationModalOpen(true)
                                      } else {
                                        setPhaseIdToBeDeleted(phase.id)
                                        setIsDeleteConfirmationModalOpen(true)
                                      }
                                    }}
                                    size="small"
                                  >
                                    <HighlightOff fontSize="medium" color="secondary" />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails className={classes.accordionDetails}>
                            {phase.id === expandedPhaseId && (
                              <>
                                {expandedPhaseId && (
                                  <Grid container>
                                    <Grid xs={12} item>
                                      <MaterialInput
                                        disabled={
                                          editPhaseMutator.loading || addPhaseMutator.loading
                                        }
                                        value={selectedPhase.translations.de.name}
                                        size="small"
                                        onChange={(value) => handleNameChange(value, 'de')}
                                        type="text"
                                        label="Name"
                                        name="name"
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                                <div className={classes.accordionLoadingDiv}>
                                  {editPhaseMutator.loading || addPhaseMutator.loading ? (
                                    <CircularProgress size="1.5rem" />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Grid>
                </Grid>
              </>
            )}
          </div>
          <DeleteConfirmationDialog
            open={isDeleteConfirmationModalOpen}
            handleClose={() => setIsDeleteConfirmationModalOpen(false)}
            title={`Delete Phase ${
              phases?.find((item) => item.id === phaseIdToBeDeleted)?.translations[store.language]
                ?.name ||
              phases?.find((item) => item.id === phaseIdToBeDeleted)?.translations[
                FALLBACK_LANGUAGE
              ]?.name
            } ?`}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleDelete}
          />
          <NewPhaseCancelConfirmationDialog
            open={isNewPhaseCancelConfirmationModalOpen}
            handleClose={() => setIsNewPhaseCancelConfirmationModalOpen(false)}
            title="Cancel adding of new phase ?"
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handlePhaseAddingCancel}
          />
          <PhaseOrderChangeDialog
            phases={phases
              .sort((a, b) => a.position - b.position)
              .map((item) => ({
                id: item.id,
                title:
                  item.translations[store.language]?.name ||
                  item.translations[FALLBACK_LANGUAGE].name,
              }))}
            open={isOrderChangeDialogOpen}
            handleClose={(data: { id: number; title: string }[]) => {
              setIsOrderChangeDialogOpen(false)
              if (data) {
                editPhaseOrderMutator.mutate(
                  data.map((item, index) => ({ id: item.id, position: index + 1 })),
                )
              }
            }}
          />
        </>,
      )}
    </ProtectedPage>
  )
}

export default Phases
