import { AxiosResponse } from 'axios'
import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

export type ApiResponse<ResponseType> = { res: void | AxiosResponse<ResponseType>, err: any }

export interface ResultsPage<RecordType> {
  data: RecordType[];
  count: number;
}

export interface StaticContentListEntryDto {
  id: number;
  code: string;
  name: string;
  lastModification: Date;
}

export interface StaticContentEditTranslationDto {
  name: string;
  content: string;
}

export interface StaticContentEditDto {
  html: boolean;
  translations: { [lang: string]: StaticContentEditTranslationDto }
}

export interface StaticContentDto {
  id: number;
  code: string;
  html: boolean;
  name: string;
  content: string;
}

async function getAll(
  params: { search: string, offset: number, limit: number }
): Promise<ApiResponse<ResultsPage<StaticContentListEntryDto>>> {
  return API.get(`${API_URL}/static-contents/`, true, params)
}

async function getEdit(id: number): Promise<ApiResponse<StaticContentEditDto>> {
  return API.get(`${API_URL}/static-contents/${id}/edit`, true)
}

async function update(params: { id: number, data: StaticContentEditDto }): Promise<ApiResponse<StaticContentEditDto>> {
  return API.put(`${API_URL}/static-contents/${params.id}/edit`, params.data, true)
}

async function uploadImage(file: File): Promise<ApiResponse<string>> {
  const formData = new FormData()
  formData.append('image', file)

  return API.post(`${API_URL}/static-contents/images/`, formData, true)
}

async function getByCode(code: string): Promise<ApiResponse<StaticContentDto>> {
  return API.get(`${API_URL}/static-contents/${code}`)
}

async function getByCodes(codes: string[]): Promise<ApiResponse<StaticContentDto>> {
  return API.get(`${API_URL}/static-contents/by-code`, true, { codes })
}

export default {
  getAll,
  getEdit,
  update,
  getByCode,
  getByCodes,
  uploadImage,
}
