import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useState, useEffect } from 'react'

import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Text from 'components/atoms/Text/Text'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import useMedia from 'hooks/useMedia'
import { useTranslation } from 'react-i18next'
import MaterialInput from '../../atoms/MaterialInput/MaterialInput'

export interface VideoGenerationModalProps {
  modalStatus: boolean
  setModalStatus: Function,
  avatar: any,
  conversationId: number
  conversationName: string
  saveScriptHandler: (script: string) => void
  video: any
}

const useStyles = makeStyles(() => ({
  modalContainer: {
    padding: 30,
  },
  stepText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  stepContainer: {
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  step: {
    backgroundColor: '#fff',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  stepNumber: {
    color: '#fff',
    fontSize: '40px',
  },
  title: {
    marginBottom: 16,
  },
  editor: {
    marginBottom: 20,
  },
  listItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list: {
    paddingLeft: 50,
    maxHeight: 400,
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row-reverse'
  },
  scroll: {
    overflow: 'scroll',
  },
  checkbox: {
    marginRight: 5,
  },
}))

const VideoGenerationModal: React.SFC<VideoGenerationModalProps> = ({
  modalStatus,
  setModalStatus,
  avatar,
  conversationId,
  conversationName,
  saveScriptHandler,
  video,
}) => {
  const [editorValue, setEditorValue] = useState('')

  const { t } = useTranslation()

  const classes = useStyles()
  function toggleModal(value) {
    setModalStatus(value)
  }

  useEffect(() => {
    setEditorValue(video.script)
  }, [video.script])

  function sendToApi() {
    saveScriptHandler(editorValue)
    setModalStatus(!modalStatus)
  }

  return (
    <CustomModal
      open={modalStatus}
      toggleModal={toggleModal}
      header={
        <div className={classes.stepContainer}>
          <Grid container className={classes.stepContainer}>
            <Grid className={classes.scroll} item sm={4}>
              <img className={classes.step} src='/did-api-logo.png' alt="add" />
            </Grid>
            <Grid item sm={8}>
              <Text variant="h4" className={classes.title}>
                Video {avatar?.name} ({t(avatar?.gender)}, {t(avatar?.language)})
              </Text>
            </Grid>
          </Grid>
        </div>
      }
      body={
        <div className={classes.modalContainer}>
          <Grid container>
            <Grid className={classes.scroll} item sm={4}>
              <p>{t('name')}: {avatar.name}</p>
              <p>ID: {avatar.id}</p>
              <p>{t('gender')}: {avatar.gender}</p>
              <p>{t('language')}: {avatar.language}</p>
              <p>{t('admin_avatars.voice')}: {avatar.apiVoice.name}</p>
              {avatar.apiVoiceStyle && <p>{t('admin_avatars.voice_style')}: {avatar.apiVoiceStyle}</p>}
              <br/>
              <p>{t('admin_learning_panel.conversation')}: {conversationName}</p>
              <p>{t('admin_learning_panel.conversation_id')}: {conversationId}</p>
            </Grid>
            <Grid item sm={8}>
              <MaterialInput
                className={classes.editor}
                multiline
                name="aa"
                value={editorValue}
                onChange={setEditorValue}
                rows={10}
              />
              <div className={classes.btns}>
                  <CustomButton color="primary" onClick={() => sendToApi()}>{t('admin_learning_panel.send_to_api')}</CustomButton>
              </div>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default VideoGenerationModal
