import { makeStyles } from '@material-ui/styles'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from 'App'
import { useHistory } from 'react-router-dom'
import { setLocalStorage } from 'utils'
import useAbstractProvider from 'providers/AbstractProvider'
import ProductsApi from 'api/products'
import { useTranslation } from 'react-i18next'
import Text from 'components/atoms/Text/Text'
import { CustomModal } from '../MaterialModal/MaterialModal'
import AvatarSelector from '../AvatarSelector/AvatarSelector'
import ChannelSelector from '../ChannelSelector/ChannelSelector'
import { ScrollableModalBody } from '../MaterialModal/ScrollableModalBody'

const useStyles = makeStyles({
  paper: {
    height: 150,
  },
  centerBoth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    marginBottom: 15,
    fontWeight: 600,
    fontSize: '1.75rem',
  },
  avatarsContainer: {
    marginBottom: 30,
  },
  pointer: {
    cursor: 'pointer',
  },
  highlightOnHover: {
    border: '2px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      border: '2px solid #0000',
      boxShadow:
        '0px 0px 5px -2px #5c6bc0, 0px 2px 5px 0px #5c6bc0, 0px 1px 5px 0px #5c6bc0 !important',
    },
  },
  selectedItem: {
    border: '2px solid #2c5de5 !important',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
})

export interface SettingsModalProps {}

const SettingsModal: React.SFC<SettingsModalProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const store = useContext(StoreContext) as any
  const [selectedAvatar, setSelectedAvatar] = useState({
    id: null,
  })
  const [selectedChannel, setSelectedChannel] = useState({
    id: null,
  })
  const { data: productsByChannelId, refetch: getProductsByChannelId } = useAbstractProvider(
    ProductsApi.getProductsByChannelId,
    {},
    false,
  )
  const [settingsChanged, setSettingsChanged] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (productsByChannelId) {
      store.setProducts(productsByChannelId)
    }
  }, [productsByChannelId])

  useEffect(() => {
    setSelectedAvatar(store.selectedAvatar)
  }, [store.selectedAvatar])

  useEffect(() => {
    setSelectedChannel(store.selectedChannel)
  }, [store.selectedChannel])

  useEffect(() => {
    store.setOpenSettingsModal(true)
  }, [])

  function saveSettings() {
    store.setSelectedAvatar(selectedAvatar.id)

    setLocalStorage('selectedAvatar', selectedAvatar.id)
    store.setOpenSettingsModal(false)
    let url
    if (selectedChannel?.id) {
      store.setSelectedChannel(selectedChannel.id)
      setLocalStorage('selectedChannel', selectedChannel?.id)

      url = store?.selectedProductCategory?.id
        ? `/learn/channelId=${selectedChannel.id}/productId=${store?.selectedProductCategory?.id}`
        : `/learn/channelId=${selectedChannel.id}`
    }

    if (store.currentUser.role.name === 'admin') {
      url = '/'
    }
    history.push(url)
    window.location.reload()

    setSettingsChanged(false)
  }

  function toggleModal() {
    store.setOpenSettingsModal(!store.openSettingsModal)

    if (!store.openSettingsModal && settingsChanged) {
      saveSettings()
    }
  }

  return (
    <CustomModal
      open={store.openSettingsModal}
      // closeOnOutsideClick={false}
      toggleModal={toggleModal}
      maxWidth="lg"
      paperWidth={null}
      header={
        <Text variant="h2" className={classes.heading}>
          {t('settings.avatar_select')}
        </Text>
      }
      body={
        <ScrollableModalBody
          body={
            <div>
              <div className={classes.avatarsContainer}>
                <AvatarSelector
                  onChange={(avatar) => {
                    setSelectedAvatar(avatar)
                    setSettingsChanged(true)
                  }}
                  smallPictures
                />
              </div>
              {store.currentUser.role.name === 'admin' ? null : (
                <>
                  <Text variant="h2" className={classes.heading}>
                    {t('settings.product_select')}
                  </Text>
                  <ChannelSelector
                    onChange={(channel) => {
                      setSelectedChannel(channel)
                      store.setSelectedChannel(channel.id)
                      getProductsByChannelId(channel.id)
                      setSettingsChanged(true)
                    }}
                    smallPictures
                    showSpecificLanguageChannels
                  />
                </>
              )}
            </div>
          }
        />
      }
    />
  )
}

export default observer(SettingsModal)
