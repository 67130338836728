import { Breadcrumbs, makeStyles } from '@material-ui/core'
import UserApi from 'api/user'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import MainLayout from 'layouts/MainLayout'
import useAbstractProvider from 'providers/AbstractProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Text from 'components/atoms/Text/Text'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Loader from 'components/atoms/Loader/Loader'
import { StoreContext } from 'App'
import { useTranslation } from 'react-i18next'
import ChannelsApi from 'api/channels'
import traineeHelpers from 'helpers/trainee'
import TraineeDetailsTable from './TraineeDetailsTable'

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  heading: {
    marginBottom: 15,
  },
  traineeChannelName: {
    margin: '0.5rem 0 1rem 0',
  },
  name: {
    color: '#333333',
  },
  breadcrumbs: {
    marginBottom: 25,
  },
  table: {
    paddingBottom: 70,
  },
})

const MentorsUserDetailPage = () => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const [openProductId, setOpenProductId] = React.useState(0)
  const [loading, setLoading] = useState(true)
  const { userId, channelId, argumentationId, recordingId } = useParams<any>()
  const [paramArgumentation, setParamArgumentation] = useState(null)
  const [paramRecording, setParamRecording] = useState(null)
  const [modalType, setModalType] = useState(null)

  const { data: getTeamUserDetailData, refetch } = useAbstractProvider(
    UserApi.getTeamUserDetailData,
    { userId, channelId },
  )
  const channelProvider = useAbstractProvider(ChannelsApi.getChannelById, {
    channelId,
    query: 'products,conversationGroups',
  })

  const [data, setData] = useState([])

  useEffect(() => {
    if (channelProvider?.data && getTeamUserDetailData) {
      setTimeout(() => {
        setLoading(false)
      }, 800)
      setData(
        traineeHelpers.processTraineeDataInChannel({
          channelData: channelProvider?.data,
          traineeData: getTeamUserDetailData,
        }),
      )
    }
  }, [channelProvider?.data, getTeamUserDetailData])

  useEffect(() => {
    if (getTeamUserDetailData && channelId && argumentationId) {
      const argumentation = getTeamUserDetailData?.argumentations.find(
        (item) => parseInt(item.id, 10) === parseInt(argumentationId, 10),
      )

      setParamArgumentation(argumentation)
      setModalType('argumentation')
    }
  }, [channelId, argumentationId, getTeamUserDetailData])

  useEffect(() => {
    if (getTeamUserDetailData && channelId && recordingId) {
      const recording = getTeamUserDetailData?.recordings.find(
        (item) => parseInt(item.id, 10) === parseInt(recordingId, 10),
      )

      setParamRecording(recording)
      setModalType('recording')
    }
  }, [channelId, recordingId, getTeamUserDetailData])

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <div className={classes.container}>
          <Text variant="h2" className={classes.heading}>
            {t('mentor_user_detail.heading')}
          </Text>
          <Breadcrumbs
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link to="/mentors-view">{t('mentor_user_detail.mentors_team')}</Link>
            <Text variant="body1">
              {t('mentor_user_detail.users_progress', {
                user: store?.currentUser?.person?.firstName ?? 'Trainee',
              })}
            </Text>
          </Breadcrumbs>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div
                className={classes.traineeChannelName}
                style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
              >
                <Text className={classes.name} variant="h4">
                  {getTeamUserDetailData
                    ? `${t('user')}: ${getTeamUserDetailData?.user.firstName} ${
                        getTeamUserDetailData?.user.lastName
                      }`
                    : null}
                </Text>
                <Text className={classes.name} variant="h4">
                  {t('channel')} : {channelProvider?.data?.name}
                </Text>
              </div>
              <div className={classes.table}>
                {getTeamUserDetailData && (
                  <TraineeDetailsTable
                    data={data}
                    refetch={() => {
                      setLoading(true)

                      refetch({ userId, channelId })
                    }}
                    openProductId={openProductId}
                    modalTypeOpen={modalType}
                    paramArgumentation={paramArgumentation}
                    paramRecording={paramRecording}
                    setOpenProductId={setOpenProductId}
                    mentorView
                    channelId={channelId}
                  />
                )}
              </div>
            </>
          )}
        </div>,
      )}
    </ProtectedPage>
  )
}

export default MentorsUserDetailPage
