import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',

    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'de',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    backend: {
      requestOptions: {
        cache: 'no-store',
      },
    },
  })

export default i18n
