import React, { useContext, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  makeStyles,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import useAbstractProvider from 'providers/AbstractProvider'
import useAbstractMutator from 'providers/AbstractMutator'
import { StoreContext } from 'App'
import ChannelsApi from 'api/channels'
import ConfirmationDialog from 'components/organisms/Dialog/ConfirmationDialog'
import { ExpandMore, HighlightOff, Save, Add, AddCircle } from '@material-ui/icons'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { useHistory, useParams, Link } from 'react-router-dom'
import FullScreenLoader from 'components/organisms/FullScreenLoader/FullScreenLoader'
import ProductsApi from 'api/products'
import ConversationGroupsApi from 'api/conversationGroups'
import {
  ConversationGroupFullDataType,
  ConversationGroupTranslationsType,
} from 'types/ConversationGroup'
import { LearningMaterialsFullType } from 'stores'
import AdminLayout from 'layouts/AdminLayout'
import { showToast } from 'utils'
import { FALLBACK_LANGUAGE } from 'helpers/configuration'
import PhasesApi from 'api/phases'
import { useTranslation } from 'react-i18next'
import { sanitize } from 'dompurify'
import HtmlEditor from 'components/organisms/HtmlEditor/HtmlEditor'
import Breadcrumbs from 'components/organisms/CustomBreadcrumbs/CustomBreadcrumbs'

type ParamsType = {
  channelId: string
  productId: string
  phaseId: string
}

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
  },
  buttons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  accordionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 15,
  },
  accordionTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  accordionName: { width: '90%' },
  accordionDetails: {
    display: 'flex',
    position: 'relative',
  },
  accordionPhotoDiv: {
    marginRight: '5%',
  },
  photoUploadImage: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '10px 0 0 0',
    boxShadow: '0px -1px 10px 1px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
  accordionDataDiv: {
    height: '100%',
    width: '100%',
  },
  conversationGroupEditButtonDiv: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    gap: 5,
  },
  conversationGroupEditButton: {
    border: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
  },
  languageChooser: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginRight: 20,
  },
  translationAccordion: {
    width: '100%',
  },
  translationAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  editor: {
    marginBottom: 20,
  },
})

const defaultValueProperties = {
  name: 'Add Conversation Group DE',
  argumentationTemplate: '',
}

const NEW_CONVERSATION_GROUP_DEFAULT_ID = 1234567890

const [DeleteConfirmationDialog, NewConversationGroupCancelConfirmationDialog] =
  Array(2).fill(ConfirmationDialog)

const ConversationGroups = () => {
  const store = useContext(StoreContext) as {
    language: string
    languages: string[]
    learningMaterialsFull: LearningMaterialsFullType
    // eslint-disable-next-line no-unused-vars
    setConversationGroupsFullData: (values: ConversationGroupFullDataType[]) => void
    // eslint-disable-next-line no-unused-vars
    getChannelNameById: (channelId: number) => string
    // eslint-disable-next-line no-unused-vars
    getProductNameById: (productId: number) => string
    // eslint-disable-next-line no-unused-vars
    getPhaseNameById: (phaseId: number) => string
  }

  const { t } = useTranslation()

  const classes = useStyles()
  const history = useHistory()
  const {
    channelId: channelIdParam,
    productId: productIdParam,
    phaseId: phaseIdParam,
  } = useParams<ParamsType>()
  const channelId = Number(channelIdParam)
  const productId = Number(productIdParam)
  const phaseId = Number(phaseIdParam)

  const [conversationGroups, setConversationGroups] = useState<
    ConversationGroupFullDataType[] | undefined
  >()

  const [selectedConversationGroup, setSelectedConversationGroup] =
    useState<ConversationGroupTranslationsType>({
      [FALLBACK_LANGUAGE]: defaultValueProperties,
    })
  const [selectedConversationGroupOriginalValues, setSelectedConversationGroupOriginalValues] =
    useState<ConversationGroupTranslationsType>({
      [FALLBACK_LANGUAGE]: defaultValueProperties,
    })

  const [expandedConversationGroupId, setExpandedConversationGroupId] = useState<
    number | undefined
  >()

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [conversationGroupIdToBeDeleted, setConversationGroupIdToBeDeleted] = useState<
    number | undefined
  >()

  const [
    isNewConversationGroupCancelConfirmationModalOpen,
    setIsNewConversationGroupCancelConfirmationModalOpen,
  ] = useState(false)

  const [isNewConversationGroupBeingAdded, setIsNewConversationGroupBeingAdded] = useState(false)

  const productProvider = useAbstractProvider(ProductsApi.getProductByCode, null, false)
  const channelProvider = useAbstractProvider(ChannelsApi.getChannelById, null, false)
  const phaseProvider = useAbstractProvider(PhasesApi.getPhaseById, null, false)
  const conversationGroupsProvider = useAbstractProvider(
    ConversationGroupsApi.getConversationGroupsFullData,
    null,
    false,
  )
  const editConversationGroupMutator = useAbstractMutator(
    ConversationGroupsApi.editConversationGroup,
  )
  const addConversationGroupMutator = useAbstractMutator(ConversationGroupsApi.addConversationGroup)
  const deleteConversationGroupMutator = useAbstractMutator(
    ConversationGroupsApi.deleteConversationGroup,
  )

  useEffect(() => {
    if (!store.learningMaterialsFull.conversationGroups.length) {
      console.log('Fetch Conversation Groups From DB')
      conversationGroupsProvider.refetch()
    } else {
      console.log('Get Conversation Groups from Cache')
      setConversationGroups(
        store.learningMaterialsFull.conversationGroups.filter(
          (item) => item.productId === productId && item.phaseId === phaseId,
        ),
      )
    }
  }, [])

  useEffect(() => {
    if (channelProvider.status === 400) {
      showToast(t('admin_learning_panel.invalid_channel_id'))
      history.push('/')
    } else if (productProvider.status === 400) {
      showToast(t('admin_learning_panel.invalid_product_id'))
      history.push('/')
    } else if (phaseProvider.status === 400) {
      showToast('Invalid Phase Id')
      history.push('/')
    }
  }, [productProvider.status, channelProvider.status, phaseProvider.status])

  useEffect(() => {
    if (conversationGroupsProvider.data) {
      store.setConversationGroupsFullData(conversationGroupsProvider.data)
      setConversationGroups(
        conversationGroupsProvider.data.filter(
          (item) => item.productId === productId && item.phaseId === phaseId,
        ),
      )
    }
  }, [conversationGroupsProvider.data])

  useEffect(() => {
    if (expandedConversationGroupId) {
      const correspondingConversationGroup = conversationGroups.find(
        (item) => item.id === expandedConversationGroupId,
      )
      setSelectedConversationGroup(correspondingConversationGroup.translations)
      setSelectedConversationGroupOriginalValues(correspondingConversationGroup.translations)
    }
  }, [expandedConversationGroupId])

  useEffect(() => {
    if (
      editConversationGroupMutator.data ||
      addConversationGroupMutator.data ||
      deleteConversationGroupMutator.data
    ) {
      setIsNewConversationGroupBeingAdded(false)
      conversationGroupsProvider.refetch({
        productId,
        phaseId,
      })
    }
  }, [
    editConversationGroupMutator.data,
    addConversationGroupMutator.data,
    deleteConversationGroupMutator.data,
  ])

  const handleConversationGroupExpand = (conversationGroupId: number) => {
    if (isNewConversationGroupBeingAdded) {
      setIsNewConversationGroupCancelConfirmationModalOpen(true)
    } else {
      setExpandedConversationGroupId((existingId) =>
        existingId === conversationGroupId ? undefined : conversationGroupId,
      )
    }
  }

  const handleDelete = () => {
    deleteConversationGroupMutator.mutate(conversationGroupIdToBeDeleted)
  }

  const handleConversationGroupAddingCancel = () => {
    setConversationGroups((arr) => arr.slice(0, -1))
    setIsNewConversationGroupBeingAdded(false)
    setExpandedConversationGroupId(undefined)
  }

  const handleEdit = () => {
    if (
      Object.keys(selectedConversationGroup).some(
        (item) => selectedConversationGroup[item].name === '',
      )
    )
      return showToast(t('fill_everything'))
    if (
      JSON.stringify(selectedConversationGroupOriginalValues) ===
      JSON.stringify(selectedConversationGroup)
    )
      return showToast(t('admin_learning_panel.nothing_has_changed'))
    const data = {
      translations: selectedConversationGroup,
    }
    editConversationGroupMutator.mutate(expandedConversationGroupId, data)
    setSelectedConversationGroupOriginalValues(selectedConversationGroup)
    return null
  }

  const handleAdd = () => {
    if (
      Object.keys(selectedConversationGroup).some(
        (item) => selectedConversationGroup[item].name === '',
      )
    )
      return showToast(t('fill_everything'))
    const data = {
      channelId,
      phaseId,
      productId,
      translations: selectedConversationGroup,
    }
    addConversationGroupMutator.mutate(data)
    return null
  }

  const handleConversationGroupAdd = () => {
    if (isNewConversationGroupBeingAdded) {
      return handleAdd()
    }
    setConversationGroups((arr) => [
      ...arr,
      {
        id: NEW_CONVERSATION_GROUP_DEFAULT_ID,
        createdAt: '',
        productId,
        phaseId,
        translations: {
          [FALLBACK_LANGUAGE]: defaultValueProperties,
        },
      },
    ])
    setIsNewConversationGroupBeingAdded(true)
    setExpandedConversationGroupId(NEW_CONVERSATION_GROUP_DEFAULT_ID)
    return null
  }

  const handleNameChange = (newName: string, lang: string) => {
    setSelectedConversationGroup((obj) => ({
      ...obj,
      [lang]: { ...obj[lang], name: newName },
    }))
  }

  const handleArgumentationTemplateChange = (value: string, lang: string) => {
    setSelectedConversationGroup((obj) => ({
      ...obj,
      [lang]: { ...obj[lang], argumentationTemplate: value },
    }))
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <div className={classes.container}>
            {!conversationGroups ? (
              <FullScreenLoader />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={10}>
                    <Breadcrumbs />
                    <Text variant="h3">{t('admin_learning_panel.conversation_groups')}</Text>
                  </Grid>
                  <Grid item xs={2} className={classes.buttons}>
                    <IconButton
                      onClick={handleConversationGroupAdd}
                      color="primary"
                      component="span"
                    >
                      <AddCircle />
                    </IconButton>
                  </Grid>
                  <hr />
                  <hr />
                  <Grid item xs={12}>
                    {conversationGroups.map((conversationGroup, index) => (
                      <Accordion
                        className={classes.translationAccordion}
                        key={conversationGroup.id}
                        expanded={expandedConversationGroupId === conversationGroup.id}
                        disabled={
                          editConversationGroupMutator.loading ||
                          addConversationGroupMutator.loading
                        }
                      >
                        <AccordionSummary
                          IconButtonProps={{
                            onClick: () => handleConversationGroupExpand(conversationGroup.id),
                          }}
                          expandIcon={<ExpandMore />}
                        >
                          <div
                            onClick={() => {
                              if (
                                !isNewConversationGroupBeingAdded ||
                                index !== conversationGroups.length - 1
                              ) {
                                history.push(
                                  `/admin-view/learning-material-managment/channelId=${channelId}/productId=${productId}/phaseId=${phaseId}/conversationGroupId=${conversationGroup.id}`,
                                )
                              }
                              return null
                            }}
                            className={classes.accordionHeader}
                          >
                            <div className={classes.accordionName}>
                              <Text variant="h4">
                                {conversationGroup.translations[store.language]?.name ||
                                  conversationGroup.translations[FALLBACK_LANGUAGE].name}
                              </Text>
                            </div>
                          </div>
                          <div className={classes.accordionTooltip}>
                            {conversationGroup.id === NEW_CONVERSATION_GROUP_DEFAULT_ID ? (
                              <Tooltip placement="top" title={t('add')}>
                                <IconButton size="small">
                                  <Add fontSize="medium" onClick={handleAdd} color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : expandedConversationGroupId === conversationGroup.id ? (
                              <Tooltip placement="top" title={t('edit')}>
                                <IconButton onClick={handleEdit} size="small">
                                  <Save fontSize="medium" color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <Tooltip placement="top" title={t('delete')}>
                              <IconButton
                                onClick={() => {
                                  if (isNewConversationGroupBeingAdded) {
                                    setIsNewConversationGroupCancelConfirmationModalOpen(true)
                                  } else {
                                    setConversationGroupIdToBeDeleted(conversationGroup.id)
                                    setIsDeleteConfirmationModalOpen(true)
                                  }
                                }}
                                size="small"
                              >
                                <HighlightOff fontSize="medium" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {conversationGroup.id === expandedConversationGroupId && (
                            <>
                              {expandedConversationGroupId && (
                                <Grid container spacing={1}>
                                  <Grid xs={12} item>
                                    <MaterialInput
                                      disabled={
                                        editConversationGroupMutator.loading ||
                                        addConversationGroupMutator.loading
                                      }
                                      value={selectedConversationGroup.de.name}
                                      size="small"
                                      onChange={(value) => handleNameChange(value, 'de')}
                                      type="text"
                                      label={t('name')}
                                      name="name"
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <HtmlEditor
                                      admin
                                      className={classes.editor}
                                      value={selectedConversationGroup.de.argumentationTemplate}
                                      onChange={(value: string) => {
                                        if (value !== '<p><br></p>') {
                                          handleArgumentationTemplateChange(value, 'de')
                                        }
                                      }}
                                      readOnly={
                                        editConversationGroupMutator.loading ||
                                        addConversationGroupMutator.loading
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              <div className={classes.conversationGroupEditButtonDiv}>
                                {editConversationGroupMutator.loading ||
                                addConversationGroupMutator.loading ? (
                                  <CircularProgress size="1.5rem" />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </div>
          <DeleteConfirmationDialog
            open={isDeleteConfirmationModalOpen}
            handleClose={() => setIsDeleteConfirmationModalOpen(false)}
            title={`${t('admin_learning_panel.delete_conversation_group')} ${
              conversationGroups?.find((item) => item.id === conversationGroupIdToBeDeleted)
                ?.translations[store.language]?.name ||
              conversationGroups?.find((item) => item.id === conversationGroupIdToBeDeleted)
                ?.translations[FALLBACK_LANGUAGE].name
            } ?`}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleDelete}
          />
          <NewConversationGroupCancelConfirmationDialog
            open={isNewConversationGroupCancelConfirmationModalOpen}
            handleClose={() => setIsNewConversationGroupCancelConfirmationModalOpen(false)}
            title={t('admin_learning_panel.cancel_conversation_group_add_question')}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleConversationGroupAddingCancel}
          />
        </>,
      )}
    </ProtectedPage>
  )
}

export default ConversationGroups
