import React, { FC, useEffect, useState } from 'react'
import TeamsApi from 'api/teams'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import useAbstractProvider from 'providers/AbstractProvider'
import { useParams } from 'react-router-dom'
import Loader from 'components/atoms/Loader/Loader'
import UserTeamsApi from 'api/userTeams'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import useAbstractMutator from 'providers/AbstractMutator'
import MaterialSelect from 'components/atoms/MaterialSelect/MaterialSelect'
import { showToast } from 'utils'
import AdminLayout from 'layouts/AdminLayout'
import { useTranslation } from 'react-i18next'

interface ParamsProps {
  teamId: string
}

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
  user: {
    marginBottom: 10,
    marginTop: 10,
  },
  allUsers: {
    marginTop: 30,
    marginBottom: 30,
  },
})

interface TeamDetailPageProps {}

const TeamDetailPage: FC<TeamDetailPageProps> = () => {
  const classes = useStyles()
  const { teamId } = useParams<ParamsProps>()

  const [data, setData] = useState(null)
  const [options, setOptions] = useState([])

  const team = useAbstractProvider(TeamsApi.getTeamById, { teamId })

  const allUsersOfTeam = useAbstractProvider(UserTeamsApi.getAllUsersOfTeam, teamId)
  const allUsersNotOfTeam = useAbstractProvider(UserTeamsApi.getAllUsersNotOfTeam, teamId)

  const removeUserTeam = useAbstractMutator(UserTeamsApi.removeUserToTeam)
  const addUserTeam = useAbstractMutator(UserTeamsApi.addUserToTeam)

  const [selectedUser, setSelectedUser] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (teamId) {
      team.refetch(teamId)
    }
  }, [teamId])

  useEffect(() => {
    if (team?.data) {
      setData(team?.data)
    }
  }, [team.data])

  useEffect(() => {
    if (allUsersNotOfTeam?.data) {
      setOptions(allUsersNotOfTeam?.data.map((user) => ({ value: user.id, name: user.email })))
    }
  }, [allUsersNotOfTeam.data])

  useEffect(() => {
    if (addUserTeam?.data) {
      allUsersOfTeam.refetch(teamId)
      allUsersNotOfTeam.refetch(teamId)
      showToast(t('admin_users_and_teams.add_to_team_success_msg'))
    }
  }, [addUserTeam.data])

  useEffect(() => {
    if (addUserTeam?.data || removeUserTeam?.data) {
      allUsersOfTeam.refetch(teamId)
      allUsersNotOfTeam.refetch(teamId)
      showToast(t('admin_users_and_teams.remove_from_team_success_msg'))
    }
  }, [removeUserTeam.data])

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Text variant="h2" className={classes.heading}>
            {t('admin_users_and_teams.team_detail')}
          </Text>
          <Grid container>
            <Grid item xs={12}>
              {team.loading ? (
                <Loader />
              ) : (
                <div>
                  <div>
                    {t('id')}: {data?.id}
                  </div>
                  <div>
                    {t('name')}: {data?.name}
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} className={classes.allUsers}>
              <h2>{t('admin_users_and_teams.all_users_of_team')}</h2>
              {allUsersOfTeam?.data?.map((user) => (
                <Grid key={user.id} container alignItems="center" spacing={4}>
                  <Grid item xs={4}>
                    {user.email}
                  </Grid>
                  <Grid item xs={4}>
                    <CustomButton
                      onClick={() => {
                        removeUserTeam.mutate({ teamId: team?.data?.id, userId: user?.id })
                      }}
                    >
                      {t('delete')}
                    </CustomButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <h2>{t('admin_users_and_teams.add_user_to_team')}</h2>
              <Grid container alignItems="center" className={classes.user}>
                <Grid item xs={4}>
                  {options ? (
                    <MaterialSelect
                      value={selectedUser || ''}
                      label="user"
                      onChange={(val) => setSelectedUser(val)}
                      options={options}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={4}>
                  <CustomButton
                    onClick={() => {
                      addUserTeam.mutate({ teamId: team?.data?.id, userId: selectedUser })
                    }}
                  >
                    {t('add')}
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default TeamDetailPage
