import React, { FC, useEffect, useState } from 'react'
import UserApi from 'api/user'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import useAbstractProvider from 'providers/AbstractProvider'
import Loader from 'components/atoms/Loader/Loader'
import MaterialTable from 'components/organisms/MaterialTable/MaterialTable'
import { useHistory } from 'react-router-dom'
import AdminLayout from 'layouts/AdminLayout'
import { useTranslation } from 'react-i18next'
import MaterialSelect from 'components/atoms/MaterialSelect/MaterialSelect'
import moment from 'moment'
import { getRangeOfNumbers } from 'utils'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import exportXlsx from 'utils/exportXlsx'

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  table: {
    paddingBottom: 70,
  },
  addUser: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

interface AdminLicensingProps {}

const AdminLicensing: FC<AdminLicensingProps> = () => {
  const { t } = useTranslation()

  const months = moment.months().map((month, index) => ({ name: month, value: String(index) }))
  const years = getRangeOfNumbers(2020, moment().year()).map((year) => ({
    name: String(year),
    value: String(year),
  }))
  const licenseOptions = [
    { name: t('all'), value: '2' },
    { name: t('admin_licensing.licensed'), value: '1' },
    { name: t('admin_licensing.not_licensed'), value: '0' },
  ]

  const classes = useStyles()
  const [data, setData] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [month, setMonth] = useState(moment().month())
  const [year, setYear] = useState(String(moment().year()))
  const [licenseOption, setLicenseOption] = useState('1')
  const [fullCount, setFullCount] = useState()
  const history = useHistory()

  const licensingDataProvider = useAbstractProvider(UserApi.getLicensingData, {
    offset,
    limit,
    month,
    year,
    licenseOption,
    requestCount: true,
  })

  const head = [
    { name: t('name'), dbName: 'name' },
    { name: t('email'), dbName: 'email' },
    { name: t('role'), dbName: 'role' },
    { name: t('admin_licensing.login_count'), dbName: 'logins' },
    { name: t('admin_licensing.licensed'), dbName: 'licensed' },
  ]

  useEffect(() => {
    if (licensingDataProvider?.data) {
      if (licensingDataProvider.data.count) {
        setFullCount(licensingDataProvider.data.count)
      }
      setData(licensingDataProvider?.data.data.map((item) => ({ ...item, role: t(item.role) })))
    }
  }, [licensingDataProvider.data])

  useEffect(() => {
    if (licensingDataProvider?.data) {
      licensingDataProvider.refetch({
        offset,
        limit,
        month,
        year,
        licenseOption,
      })
    }
  }, [offset, limit])

  useEffect(() => {
    if (licensingDataProvider?.data) {
      licensingDataProvider.refetch({
        offset,
        limit,
        month,
        year,
        licenseOption,
        requestCount: true,
      })
    }
  }, [month, year, licenseOption])

  function handleChangePage(pageToChange) {
    setOffset(pageToChange * limit)
  }

  const selectItem = (selectedItem) => {
    history.push(`/admin-view/user-detail/${selectedItem.id}`)
  }

  const handleExport = () => {
    exportXlsx(`${t('admin_licensing.header_title')}_${months[month].name}-${year}`, [
      {
        title: `${months[month].name}-${year}`,
        data: data.map((item) => ({
          [t('name')]: item.name,
          [t('email')]: item.email,
          [t('role')]: item.role,
          [t('admin_licensing.login_count')]: item.logins,
          [t('admin_licensing.licensed')]: item.licensed ? t('yes') : t('no'),
        })),
      },
    ])
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Grid container>
            <Grid item xs={6}>
              <Text variant="h2" className={classes.heading}>
                {t('admin_licensing.header_title')}
              </Text>
            </Grid>
            <Grid item xs={2}>
              <MaterialSelect
                value={months[month].value}
                label="Month"
                onChange={(monthIndex) => setMonth(Number(monthIndex))}
                options={months}
              />
            </Grid>
            <Grid item xs={2}>
              <MaterialSelect
                value={year}
                label="Year"
                onChange={(selectedYear) => setYear(selectedYear)}
                options={years}
              />
            </Grid>
            <Grid item xs={2}>
              <MaterialSelect
                value={licenseOption}
                label="License State"
                onChange={(license) => setLicenseOption(license)}
                options={licenseOptions}
              />
            </Grid>
            <Grid style={{ marginBottom: '0.5rem' }} item xs={12}>
              <CustomButton onClick={handleExport}>{t('export')}</CustomButton>
            </Grid>
            <Grid item xs={12}>
              {licensingDataProvider.loading ? (
                <Loader />
              ) : (
                <div className={classes.table}>
                  <MaterialTable
                    head={head}
                    data={data.sort((a, b) => b.logins - a.logins)}
                    onRowClick={selectItem}
                    count={fullCount}
                    onChangePage={handleChangePage}
                    page={!fullCount ? 0 : page}
                    setPage={setPage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={(value) => {
                      setLimit(value)
                      setOffset(0)
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default AdminLicensing
