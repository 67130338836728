import { BACKEND_WS_URL } from 'helpers/configuration'
import { ClickAwayListener, IconButton, makeStyles, Paper } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'
import React, { FC, useState, useEffect, useContext } from 'react'
// import NotificationsApi from 'api/notifications'
import { StoreContext } from 'App'
import NotificationsList from './NotificationsList'

const useStyles = makeStyles({
  icon: {
    color: '#191934',
  },
  root: {
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: 48,
    right: 0,
    zIndex: 1,
    width: 320,
    maxHeight: 320,
    overflowY: 'auto'
  },
})

interface NotificationsButtonProps {}

const NotificationsButton: FC<NotificationsButtonProps> = () => {
  const classes = useStyles()
  const store = useContext(StoreContext)

  const [open, setOpen] = useState(false)
  const [notificationsCount, setNotificationsCount] = useState<number>(null)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
    setNotificationsCount(0)
  }

  useEffect(() => {
    const baseUrl = BACKEND_WS_URL || `${window.location.protocol.replace(/^http/ui, 'ws')}//${window.location.host}`
    const ws = new WebSocket(`${baseUrl}/sockets/notifications`)

    ws.onopen = () => {
      ws.send(JSON.stringify({
        event: 'notifications',
        data: { token: store.currentUser.token }
      }))
    }

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data)
      setNotificationsCount(data?.data?.count || 0)
    }
    
    return () => {
      ws.close()
    }
  }, [])

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <div className={classes.root}>
        <IconButton onClick={handleClick} className={classes.icon}>
          <Badge overlap="rectangular" color="secondary" badgeContent={notificationsCount}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        {open ? (
          <Paper elevation={3} className={classes.dropdown}>
            <NotificationsList />
          </Paper>
        ) : null}
      </div>
    </ClickAwayListener>
  )
}

export default NotificationsButton