import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getConfigs() {
  const res = await API.get(`${API_URL}/configs`, true)
  return res
}

const getLogo = async () => {
  const res = await API.get(`${API_URL}/configs/logo`, true)
  return res
}

const getCloudfrontUrl = async () => {
  const res = await API.get(`${API_URL}/configs/cloudfront`, true)
  return res
}

const ConfigsApi = {
  getConfigs,
  getLogo,
  getCloudfrontUrl,
}

export default ConfigsApi
