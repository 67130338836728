const getScreenResolution = () => {
    let screenResolution = ''
    // eslint-disable-next-line prefer-destructuring
    const screen = window.screen
    if (screen.width) {
        const width = (screen.width) ? screen.width : ''
        const height = (screen.height) ? screen.height : ''
        screenResolution += `${width} x ${height}`
    }

    return screenResolution
}

export default getScreenResolution
