import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function login(params) {
  const res = await API.post(`${API_URL}/auth/sign-in`, params)
  return res
}

async function openIdLogin() {
  const res = await API.post(`${API_URL}/auth/openid-sign-in`, null)
  return res
}

async function openIdVerifyUser(params) {
  const res = await API.post(`${API_URL}/auth/openid-verify-user`, { ...params })
  return res
}

async function register(params) {
  const res = await API.post(`${API_URL}/auth/sign-up`, params)
  return res
}

async function savePassword(params) {
  const res = await API.post(`${API_URL}/auth/save-password`, params)
  return res
}

async function checkValidToken(params) {
  const res = await API.post(`${API_URL}/auth/check-valid-token`, params)
  return res
}

async function resetPassword(params) {
  const res = await API.post(`${API_URL}/auth/reset-password`, params)
  return res
}

async function addNewUser(body) {
  const res = await API.post(
    `${API_URL}/auth/add-new-user`,
    {
      ...body,
    },
    true,
  )
  return res
}

async function logout(params) {
  const res = await API.post(`${API_URL}/auth/sign-out`, params, true)
  return res
}

const AuthApi = {
  login,
  openIdLogin,
  openIdVerifyUser,
  register,
  savePassword,
  checkValidToken,
  resetPassword,
  addNewUser,
  logout,
}

export default AuthApi
