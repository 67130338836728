import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React, { FC } from 'react'

import MainLayout from 'layouts/MainLayout'
import StaticContent from 'components/organisms/StaticContent/StaticContent'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    heading: {
        color: '#00008F',
        letterSpacing: '0px',
    },
    topHeading: {
        marginTop: '60px'
    }
})

export interface DataProtectionPageProps {}

const DataProtectionPage: FC<DataProtectionPageProps> = () => {
    const classes = useStyles()

    return (
        <>
            {MainLayout.getLayout(
                <div className={classes.container}>
                    <div>
                        <StaticContent
                            code="gdpr"
                            headingClassName={classNames(classes.heading, classes.topHeading)}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default DataProtectionPage
