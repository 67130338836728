import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getArgumentationFeedbackByArgumentationId(argumentationId) {
  const res = await API.get(`${API_URL}/argumentation-feedback/${argumentationId}`, true)
  return res
}

async function saveArgumentationFeedack(argumentationFeedback) {
  const res = await API.post(
    `${API_URL}/argumentation-feedback`,
    {
      ...argumentationFeedback,
    },
    true,
  )
  return res
}

async function editArgumentationFeedback(argumentationFeedback) {
  const res = await API.put(
    `${API_URL}/argumentation-feedback`,
    {
      ...argumentationFeedback,
    },
    true,
  )
  return res
}

async function deleteArgumentationFeedback(argumentationFeedbackId) {
  const res = await API.remove(`${API_URL}/argumentation-feedback/${argumentationFeedbackId}`, true)
  return res
}

const ArgumentationFeedbackApi = {
  getArgumentationFeedbackByArgumentationId,
  saveArgumentationFeedack,
  editArgumentationFeedback,
  deleteArgumentationFeedback,
}

export default ArgumentationFeedbackApi
