import {Box, makeStyles} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { FC, useContext } from 'react'

import { StoreContext } from 'App'
import LearningTypesApi from 'api/learningTypes'
import Text from 'components/atoms/Text/Text'
import LearningProducts from 'components/learning/LearningProducts'
import useAbstractProvider from 'providers/AbstractProvider'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    boxSizing: 'border-box',
    padding: '15px 0px',
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: '#cce3e6',
    padding: 17,
  },
  learningTypeName: {
    marginBottom: 5,
    cursor: 'pointer',
    padding: '10px 17px',
    fontSize: '20px',
    textTransform: 'uppercase',
    '&:nth-child(4)': {
      marginBottom: 50,
      color: '#cccccc',
    },
  },
  heading3: {
    marginBottom: 15,
    padding: '0px 17px',
  },
  channelLabel: {
    padding: '0px 17px',
  },
}))

export interface LearningSidebarProps {
  onLearningTypeChange: Function
}

const LearningSidebar: FC<LearningSidebarProps> = ({ onLearningTypeChange }) => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const { data: learningTypes } = useAbstractProvider(LearningTypesApi.getLearningTypes)

  const { t } = useTranslation()

  const translateLearningType = (name: 'Erarbeiten' | 'Trainieren' | 'Beherrschen') => {
    if (name === 'Erarbeiten') return t('learning.type_1')
    if (name === 'Trainieren') return t('learning.type_2')
    if (name === 'Beherrschen') return t('learning.type_3')
    return 'translationNeeded'
  }

  return (
    <>

      <Paper className={classes.paper} square>
        <Box>
          <Text variant="body2" className={classes.channelLabel}>
            {t('channel')}:
          </Text>
          <Text variant="h3" className={classes.heading3} wrap>
             {store?.selectedChannel?.name}
          </Text>
        </Box>

        {learningTypes?.map((learningType) => (
          <div
            key={`product-${learningType?.id}`}
            className={classNames(
              classes.learningTypeName,
              store.selectedLearningType.id === learningType.id ? classes.selected : null,
            )}
            onClick={() => {
              if (learningType.id === 3) return

              store.setSelectedLearningType(learningType)
              onLearningTypeChange()
            }}
          >
            {translateLearningType(learningType.name)}
          </div>
        ))}
        <LearningProducts />
      </Paper>
    </>
  )
}

export default observer(LearningSidebar)
