import ConversationAssetsApi from 'api/conversationAssets'
import useAbstractMutator from 'providers/AbstractMutator'
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import Webcam from 'react-webcam'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import StopIcon from '@material-ui/icons/Stop'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Loader from 'components/atoms/Loader/Loader'
import CheckIcon from '@material-ui/icons/Check'
import Text from 'components/atoms/Text/Text'
import { isSafari } from 'react-device-detect'
import { StoreContext } from 'App'
import { useTranslation } from 'react-i18next'
import { showToast } from 'utils'
import SendIcon from '@material-ui/icons/Send'
import CancelButton from '../atoms/ColorButton/CancelButton'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'red',
    height: 50,
    width: 50,
  },
  recordIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
    background: 'transparent',
    border: '3px solid #D3D3D3',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '10px',
    left: 'calc(50% - 23.5px)',
  },
  circle: {
    borderRadius: 999,
  },
  iconGrey: {
    color: 'grey',
  },
  container: {
    position: 'relative',
  },
  cancelBtn: {
    marginLeft: 20,
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  published: {
    display: 'flex',
    alignItems: 'center',
    color: '#41B883',
  },
  checkbox: {
    marginRight: 5,
  },
  error: {
    color: '#B00020',
    marginLeft: 10,
  },
  removeBtnAndError: {
    display: 'flex',
    alignItems: 'center',
  },
  publishBtn: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
}))

const WebcamStreamCapture = ({ groupId, className, onUpload, editRecording, removeRecording }) => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const webcamRef = useRef(null)
  const mediaRecorderRef = useRef(null)
  const { t } = useTranslation()
  const [capturing, setCapturing] = useState(false)
  const [error, setError] = useState(null)
  const [recordedChunks, setRecordedChunks] = useState([])
  const [recordingBlob, setRecordingBlob] = useState(null as any)
  const [recording, setRecording] = useState({} as any)
  const {
    data: uploadRecordingData,
    mutate: uploadRecording,
    loading,
    error: uploadRecordingError,
  } = useAbstractMutator(ConversationAssetsApi.uploadRecording)

  useEffect(() => {
    if (uploadRecordingError) {
      showToast(uploadRecordingError?.data?.message || t('error_occured'))
    }
  }, [uploadRecordingError])

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    [setRecordedChunks],
  )

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true)
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: isSafari ? 'video/mp4' : 'video/webm',
      audioBitsPerSecond: 100000,
      videoBitsPerSecond: 1000000,
    })
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable)
    mediaRecorderRef.current.start()
  }, [webcamRef, setCapturing, mediaRecorderRef])

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }, [mediaRecorderRef, webcamRef, setCapturing])

  useEffect(() => {
    if (uploadRecordingData) {
      setRecording(uploadRecordingData)
      onUpload()
    }
  }, [uploadRecordingData])

  useEffect(() => {
    if (recordedChunks?.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/mp4',
      })
      const url = URL.createObjectURL(blob)

      setRecordingBlob(url)
    }
  }, [recordedChunks])

  const handleUpload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: 'video/mp4',
      })

      const options = {
        'Content-type': blob.type,
      }

      const data = new FormData()
      // data.append("body", blob);
      data.append('conversationGroupId', groupId as string)
      data.append('published', 'false')
      data.append('image', blob)
      data.append('languageCode', store.language)

      uploadRecording(data, options)
    }
  }, [recordedChunks])

  const handleDiscardRecording = () => {
    if (recording.published) {
      setError(t('recording_section.delete_published_error'))
    } else {
      setRecordedChunks([])
      removeRecording(recording)
    }
  }

  const publishRecording = () => {
    const newRecording = { ...recording }
    newRecording.published = true
    setRecording(newRecording)
    editRecording(newRecording)
  }

  return (
    <div>
      <div className={classes.container}>
        {recordedChunks.length > 0 ? (
          <>
            <div>
              { recordingBlob &&
              <video playsInline muted loop width="100%" autoPlay controls onMouseUp={() => {}}>
                <source src={recordingBlob} type="video/mp4" />
                <track src="captions_en.vtt" kind="captions" label="english_captions" />
                {t('recording_section.video_error')}
              </video>
              }
            </div>
            <div className={classes.btns}>
              <div className={classes.removeBtnAndError}>
                <CustomButton color="primary" onClick={() => handleUpload()}>
                  {t('save')}
                </CustomButton>
                <CancelButton
                  className={classes.cancelBtn}
                  variant="outlined"
                  onClick={() => handleDiscardRecording()}
                  disabled={!recording?.id}
                >
                  {t('delete')}
                </CancelButton>
                <Text variant="body1" className={classes.error}>
                  {error}
                </Text>
              </div>

              {uploadRecordingData ? (
                <>
                  {recording?.published ? (
                    <Text variant="body1" className={classes.published}>
                      <CheckIcon className={classes.checkbox} /> {t('published')}
                    </Text>
                  ) : (
                    <CustomButton
                      startIcon={<SendIcon/>}
                      onClick={() => publishRecording()}
                      className={classNames(classes.publishBtn)}
                    >{t('publish')}
                    </CustomButton>
                  )}
                </>
              ) : null}
              {loading ? <Loader /> : null}
            </div>
          </>
        ) : (
          <>
            <Webcam className={className} width="100%" ref={webcamRef} />
            {!capturing ? (
              <div className={classNames(classes.circle, classes.recordIcon)}>
                <FiberManualRecordIcon
                  className={classes.icon}
                  onClick={() => {
                    handleStartCaptureClick()
                  }}
                />
              </div>
            ) : (
              <div className={classNames(classes.recordIcon)}>
                <StopIcon
                  className={classes.icon}
                  onClick={() => {
                    handleStopCaptureClick()
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default WebcamStreamCapture
