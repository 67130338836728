import { useEffect } from 'react'
import moment from 'moment'
import { toast } from 'react-toastify'

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}

export function showDate(date) {
  const dateDays = moment().diff(date, 'days')
  if (dateDays) return `${dateDays}d`
  const dateHours = moment().diff(date, 'hours')
  if (dateHours) return `${dateHours}h`
  const dateMinutes = moment().diff(date, 'minutes')
  if (dateMinutes) return `${dateMinutes}m`
  const dateSeconds = moment().diff(date, 'seconds')
  if (dateSeconds) return `${dateSeconds}s`
  return null
}

export function showToast(message) {
  toast(message)
}

export function getLocalStorage(name) {
  return JSON.parse(localStorage.getItem(name))
}

export function setLocalStorage(name, value) {
  localStorage.setItem(name, JSON.stringify(value))
}

export function removeLocalStorage(name) {
  localStorage.removeItem(name)
}

export const FIVE_MEGA = 5048576

export const getRangeOfNumbers = (start: number, end: number) =>
  Array(end - start + 1)
    .fill('')
    .map((_, idx) => start + idx)

export function dhm(t: number) {
  const cd = 24 * 60 * 60 * 1000
  const ch = 60 * 60 * 1000
  let d = Math.floor(t / cd)
  let h = Math.floor((t - d * cd) / ch)
  let m = Math.round((t - d * cd - h * ch) / 60000)
  const pad = function (n) {
    return n < 10 ? `0${n}` : n
  }
  if (m === 60) {
    h += 1
    m = 0
  }
  if (h === 24) {
    d += 1
    h = 0
  }
  // return `${pad(d)}d:${pad(h)}h:${pad(m)}m`
  return { days: d, hours: h, minutes: m}
}

// export function dh(timeMilliseconds: any) {
//   const duration = moment.duration(timeMilliseconds, 'millisecond')
//   const days = Math.floor(duration.asDays())
//   const hours = Math.floor(duration.subtract(days, 'days').asHours())
//   return { days, hours}
// }

export enum ROLES {
  trainee = 1,
  mentor = 2,
  admin = 3,
  manager = 4,
  contentmanager = 5
}

export function getTheRightRole(ssoData) {
  let roleId
  if (ssoData) {
    const role = ssoData.role ? ssoData.role : ssoData
    if (role instanceof Array) {
      if (role.find((item) => item === 'TRAINEE' || item === 'no_role')) {
        roleId = 1
      }
      if (role.find((item) => item === 'MENTOR')) {
        roleId = 2
      }
      if (role.find((item) => item === 'MANAGER')) {
        roleId = 4
      }
      if (role.find((item) => item === 'contentmanager')) {
        roleId = 5
      }
    } else {
      roleId = ROLES[role.toLowerCase()]
    }
  }
  return roleId
}
