import React, { useContext, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import useAbstractProvider from 'providers/AbstractProvider'
import useAbstractMutator from 'providers/AbstractMutator'
import { StoreContext } from 'App'
import ChannelsApi from 'api/channels'
import ConfirmationDialog from 'components/organisms/Dialog/ConfirmationDialog'
import { ExpandMore, HighlightOff, Save, Add, AddCircle, CloudUpload } from '@material-ui/icons'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { Link, useHistory } from 'react-router-dom'
import FullScreenLoader from 'components/organisms/FullScreenLoader/FullScreenLoader'
import { LearningMaterialsFullType } from 'stores'
import { ChannelFullDataType, ChannelTranslationsType } from 'types/Channel'
import AdminLayout from 'layouts/AdminLayout'
import { showToast } from 'utils'
import { FALLBACK_LANGUAGE } from 'helpers/configuration'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
  },
  buttons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  accordionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 15,
  },
  accordionTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  accordionName: { width: '90%' },
  accordionDetails: {
    display: 'flex',
    position: 'relative',
  },
  accordionPhotoDiv: {
    marginRight: '5%',
  },
  photoUploadImage: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '10px 0 0 0',
    boxShadow: '0px -1px 10px 1px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
  accordionDataDiv: {
    height: '100%',
  },
  channelEditButtonDiv: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    gap: 5,
  },
  channelEditButton: {
    border: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
  },
  languageChooser: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginRight: 20,
  },
  modalContainer: {
    padding: 20,
  },
})

const defaultValueProperties = {
  name: '',
  isActive: false,
}
const IMAGE_INPUT_ELEMENT_ID = 'avatar-add-input'
const IMAGE_UPLOAD_PLACEHOLDER_PHOTO = '/image-upload-placeholder.png'
const NEW_CHANNEL_DEFAULT_ID = 1234567890

const [DeleteConfirmationDialog, NewChannelCancelConfirmationDialog] =
  Array(2).fill(ConfirmationDialog)

const Channels = () => {
  const store = useContext(StoreContext) as {
    cloudfrontUrl: string
    language: string
    languages: string[]
    learningMaterialsFull: LearningMaterialsFullType
    // eslint-disable-next-line no-unused-vars
    setChannelsFullData: (values: ChannelFullDataType[]) => void
  }

  const { t } = useTranslation()

  const classes = useStyles()
  const history = useHistory()

  const [channels, setChannels] = useState<ChannelFullDataType[] | undefined>()

  const [selectedChannel, setSelectedChannel] = useState<ChannelTranslationsType>({
    [FALLBACK_LANGUAGE]: defaultValueProperties,
  })
  const [selectedChannelOriginalValues, setSelectedChannelOriginalValues] =
    useState<ChannelTranslationsType>({
      [FALLBACK_LANGUAGE]: defaultValueProperties,
    })

  const [expandedChannelId, setExpandedChannelId] = useState<number | undefined>()

  const [previewImage, setPreviewImage] = useState(IMAGE_UPLOAD_PLACEHOLDER_PHOTO)
  const [photo, setPhoto] = useState<undefined | File>()

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [channelIdToBeDeleted, setChannelIdToBeDeleted] = useState<number | undefined>()

  const [isNewChannelCancelConfirmationModalOpen, setIsNewChannelCancelConfirmationModalOpen] =
    useState(false)

  const [isNewChannelBeingAdded, setIsNewChannelBeingAdded] = useState(false)

  const channelsProvider = useAbstractProvider(ChannelsApi.getChannelFullData, null, false)
  const editChannelMutator = useAbstractMutator(ChannelsApi.editChannel)
  const addChannelMutator = useAbstractMutator(ChannelsApi.addChannel)
  const deleteChannelMutator = useAbstractMutator(ChannelsApi.deleteChannel)

  useEffect(() => {
    if (!store.learningMaterialsFull.channels.length) {
      console.log('Fetch Channels From DB')
      channelsProvider.refetch()
    } else {
      console.log('Get Channels From Cache')
      setChannels(store.learningMaterialsFull.channels)
    }
  }, [])

  useEffect(() => {
    if (channelsProvider.data) {
      store.setChannelsFullData(channelsProvider.data)
      setChannels(channelsProvider.data)
      setPreviewImage(IMAGE_UPLOAD_PLACEHOLDER_PHOTO)
      setPhoto(undefined)
    }
  }, [channelsProvider.data])

  useEffect(() => {
    if (expandedChannelId) {
      const selectedChannelTranslations = channels.find(
        (item) => item.id === expandedChannelId,
      ).translations
      setSelectedChannel(selectedChannelTranslations)
      setSelectedChannelOriginalValues(selectedChannelTranslations)
    }
  }, [expandedChannelId])

  useEffect(() => {
    if (editChannelMutator.data || addChannelMutator.data || deleteChannelMutator.data) {
      setIsNewChannelBeingAdded(false)
      channelsProvider.refetch()
    }
  }, [editChannelMutator.data, addChannelMutator.data, deleteChannelMutator.data])

  const handleChannelExpand = (channelId: number) => {
    if (isNewChannelBeingAdded) {
      setIsNewChannelCancelConfirmationModalOpen(true)
    } else {
      setExpandedChannelId((existingId) => (existingId === channelId ? undefined : channelId))
      setPreviewImage(IMAGE_UPLOAD_PLACEHOLDER_PHOTO)
    }
  }

  const handleDelete = () => {
    deleteChannelMutator.mutate(channelIdToBeDeleted)
  }

  const handleChannelAddingCancel = () => {
    setChannels((arr) => arr.slice(0, -1))
    setIsNewChannelBeingAdded(false)
    setExpandedChannelId(undefined)
  }

  const handleEdit = () => {
    if (Object.keys(selectedChannel).some((item) => selectedChannel[item]?.name === ''))
      return showToast(t('fill_everything'))
    if (JSON.stringify(selectedChannel) === JSON.stringify(selectedChannelOriginalValues))
      return showToast(t('admin_learning_panel.nothing_has_changed'))
    const formData = new FormData()
    if (photo) formData.append('photo', photo, photo.name)
    formData.append('translations', JSON.stringify(selectedChannel))
    editChannelMutator.mutate(expandedChannelId, formData)
    setSelectedChannelOriginalValues(selectedChannel)
    return null
  }

  const handleAdd = () => {
    if (!photo || Object.keys(selectedChannel).some((item) => selectedChannel[item]?.name === ''))
      return showToast(t('fill_everything'))
    const formData = new FormData()
    formData.append('photo', photo, photo.name)
    formData.append('translations', JSON.stringify(selectedChannel))
    addChannelMutator.mutate(formData)
    return null
  }

  const handleChannelAdd = () => {
    if (isNewChannelBeingAdded) {
      return handleAdd()
    }
    setChannels((arr) => [
      ...arr,
      {
        id: NEW_CHANNEL_DEFAULT_ID,
        imageUrl: IMAGE_UPLOAD_PLACEHOLDER_PHOTO,
        createdAt: '',
        translations: {
          [FALLBACK_LANGUAGE]: {
            name: 'Add Channel DE',
            isActive: false,
          },
        },
      },
    ])
    setIsNewChannelBeingAdded(true)
    setExpandedChannelId(NEW_CHANNEL_DEFAULT_ID)
    return null
  }

  const handleNameChange = (newName: string, lang: string) => {
    setSelectedChannel((obj) => ({
      ...obj,
      [lang]: { ...obj[lang], name: newName },
    }))
  }

  const handleIsActiveChange = (newIsActive: boolean, lang: string) => {
    setSelectedChannel((obj) => ({
      ...obj,
      [lang]: { ...obj[lang], isActive: newIsActive },
    }))
  }

  const handleImageUploadPopup = () => {
    const inputElement = document.querySelector(`#${IMAGE_INPUT_ELEMENT_ID}`) as HTMLElement
    inputElement.click()
  }

  const handleImageUpload = (e) => {
    e.preventDefault()
    setPhoto(e.target.files[0])
    setPreviewImage(URL.createObjectURL(e.target.files[0]))
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <div className={classes.container}>
            {!channels ? (
              <FullScreenLoader />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={10}>
                    <Text variant="h3">{t('admin_learning_panel.channels')}</Text>
                  </Grid>
                  <Grid item xs={2} className={classes.buttons}>
                    <IconButton
                      onClick={() => history.push('/admin-view/learning-material-managment-import')}
                      color="default"
                      component="span"
                    >
                      <CloudUpload />
                    </IconButton>
                    <IconButton onClick={handleChannelAdd} color="primary" component="span">
                      <AddCircle />
                    </IconButton>
                  </Grid>
                  <hr />
                  <hr />
                  <Grid item xs={12}>
                    {channels.map((channel, index) => (
                      <Accordion
                        key={channel.id}
                        expanded={expandedChannelId === channel.id}
                        disabled={editChannelMutator.loading || addChannelMutator.loading}
                      >
                        <AccordionSummary
                          IconButtonProps={{
                            onClick: () => handleChannelExpand(channel.id),
                          }}
                          expandIcon={<ExpandMore />}
                        >
                          <div
                            onClick={() => {
                              if (!isNewChannelBeingAdded || index !== channels.length - 1) {
                                history.push(
                                  `/admin-view/learning-material-managment/channelId=${channel.id}`,
                                )
                              }
                              return null
                            }}
                            className={classes.accordionHeader}
                          >
                            <div className={classes.accordionName}>
                              <Text variant="h4">
                                {channel.translations[store.language]?.name ||
                                  channel.translations[FALLBACK_LANGUAGE]?.name}
                              </Text>
                            </div>
                          </div>
                          <div className={classes.accordionTooltip}>
                            {channel.id === NEW_CHANNEL_DEFAULT_ID ? (
                              <Tooltip placement="top" title={t('add')}>
                                <IconButton size="small" onClick={handleAdd}>
                                  <Add fontSize="medium" color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : expandedChannelId === channel.id ? (
                              <Tooltip placement="top" title={t('edit')}>
                                <IconButton onClick={handleEdit} size="small">
                                  <Save fontSize="medium" color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <Tooltip placement="top" title={t('delete')}>
                              <IconButton
                                onClick={() => {
                                  if (isNewChannelBeingAdded) {
                                    setIsNewChannelCancelConfirmationModalOpen(true)
                                  } else {
                                    setChannelIdToBeDeleted(channel.id)
                                    setIsDeleteConfirmationModalOpen(true)
                                  }
                                }}
                                size="small"
                              >
                                <HighlightOff fontSize="medium" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {channel.id === expandedChannelId && (
                            <>
                              <div
                                className={classes.accordionPhotoDiv}
                                onClick={handleImageUploadPopup}
                              >
                                <img
                                  className={classes.photoUploadImage}
                                  src={
                                    previewImage === IMAGE_UPLOAD_PLACEHOLDER_PHOTO &&
                                    !isNewChannelBeingAdded
                                      ? `${store.cloudfrontUrl}/${channel.imageUrl}`
                                      : previewImage
                                  }
                                  alt="add"
                                />
                                <input
                                  type="file"
                                  hidden
                                  id={IMAGE_INPUT_ELEMENT_ID}
                                  accept=".png, .jpg, .jpeg"
                                  name="photo"
                                  onChange={handleImageUpload}
                                />
                              </div>
                              {expandedChannelId && (
                                <Grid container>
                                  <Grid xs={12} item>
                                    <FormControlLabel
                                      disabled={
                                        editChannelMutator.loading || addChannelMutator.loading
                                      }
                                      control={
                                        <Switch
                                          color="primary"
                                          checked={selectedChannel.de.isActive}
                                          onChange={(e) =>
                                            handleIsActiveChange(e.target.checked, 'de')
                                          }
                                        />
                                      }
                                      label={t('admin_learning_panel.active_status')}
                                    />
                                  </Grid>
                                  <Grid xs={12} item>
                                    <MaterialInput
                                      disabled={
                                        editChannelMutator.loading || addChannelMutator.loading
                                      }
                                      value={selectedChannel.de.name}
                                      size="small"
                                      onChange={(value) => handleNameChange(value, 'de')}
                                      type="text"
                                      label={t('name')}
                                      name="name"
                                    />
                                  </Grid>
                                </Grid>
                              )}
                              <div className={classes.channelEditButtonDiv}>
                                {editChannelMutator.loading || addChannelMutator.loading ? (
                                  <CircularProgress size="1.5rem" />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </div>
          <DeleteConfirmationDialog
            open={isDeleteConfirmationModalOpen}
            handleClose={() => setIsDeleteConfirmationModalOpen(false)}
            title={`${t('admin_learning_panel.delete_channel')} ${
              channels?.find((item) => item.id === channelIdToBeDeleted)?.translations[
                store.language
              ]?.name ||
              channels?.find((item) => item.id === channelIdToBeDeleted)?.translations[
                FALLBACK_LANGUAGE
              ].name
            } ?`}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleDelete}
          />
          <NewChannelCancelConfirmationDialog
            open={isNewChannelCancelConfirmationModalOpen}
            handleClose={() => setIsNewChannelCancelConfirmationModalOpen(false)}
            title={t('admin_learning_panel.cancel_channel_add_question')}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleChannelAddingCancel}
          />
        </>,
      )}
    </ProtectedPage>
  )
}

export default Channels
