import React from 'react'
import { observer } from 'mobx-react-lite'

import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import LearningProducts from 'components/learning/LearningProducts'
import LearningLayout from 'layouts/LearningLayout'

function ProductsPage () {
    return (
        <ProtectedPage>
            <LearningLayout>
                <LearningProducts />
            </LearningLayout>
        </ProtectedPage>
    )
}

export default observer(ProductsPage)
