import { useMediaQuery } from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'

const useMedia = () => {
  const [isLoading, setIsLoading] = useState(true)

  const xs = useMediaQuery(`(max-width: 576px)`)
  const sm = useMediaQuery(`(min-width: 576px)`)
  const md = useMediaQuery(`(min-width: 768px)`)
  const lg = useMediaQuery(`(min-width: 992px)`)
  const xl = useMediaQuery(`(min-width: 1200px)`)

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 10)
  }, [])

  return useMemo(
    () => ({
      xs,
      sm,
      md,
      lg,
      xl,
      isLoading,
    }),
    [xs, sm, md, lg, xl, isLoading],
  )
}

export default useMedia
