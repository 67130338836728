import {withStyles} from '@material-ui/styles'
import {Button} from '@material-ui/core'

const CancelButton = withStyles((theme) => ({
  root: {
    height: '44px',
    borderRadius: '0px',
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(240,118,98,0.13)',
    },
  },
}))(Button)

export default CancelButton