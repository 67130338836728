import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getConversationsProgress(productId) {
  const res = await API.get(`${API_URL}/conversation-progress/${productId}`, true)

  return res
}

async function saveConversationsProgress(conversationId) {
  const res = await API.post(
    `${API_URL}/conversation-progress`,
    {
      completed: true,
      conversationId,
    },
    true,
  )
  return res
}

const ConversationsProgressApi = { getConversationsProgress, saveConversationsProgress }

export default ConversationsProgressApi
