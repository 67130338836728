import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getUsersTeams(id) {
  const res = await API.get(`${API_URL}/teams/getUsersTeams/${id}`, true)
  return res
}

async function getTeamsUserIsNotIn(id) {
  const res = await API.get(`${API_URL}/teams/getTeamsUserIsNotIn/${id}`, true)
  return res
}

async function addNewTeam(body) {
  const res = await API.post(
    `${API_URL}/teams/add-new-team`,
    {
      ...body,
    },
    true,
  )
  return res
}

async function getAllTeamsPagination(params) {
  const res = await API.get(
    `${API_URL}/teams/allTeamsPagination/${params.offset}/${params.limit}/${params.search}`,
    true,
  )
  return res
}

async function getTeamById(id) {
  const res = await API.get(`${API_URL}/teams/getTeamById/${id}`, true)
  return res
}

export default {
  getUsersTeams,
  getTeamsUserIsNotIn,
  addNewTeam,
  getAllTeamsPagination,
  getTeamById,
}
