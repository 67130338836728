import classNames from 'classnames'
import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core'

import LearningHeader from 'components/learning/LearningHeader'
import useMedia from 'hooks/useMedia'
import MainLayout from 'layouts/MainLayout'

const useStyles = makeStyles({
  learningLayout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    minHeight: 0,
    // width: '100%'
  },
  contentDesktop: {},
  contentBody: {
    flex: 1,
  },
  learningHeaderDesktop: {
    padding: '3rem 0rem 1.5rem 1.5rem',
  },
})

interface LearningLayoutProps {
  onPhaseChange?: Function
  leftSideBar?: any
  children: any
}

const LearningLayout: FC<LearningLayoutProps> = ({ children, onPhaseChange, leftSideBar }) => {
  const classes = useStyles()
  const { md: isDesktop } = useMedia()

  return (
    <MainLayout>
      <div className={classes.learningLayout}>
        <div className={classNames(classes.content, isDesktop && classes.contentDesktop)}>
          {isDesktop && leftSideBar}
          <div className={classes.contentBody}>
            <div className={classNames(isDesktop && classes.learningHeaderDesktop)}>
              <LearningHeader onPhaseChange={onPhaseChange} />
            </div>
            {children}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default LearningLayout
