import React from 'react'
import { makeStyles } from '@material-ui/core'
import c from 'classnames'
import { useHistory } from 'react-router-dom'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

export interface BackButtonProps {
  text?: string
  onClick?: any
  className?: any
}
const useStyles = makeStyles(() => ({
  customButton: {
    borderRadius: '0px',
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    cursor: 'pointer',
    color: '#3032C1',
    marginBottom: 10,
    // background: 'linear-gradient(45deg, #4240bc 30%, #06028C 90%)',
  },
  text: {
    paddingLeft: 5,
  },
}))

const BackButton: React.FC<BackButtonProps> = ({ text, onClick, className }) => {
  const classes = useStyles()
  const history = useHistory()

  function goBack() {
    history.goBack()
  }

  return (
    <div className={c(classes.customButton, className)} onClick={onClick || (() => goBack())}>
      <KeyboardBackspaceIcon />
      <div className={classes.text}>{text}</div>
    </div>
  )
}
export default BackButton
