import React, { useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { StoreContext } from 'App'

function UnProtectedPage({ children }) {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const store = useContext(StoreContext)

  useEffect(() => {
    if (store?.currentUser?.email) {
      history.push('/')
    } else {
      setLoading(false)
    }
  }, [store.currentUser])

  if (loading) {
    return <div />
  }
  return children
}

export default observer(UnProtectedPage)
