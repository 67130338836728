import React from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { CustomButton } from '../../atoms/MaterialButton/MaterialButton'

export interface ModalProps {
  open: boolean
  toggleModal: Function
  title: string
  description?: string
  firstBtnText?: string
  secondBtnText?: string
  firstBtnOnClick?: Function
  secondBtnOnClick?: Function
}

const useStyles = makeStyles(() => ({
  modalContainer: {
    padding: 20,
    maxWidth: '25rem',
  },
  textContainer: {
    marginBottom: 40,
  },
  title: {
    marginBottom: 20,
  },
  description: {
    marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  customModal: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px 0px 20px',
  },
  closeIcon: {
    color: '#ccc',
    cursor: 'pointer',
  },
  paper: {
    '@media (max-width: 600px)': {
      margin: 0,
    },
  },
}))

const MaterialModal: React.SFC<ModalProps> = ({
  open,
  toggleModal,
  title,
  description,
  firstBtnText,
  secondBtnText,
  firstBtnOnClick,
  secondBtnOnClick,
}) => {
  const classes = useStyles()

  return (
    <Dialog open={open || false} onClose={() => toggleModal()}>
      <div className={classes.modalContainer}>
        <div className={classes.textContainer}>
          <div className={classes.title}>
            <h2>{title}</h2>
          </div>
          {description ? <div>{description}</div> : null}
        </div>
        <div className={classes.buttonContainer}>
          <CustomButton onClick={() => firstBtnOnClick()}>{firstBtnText}</CustomButton>
          <CustomButton onClick={() => secondBtnOnClick()}>{secondBtnText}</CustomButton>
        </div>
      </div>
    </Dialog>
  )
}

interface CustomModalProps {
  body: any
  header?: any
  className?: any
  paperClassName?: any
  open: boolean
  toggleModal: Function
  maxWidth?: any
  closeOnOutsideClick?: boolean
  paperWidth?: any
}

const CustomModal: React.FC<CustomModalProps> = ({
  body,
  header,
  className,
  paperClassName,
  open,
  toggleModal,
  maxWidth,
  paperWidth = '100%',
  closeOnOutsideClick = true,
}) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open || false}
      className={className}
      onClose={() => {
        if (closeOnOutsideClick) {
          toggleModal(false)
        }
      }}
      maxWidth={maxWidth || 'lg'}
      PaperProps={{
        style: {
          width: paperWidth,
          borderRadius: '0px'
        },
        classes: { root: paperClassName },
      }}
      classes={{ paper: classes.paper }}
    >
      <div
        className={classes.header}
        style={header ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }}
      >
        {header}
        <CloseIcon className={classes.closeIcon} onClick={() => toggleModal()} />
      </div>
      {body}
    </Dialog>
  )
}

export default MaterialModal
export { CustomModal }
