import React, { FC, useEffect, useState } from 'react'

import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import useAbstractProvider from 'providers/AbstractProvider'
import UserApi from 'api/user'
import MailApi from 'api/mail'
import MaterialSelect from 'components/atoms/MaterialSelect/MaterialSelect'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import useAbstractMutator from 'providers/AbstractMutator'
import { useTranslation } from 'react-i18next'
import AdminLayout from 'layouts/AdminLayout'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { showToast } from 'utils'

const useStyles = makeStyles({
  saveBtn: {
    marginTop: 15,
  },
  heading2: {
    marginTop: 45,
    marginBottom: 20,
  },
})

interface AdminSendEmailProps {}

const AdminSendEmail: FC<AdminSendEmailProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data } = useAbstractProvider(UserApi.getAllUsers)
  const sendInvitationEmailMutator = useAbstractMutator(MailApi.sendInvitationEmail)
  const sendEmailToNewUsersMutator = useAbstractMutator(MailApi.sendEmailToNewUsers)

  const [daysBefore, setDaysBefore] = useState('3')
  const [selectedUser, setSelectedUser] = useState('')
  const [options, setOptions] = useState()

  useEffect(() => {
    if (data) {
      const newOptions = data.map((user) => ({ value: user.id, name: user.email }))
      setOptions(newOptions)
    }
  }, [data])

  useEffect(() => {
    if (sendInvitationEmailMutator.error) {
      showToast(sendInvitationEmailMutator.error.data.message || t('error_occured'))
    }
  }, [sendInvitationEmailMutator.error])

  useEffect(() => {
    if (sendEmailToNewUsersMutator.error) {
      showToast(sendEmailToNewUsersMutator.error.data.message || t('error_occured'))
    }
  }, [sendEmailToNewUsersMutator.error])

  useEffect(() => {
    if (sendInvitationEmailMutator.data) {
      showToast(t('admin_send_email.success_message'))
    }
  }, [sendInvitationEmailMutator.data])

  useEffect(() => {
    if (sendEmailToNewUsersMutator.data) {
      showToast(t('admin_send_email.success_message'))
    }
  }, [sendEmailToNewUsersMutator.data])

  const sendEmail = () => {
    if (selectedUser) {
      sendInvitationEmailMutator.mutate(selectedUser)
    }
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Text variant="h2">{t('admin_send_email.heading')}</Text>
          <Text variant="h4" className={classes.heading2}>
            {t('admin_send_email.send_to_one_user_heading')}
          </Text>
          <Grid container>
            <Grid item xs={12}>
              {options ? (
                <MaterialSelect
                  value={selectedUser}
                  label={t('admin_send_email.user')}
                  onChange={(val) => setSelectedUser(val)}
                  options={options}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                disabled={sendInvitationEmailMutator.loading}
                className={classes.saveBtn}
                onClick={() => sendEmail()}
              >
                {t('admin_send_email.button')}
                {sendInvitationEmailMutator.loading && (
                  <CircularProgress style={{ marginLeft: '1rem' }} size="1rem" />
                )}
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <Text variant="h4" className={classes.heading2}>
                {t('admin_send_email.send_to_new_users_heading')}
              </Text>
            </Grid>

            <Grid item xs={4}>
              <MaterialInput
                type="number"
                label={t('admin_send_email.days_before')}
                name="daysBefore"
                value={daysBefore}
                onChange={setDaysBefore}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                disabled={sendEmailToNewUsersMutator.loading}
                className={classes.saveBtn}
                onClick={() => sendEmailToNewUsersMutator.mutate(daysBefore)}
              >
                {t('admin_send_email.send_to_new_users_button')}
                {sendEmailToNewUsersMutator.loading && (
                  <CircularProgress style={{ marginLeft: '1rem' }} size="1rem" />
                )}
              </CustomButton>
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default AdminSendEmail
