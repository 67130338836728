import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import Text from 'components/atoms/Text/Text'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  modalContainer: {
    padding: 30,
  },
  btns: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    backgroundColor: '#999999',
  },
}))

export interface ConfirmModalProps {
  open: boolean
  toggleModal: () => void
  message: string
  confirmHandler: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  open,
  toggleModal,
  message,
  confirmHandler,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <CustomModal
      open={open}
      maxWidth="sm"
      toggleModal={toggleModal}
      body={
        <div className={classes.modalContainer}>
          <Text variant="body1">{message}</Text>
          <div className={classes.btns}>
            <CustomButton
              onClick={() => {
                confirmHandler()
                toggleModal()
              }}
            >
              {t('ok')}
            </CustomButton>
            <CustomButton onClick={toggleModal} className={classes.cancelBtn}>
              {t('cancel')}
            </CustomButton>
          </div>
        </div>
      }
    />
  )
}

export default ConfirmModal
