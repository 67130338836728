import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

export default function HtmlEditor({
  value,
  onChange,
  className,
  readOnly = false,
  admin = false,
}) {
  const toolbar = admin
    ? 'undo redo | blocks | ' +
      'bold italic forecolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | code |  ' +
      'removeformat | help'
    : 'undo redo | blocks | ' +
      'bold italic | ' +
      'bullist numlist |  ' +
      'removeformat | help'
  return (
    <div className={className}>
      <Editor
        apiKey="7hu4bk6rddmuujbd3gnk747siz4p5yrggt5qsxkhvssi4et8"
        // eslint-disable-next-line no-return-assign
        onEditorChange={(e) => {
          onChange(e)
        }}
        disabled={readOnly}
        value={value}
        init={{
          height: 500,
          menubar: false,
          statusbar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount',
          ],
          toolbar,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </div>
  )
}
