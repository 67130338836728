import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import AuthApi from 'api/auth'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import useAbstractMutator from 'providers/AbstractMutator'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { showToast } from 'utils'

interface ParamsProps {
  token: string
}

const useStyles = makeStyles({
  signInButton: {
    width: '100%',
    marginTop: 10,
  },

  error: {
    color: '#B00020',
  },
})

export interface PasswordChangeProps {}

const PasswordChange: React.SFC<PasswordChangeProps> = () => {
  const { token } = useParams<ParamsProps>()
  const classes = useStyles()
  const {
    data: savePasswordData,
    mutate: savePassword,
    status: savePasswordStatus,
    error: savePasswordError
  } = useAbstractMutator(AuthApi.savePassword)

  const {
    // data: checkValidData,
    mutate: checkValidToken,
    status: checkValidStatus,
  } = useAbstractMutator(AuthApi.checkValidToken)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [error, setError] = useState(null)
  const history = useHistory()
  const { t } = useTranslation()

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (token) {
      checkValidToken({ token })
    }
  }, [token])

  useEffect(() => {
    if (savePasswordData) {
      if (savePasswordStatus === 201) {
        history.push('/sign-in')
      }
    }
  }, [savePasswordData])

  useEffect(()=> {
    if(savePasswordError){
      showToast(savePasswordError?.data?.message)
    }
  },[savePasswordError])

  const handleSavePassword = async () => {
    if (password === repeatPassword) {
      if (password.length >= 6) {
        await executeRecaptcha('change_password')
        savePassword({ password, token })
        setError(null)
      } else {
        setError(t('password_change.password_min_length_error'))
      }
    } else {
      setError(t('password_change.password_match_error'))
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSavePassword()
    }
  }

  return (
    <>
      {checkValidStatus === 400 ? (
        <div>{t('password_change.token_expired')}</div>
      ) : (
        <Grid
          onKeyDown={handleKeyDown}
          spacing={3}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {error ? <div className={classes.error}>{error}</div> : null}
          <Grid item xs={12}>
            <MaterialInput
              type="password"
              label={t('password')}
              name="password"
              value={password}
              onChange={setPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <MaterialInput
              type="password"
              label={t('password_repeat')}
              name="repeatPassword"
              value={repeatPassword}
              onChange={setRepeatPassword}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomButton
              color="secondary"
              className={classes.signInButton}
              onClick={handleSavePassword}
            >
              {t('password_change.button')}
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PasswordChange
