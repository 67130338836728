import React, { useContext, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  makeStyles,
  IconButton,
  Tooltip,
  Button,
} from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import useAbstractProvider from 'providers/AbstractProvider'
import useAbstractMutator from 'providers/AbstractMutator'
import { StoreContext } from 'App'
import ChannelsApi from 'api/channels'
import ConfirmationDialog from 'components/organisms/Dialog/ConfirmationDialog'
import { ExpandMore, HighlightOff, Save, Add, AddCircle, CloudUpload } from '@material-ui/icons'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import { useParams, Link, useHistory } from 'react-router-dom'
import FullScreenLoader from 'components/organisms/FullScreenLoader/FullScreenLoader'
import ProductsApi from 'api/products'
import ConversationGroupsApi from 'api/conversationGroups'
import ConversationsApi from 'api/conversations'
import { LearningMaterialsFullType } from 'stores'
import {ConversationFullDataType} from 'types/Conversation'
import AdminLayout from 'layouts/AdminLayout'
import { showToast } from 'utils'
import { FALLBACK_LANGUAGE } from 'helpers/configuration'
import PhasesApi from 'api/phases'
import { useTranslation } from 'react-i18next'
import HtmlEditor from 'components/organisms/HtmlEditor/HtmlEditor'
import {toast} from 'react-toastify'
import Breadcrumbs from 'components/organisms/CustomBreadcrumbs/CustomBreadcrumbs'
import MaterialSelect from '../components/atoms/MaterialSelect/MaterialSelect'
import AvatarsApi from '../api/avatars'
import VideoGenerationModal
  from '../components/organisms/VideoGenerationModal/VideoGenerationModal'

type ParamsType = {
  channelId: string
  productId: string
  phaseId: string
  conversationGroupId: string
}

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
  },
  buttons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  accordionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 15,
  },
  accordionTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  accordionName: { width: '90%' },
  accordionDetails: {
    display: 'flex',
    position: 'relative',
  },
  accordionPhotoDiv: {
    marginRight: '5%',
  },
  photoUploadImage: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '10px 0 0 0',
    boxShadow: '0px -1px 10px 1px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
  accordionDataDiv: {
    height: '100%',
    width: '100%',
  },
  conversationEditButtonDiv: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    gap: 5,
  },
  conversationEditButton: {
    border: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
  },
  languageChooser: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginRight: 20,
  },
  translationAccordion: {
    width: '100%',
  },
  translationAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  editor: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  label: {},
  fileUploadGrid: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginBottom: 20,
  },
  fileUploadButton: {},
  imagesUploadGrid: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 0,
  },
  imageList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageListItem: {
    width: 250,
    height: 250,
    position: 'relative',
  },
  uploadedImagePreview: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    margin: 1,
    marginTop: 20,
    boxShadow: '0px -2px 20px 2px rgba(0, 0, 0, 0.4)',
  },
  avatarSelect: {
    width: 400,
  },
  buttonsRow: {
    marginBottom: 20,
  },
  avatarPreview: {
    width: 60,
    height: 60,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
})

const defaultValueProperties = {
  assets: '',
  video_file: '',
  name: 'Add New Conversation',
  text: '',
}

const NEW_CONVERSATION_DEFAULT_ID = 1234567890
const IMAGE_UPLOAD_PLACEHOLDER_PHOTO = '/image-upload-placeholder.png'

const [DeleteConfirmationDialog, NewConversationCancelConfirmationDialog] =
  Array(2).fill(ConfirmationDialog)

const Conversations = () => {
  const store = useContext(StoreContext) as {
    cloudfrontUrl: string
    language: string
    languages: string[]
    learningMaterialsFull: LearningMaterialsFullType
    // eslint-disable-next-line no-unused-vars
    setConversationsFullData: (values: ConversationFullDataType[]) => void
    // eslint-disable-next-line no-unused-vars
    getChannelNameById: (channelId: number) => string
    // eslint-disable-next-line no-unused-vars
    getProductNameById: (productId: number) => string
    // eslint-disable-next-line no-unused-vars
    getConversationGroupNameById: (conversationGroupId: number) => string
    // eslint-disable-next-line no-unused-vars
    getPhaseNameById: (phaseId: number) => string
    avatars: any[]
  }

  const { t } = useTranslation()

  const history = useHistory()
  const classes = useStyles()
  const {
    channelId: channelIdParam,
    productId: productIdParam,
    phaseId: phaseIdParam,
    conversationGroupId: conversationGroupIdParam,
  } = useParams<ParamsType>()
  const channelId = Number(channelIdParam)
  const productId = Number(productIdParam)
  const phaseId = Number(phaseIdParam)
  const conversationGroupId = Number(conversationGroupIdParam)

  const [conversations, setConversations] = useState<ConversationFullDataType[] | undefined>()

  const [selectedConversation, setSelectedConversation] = useState<
    ConversationFullDataType | undefined
    >()
  const [selectedConversationOriginal, setSelectedConversationOriginal] = useState<
    ConversationFullDataType | undefined
    >()

  const [expandedConversationId, setExpandedConversationId] = useState<number | undefined>()

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false)
  const [conversationIdToBeDeleted, setConversationIdToBeDeleted] = useState<number | undefined>()

  const [
    isNewConversationCancelConfirmationModalOpen,
    setIsNewConversationCancelConfirmationModalOpen,
  ] = useState(false)

  const [isNewConversationBeingAdded, setIsNewConversationBeingAdded] = useState(false)

  const productProvider = useAbstractProvider(ProductsApi.getProductByCode, null, false)
  const channelProvider = useAbstractProvider(ChannelsApi.getChannelById, null, false)
  const phaseProvider = useAbstractProvider(PhasesApi.getPhaseById, null, false)
  const conversationGroupProvider = useAbstractProvider(
    ConversationGroupsApi.getConversationById,
    null,
    false,
  )

  const conversationsProvider = useAbstractProvider(
    ConversationsApi.getConversationsWithFullData,
    null,
    false,
  )

  const editConversationMutator = useAbstractMutator(ConversationsApi.editConversation)
  const addConversationMutator = useAbstractMutator(ConversationsApi.addConversation)
  const deleteConversationMutator = useAbstractMutator(ConversationsApi.deleteConversation)

  useEffect(() => {
    if (!store.learningMaterialsFull.conversations.length) {
      console.log('Fetch Conversations From DB')
      conversationsProvider.refetch(conversationGroupId)
    } else {
      console.log('Get Conversations from Cache')
      setConversations(
        store.learningMaterialsFull.conversations.filter(
          (item) => item.conversationGroupId === conversationGroupId,
        ),
      )
    }
  }, [])

  useEffect(() => {
    if (channelProvider.status === 400) {
      showToast(t('admin_learning_panel.invalid_channel_id'))
      history.push('/')
    } else if (productProvider.status === 400) {
      showToast(t('admin_learning_panel.invalid_product_id'))
      history.push('/')
    } else if (phaseProvider.status === 400) {
      showToast('Invalid Phase Id')
      history.push('/')
    } else if (conversationGroupProvider.status === 400) {
      showToast(t('admin_learning_panel.invalid_conversation_group_id'))
      history.push('/')
    }
  }, [
    productProvider.status,
    channelProvider.status,
    conversationGroupProvider.status,
    phaseProvider.status,
  ])



  useEffect(() => {
    if (conversationsProvider.data) {
      store.setConversationsFullData(conversationsProvider.data)
      setConversations(
        conversationsProvider.data.filter(
          (item) => item.conversationGroupId === conversationGroupId,
        ),
      )
    }
  }, [conversationsProvider.data])

  useEffect(() => {
    if (expandedConversationId) {
      const convo = conversations.find((item) => item.id === expandedConversationId)
      setSelectedConversation(convo)
      setSelectedConversationOriginal(JSON.parse(JSON.stringify(convo)))
    }
  }, [expandedConversationId])

  useEffect(() => {
    if (
      editConversationMutator.data ||
      addConversationMutator.data ||
      deleteConversationMutator.data
    ) {
      setIsNewConversationBeingAdded(false)
      conversationsProvider.refetch(conversationGroupId)
      setExpandedConversationId(undefined)
    }
  }, [editConversationMutator.data, addConversationMutator.data, deleteConversationMutator.data])

  // fetch options for selectboxes
  const { data: avatars } = useAbstractProvider(AvatarsApi.getAvatars )

  const handleConversationExpand = (conversationid: number) => {
    if (isNewConversationBeingAdded) {
      setIsNewConversationCancelConfirmationModalOpen(true)
    } else {
      setExpandedConversationId((existingId) =>
        existingId === conversationid ? undefined : conversationid,
      )
    }
  }

  const handleDelete = () => {
    deleteConversationMutator.mutate(conversationIdToBeDeleted)
  }

  const handleConversationAddingCancel = () => {
    setConversations((arr) => arr.slice(0, -1))
    setIsNewConversationBeingAdded(false)
    setExpandedConversationId(undefined)
  }

  const handleEdit = () => {
    if (
      Object.keys(selectedConversation.translations).some(
        (item) =>
          selectedConversation.translations[item].name === '' ||
          selectedConversation.translations[item].text === ''
      )
    )
      return showToast(t('fill_everything'))

    const changedFields: { lang: string; field: string }[] = []
    Object.keys(selectedConversation.translations).forEach((lang) => {
      Object.keys(selectedConversation.translations[lang]).forEach((field) => {
        const isFieldChanged =
          JSON.stringify(selectedConversation.translations?.[lang]?.[field]) !==
          JSON.stringify(selectedConversationOriginal.translations?.[lang]?.[field])
        if (isFieldChanged) {
          changedFields.push({ lang, field })
        }
      })
    })

    const changedVideos = []
    avatars.forEach((avatar) => {
      const currentAvatarVideo = selectedConversation.videos.find((video) => video.avatarId === avatar.id)
      const originalAvatarVideo = selectedConversationOriginal.videos.find((video) => video.avatarId === avatar.id)

      if (currentAvatarVideo) {
        if (JSON.stringify(currentAvatarVideo) !== JSON.stringify(originalAvatarVideo)) {
          changedVideos.push(currentAvatarVideo)
        }
        return
      }
      changedVideos.push({
        ...originalAvatarVideo,
        deleted: true
      })
    })

    if (!changedFields.length && !changedVideos.length) return showToast(t('admin_learning_panel.nothing_has_changed'))

    const data = new FormData()

    const imageUrls = []

    // TODO: assets will probably never be strings
    changedFields.forEach(({ lang, field }) => {
      const newValue = selectedConversation.translations[lang][field]
      switch (field) {
        case 'name':
        case 'text':
          data.append(`${lang}#${field}`, String(newValue))
          data.append(`translations[${lang}][${field}]`, String(newValue))
          break
        case 'assets':
          if (typeof newValue === 'string') {
            imageUrls.push(newValue)
          } else {
            data.append('files[]', newValue, `${lang}#imageFile`)
          }
          break
        default:
          break
      }
    })

    changedVideos.forEach((video, index) => {
      data.append(`videos[${index}][avatarId]`, video.avatarId)
      data.append(`videos[${index}][languageCode]`, video.languageCode)
      data.append(`videos[${index}][gender]`, video.gender)
      if (video.deleted) {
        data.append(`videos[${index}][deleted]`, video.deleted)
      }
      if (video.video_file) {
        data.append(`videos[${index}][file]`, video.video_file)
      }
      else if (video.script) {
        data.append(`videos[${index}][script]`, video.script)
      }
    })

    Object.keys(selectedConversation.translations).forEach((lang) => {
      const { assets } = selectedConversation.translations[lang]
      if (typeof assets === 'string') {
        imageUrls.push(assets)
      }
    })

    if (imageUrls.length) {
      data.append('imageUrls', JSON.stringify(imageUrls))
    }

    editConversationMutator.mutate(expandedConversationId, data)
    return null
  }

  const handleAdd = () => {
    if (
      Object.keys(selectedConversation.translations).some(
        (item) =>
          selectedConversation.translations[item].name === '' ||
          selectedConversation.translations[item].text === ''
      )
    )
      return showToast(`${t('fill_everything')}`)

    // if there is some video without uploaded or (to be) generated video
    if (selectedConversation.videos.some((video) =>
      (!video.video_file && !video.script)
    )) return showToast(`${t('fill_everything')} (${t('admin_learning_panel.videos')})`)

    if (
      selectedConversation.videos.map((video) => video.languageCode).some(
        (item) =>
          !selectedConversation.videos.some((video) => video.languageCode === item && video.gender === 'male') ||
          !selectedConversation.videos.some((video) => video.languageCode === item && video.gender === 'female')
      )
    )
      return showToast(t('admin_learning_panel.video_both_genders_required'))

    const formData = new FormData()

    formData.append('channelId', String(channelId))
    formData.append('phaseId', String(phaseId))
    formData.append('productId', String(productId))
    formData.append('conversationGroupId', String(conversationGroupId))
    formData.append('translations', JSON.stringify(selectedConversation.translations))
    Object.keys(selectedConversation.translations).forEach((lang) => {
      // eslint-disable-next-line camelcase
      const { assets } = selectedConversation.translations[lang]
      if (assets) {
        formData.append('files[]', assets, `${lang}#${0}imageFile`)
      }
    })

    formData.append('videos', JSON.stringify(selectedConversation.videos))
    selectedConversation.videos.forEach((video, index) => {
      if (video.video_file) {
        formData.append(`videos[${index}][file]`, video.video_file)
      }
    })

    addConversationMutator.mutate(formData)
    return null
  }

  useEffect(() => {
    if (typeof(addConversationMutator.status) === 'number') {
      if (addConversationMutator.status >= 200 && addConversationMutator.status <= 299) {
        toast.success(t('success'))
      } else {
        toast.error(t('error'))
      }
    }
  }, [addConversationMutator.status])

  useEffect(() => {
    if (!editConversationMutator.loading && typeof(editConversationMutator.status) === 'number') {
      if (editConversationMutator.status >= 200 && editConversationMutator.status <= 299) {
        toast.success(t('success'))
      } else {
        toast.error(t('error'))
      }
    }
  }, [editConversationMutator.loading, editConversationMutator.status])

  useEffect(() => {
    if (typeof(deleteConversationMutator.status) === 'number') {
      if (deleteConversationMutator.status >= 200 && deleteConversationMutator.status <= 299) {
        toast.success(t('success'))
      } else {
        toast.error(t('error'))
      }
    }
  }, [deleteConversationMutator.status])

  const handleConversationAdd = () => {
    if (isNewConversationBeingAdded) {
      return handleAdd()
    }
    setConversations((arr) => [
      ...arr,
      {
        id: NEW_CONVERSATION_DEFAULT_ID,
        conversationGroupId,
        translations: {
          [FALLBACK_LANGUAGE]: defaultValueProperties,
        },
        videos: []
      } as ConversationFullDataType,
    ])
    setIsNewConversationBeingAdded(true)
    setExpandedConversationId(NEW_CONVERSATION_DEFAULT_ID)
    return null
  }

  const handleNameChange = (newName: string, lang: string) => {
    setSelectedConversation((obj) => ({
      ...obj,
      translations: {
        ...obj.translations,
        [lang]: { ...obj.translations[lang], name: newName },
      },
    }))
  }

  const handleConversationTextChange = (value: string, lang: string) => {
    setSelectedConversation((obj) => ({
      ...obj,
      translations: {
        ...obj.translations,
        [lang]: { ...obj.translations[lang], text: value },
      },
    }))
  }

  const handleFileChange = (
    e: any,
    fileType: 'audio_file' | 'motion_file' | 'assets',
    lang: string,
  ) => {
    setSelectedConversation((obj) => ({
      ...obj,
      translations: {
        ...obj.translations,
        [lang]: { ...obj.translations[lang], [fileType]: e.target.files[0] },
      },
    }))
  }

  const handleVideoChange = (
    e: any,
    avatar: any
  ) => {
    const file = e.target.files[0]
    const updatedVideo = selectedConversation.videos.find((video) => video.avatarId === avatar.id)
    updatedVideo.video_file = file
    setSelectedConversation((obj) => ({
      ...obj,
      videos: selectedConversation.videos
    }))
  }

  const getVideoLabel = (avatar: any) => {
    const conversationVideo = selectedConversation.videos.find((video) => video.avatarId === avatar.id)

    if (conversationVideo.video_file === null && conversationVideo.script)
      return <Text variant="body2">{t('admin_learning_panel.generate_scheduled')}</Text>
    if (conversationVideo.video_file?.length === 36 && !conversationVideo.video_file.includes('.mp4'))
      return <Text variant="body2">{t('admin_learning_panel.generate_in_progress')}</Text>
    if (conversationVideo.video_file?.name)
      return <Text variant="body2">{conversationVideo.video_file?.name}</Text>
    return <Link to={{ pathname: `${store.cloudfrontUrl}/videos/${conversationVideo.video_file}`}} target="_blank">{conversationVideo.video_file}</Link>
  }

  const getImageSource = (lang) => {
    const photo = selectedConversation.translations[lang].assets
    if (!photo) return null
    if (typeof photo === 'string') return `${store.cloudfrontUrl}/${photo}`
    return URL.createObjectURL(photo)
  }


  const [selectedAvatar, setSelectedAvatar] = useState(null)

  const [videoGenerationModalStatus, setVideoGenerationModalStatus] = useState(false)
  const [modalAvatar, setModalAvatar] = useState(null)
  const [modalVideo, setModalVideo] = useState(null)

  const saveScriptFunction = (script: string) => {
    const videosCloned = [...selectedConversation.videos]
    const updatedVideo = videosCloned.find((video) => video.avatarId === modalAvatar.id)
    updatedVideo.script = script
    updatedVideo.video_file = null

    setSelectedConversation((obj) => ({
      ...obj,
      videos: videosCloned,
    }))
  }

  const getAvatarOptions = () => {
    const usedAvatarIds = selectedConversation?.videos.map((video) => video.avatarId)
    return store.avatars
      .filter((avatar) => !(selectedConversation?.videos.map((video) => video.avatarId) ?? []).includes(avatar.id))
      .map((avatar) => {
          // we can show used avatars in selectbox as disabled instead of not showing them at all
          const alreadyUsed = usedAvatarIds.includes(avatar.id)
          return {
            name: `${avatar.name} (${t(avatar.gender)}, ${avatar.language})`,
            // value: avatar.id,
            value: avatar,
            // disabled: alreadyUsed
          }
        }
      )
  }

  const handleVideoAdd = () => {
    // TODO: check if video with selectedAvatar already exists
    setSelectedConversation((obj) => ({
      ...obj,
      videos: [
        ... selectedConversation.videos,
        {
          avatarId: selectedAvatar.id,
          gender: selectedAvatar.gender,
          languageCode: selectedAvatar.language,
          conversationId: selectedConversation.id,
          video_file: null,
        }
      ]
    }))
    setSelectedAvatar(null)
  }

  const handleVideoRemove = (avatarId: string) => {
    setSelectedConversation((obj) => ({
      ...obj,
      videos: selectedConversation.videos.filter((video) => video.avatarId !== avatarId)
    }))
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <div className={classes.container}>
            {!conversations ? (
              <FullScreenLoader />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={10}>
                    <Breadcrumbs/>
                    <Text variant="h3">{t('admin_learning_panel.conversations')}</Text>

                  </Grid>
                  <Grid item xs={2} className={classes.buttons}>
                    <IconButton onClick={handleConversationAdd} color="primary" component="span" >
                      <AddCircle />
                    </IconButton>
                  </Grid>
                  <hr />
                  <hr />
                  <Grid item xs={12}>
                    {conversations.map((conversation) => (
                      <Accordion
                        className={classes.translationAccordion}
                        key={conversation.id}
                        expanded={expandedConversationId === conversation.id}
                        disabled={editConversationMutator.loading || addConversationMutator.loading}
                      >
                        <AccordionSummary
                          IconButtonProps={{
                            onClick: () => handleConversationExpand(conversation.id),
                          }}
                          expandIcon={<ExpandMore />}
                        >
                          <div className={classes.accordionHeader}>
                            <div className={classes.accordionName}>
                              <Text variant="h4">
                                {conversation.translations[store.language]?.name ||
                                  conversation.translations[FALLBACK_LANGUAGE]?.name}
                              </Text>
                            </div>
                          </div>
                          <div className={classes.accordionTooltip}>
                            {conversation.id === NEW_CONVERSATION_DEFAULT_ID ? (
                              <Tooltip placement="top" title={t('add')}>
                                <IconButton onClick={handleAdd} size="small">
                                  <Add fontSize="medium" color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : expandedConversationId === conversation.id ? (
                              <Tooltip placement="top" title={t('edit')}>
                                <IconButton onClick={handleEdit} size="small">
                                  <Save fontSize="medium" color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <Tooltip placement="top" title={t('delete')}>
                              <IconButton
                                onClick={() => {
                                  if (isNewConversationBeingAdded) {
                                    setIsNewConversationCancelConfirmationModalOpen(true)
                                  } else {
                                    setConversationIdToBeDeleted(conversation.id)
                                    setIsDeleteConfirmationModalOpen(true)
                                  }
                                }}
                                size="small"
                              >
                                <HighlightOff fontSize="medium" color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                          {conversation.id === expandedConversationId && selectedConversation && (
                            <>
                              {expandedConversationId && (
                                <Grid container spacing={2} style={{ paddingBottom: '1rem' }}>
                                  <Grid xs={12} item>
                                    <MaterialInput
                                      disabled={
                                        editConversationMutator.loading ||
                                        addConversationMutator.loading
                                      }
                                      value={selectedConversation.translations.de.name}
                                      size="small"
                                      onChange={(value) => handleNameChange(value, 'de')}
                                      type="text"
                                      label={t('name')}
                                      name="name"
                                    />
                                  </Grid>
                                  <Grid xs={12} item>
                                    <HtmlEditor
                                      admin
                                      className={classes.editor}
                                      value={selectedConversation.translations.de.text}
                                      onChange={(value: string) => {
                                        if (value !== '<p><br></p>') {
                                          handleConversationTextChange(value, 'de')
                                        }
                                      }}
                                      readOnly={
                                        editConversationMutator.loading ||
                                        addConversationMutator.loading
                                      }
                                    />
                                  </Grid>
                                  <Grid xs={12} item >
                                    <Grid xs={12} item>
                                      <Text variant="h4" className={classes.label}>
                                        {t('admin_learning_panel.videos')}
                                      </Text>
                                    </Grid>
                                    <Grid xs={12} item className={classes.editor}>
                                      <MaterialSelect
                                        fullWidth={false}
                                        disabled={false}
                                        label={t('admin_learning_panel.avatar')}
                                        name="avatar"
                                        value={selectedAvatar}
                                        onChange={setSelectedAvatar}
                                        options={getAvatarOptions()}
                                        className={classes.avatarSelect}
                                        // renderValue={(selected) => selected}
                                      />
                                      <Tooltip title="Add avatar video">
                                        <IconButton disabled={!selectedAvatar} onClick={handleVideoAdd} color="primary" component="span" >
                                          <AddCircle />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                    {selectedConversation.videos.map((conversationVideo) => {
                                      const avatar = avatars.find((a) => a.id === conversationVideo.avatarId)
                                      return (
                                        <>
                                          <Grid  item xs={12}>
                                            <Text variant="h5">
                                              Video {`${avatar.name} (${t(avatar.gender)}, ${avatar.language})`}
                                            </Text>
                                          </Grid>
                                          <Grid className={classes.fileUploadGrid} item xs={12}>
                                            <Button
                                              disabled={editConversationMutator.loading ||
                                                addConversationMutator.loading}
                                              className={classes.fileUploadButton}
                                              variant="contained"
                                              component="label"
                                              color="primary"
                                            >
                                              {t('admin_learning_panel.upload_video')}
                                              <input
                                                disabled={editConversationMutator.loading ||
                                                  addConversationMutator.loading}
                                                onChange={(e) => handleVideoChange(e, avatar)}
                                                type="file"
                                                hidden
                                                accept=".mp4"/>
                                            </Button>
                                            <Button
                                              startIcon={<CloudUpload/>}
                                              disabled={editConversationMutator.loading ||
                                                addConversationMutator.loading}
                                              className={classes.fileUploadButton}
                                              variant="contained"
                                              component="label"
                                              color="primary"
                                              onClick={() => {
                                                setVideoGenerationModalStatus(!videoGenerationModalStatus)
                                                setModalAvatar(avatar)
                                                setModalVideo(selectedConversation.videos.find((video) => video.avatarId === avatar.id))
                                              }}
                                            >
                                              {t('admin_learning_panel.generate_via_api')}
                                            </Button>
                                            {getVideoLabel(avatar)}
                                            <Tooltip placement="top" title={`${t('delete')} video`}>
                                              <IconButton
                                                onClick={() => handleVideoRemove(avatar.id)}
                                                size="small"
                                              >
                                                <HighlightOff fontSize="medium" color="secondary" />
                                              </IconButton>
                                            </Tooltip>
                                          </Grid></>
                                      )})
                                    }
                                  </Grid>
                                  <Grid xs={12} item>
                                    <Text variant="h4" className={classes.label}>
                                      {t('image')}
                                    </Text>
                                    <Grid
                                      className={`${classes.fileUploadGrid} ${classes.imagesUploadGrid}`}
                                      item
                                      xs={12}
                                    >
                                      <Button
                                        disabled={
                                          editConversationMutator.loading ||
                                          addConversationMutator.loading
                                        }
                                        className={classes.fileUploadButton}
                                        variant="contained"
                                        component="label"
                                        color="primary"
                                      >
                                        {t('upload_file')}
                                        <input
                                          disabled={
                                            editConversationMutator.loading ||
                                            addConversationMutator.loading
                                          }
                                          onChange={(e) => {
                                            handleFileChange(e, 'assets', 'de')
                                            e.target.value = ''
                                          }}
                                          type="file"
                                          hidden
                                          accept=".png, .jpg, .jpeg"
                                        />
                                      </Button>
                                      <div className={classes.imageListItem}>
                                        <img
                                          className={classes.uploadedImagePreview}
                                          src={
                                            getImageSource('de') || IMAGE_UPLOAD_PLACEHOLDER_PHOTO
                                          }
                                          alt="add"
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                              <div className={classes.conversationEditButtonDiv}>
                                {editConversationMutator.loading ||
                                addConversationMutator.loading ? (
                                  <CircularProgress size="1.5rem" />
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                </Grid>
              </>
            )}
          </div>
          <DeleteConfirmationDialog
            open={isDeleteConfirmationModalOpen}
            handleClose={() => setIsDeleteConfirmationModalOpen(false)}
            title={`${t('admin_learning_panel.delete_conversation')} ${
              conversations?.find((item) => item.id === conversationIdToBeDeleted)?.translations[
                store.language
                ]?.name ||
              conversations?.find((item) => item.id === conversationIdToBeDeleted)?.translations[
                FALLBACK_LANGUAGE
                ]?.name
            } ?`}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleDelete}
          />
          <NewConversationCancelConfirmationDialog
            open={isNewConversationCancelConfirmationModalOpen}
            handleClose={() => setIsNewConversationCancelConfirmationModalOpen(false)}
            title={t('admin_learning_panel.delete_conversation')}
            description=""
            actionOnDeny={() => {}}
            actionOnConfirm={handleConversationAddingCancel}
          />

          {modalAvatar &&
              <VideoGenerationModal
                  modalStatus={videoGenerationModalStatus}
                  setModalStatus={setVideoGenerationModalStatus}
                  avatar={modalAvatar}
                  conversationId={selectedConversation.id}
                  conversationName={selectedConversation.translations.de.name}
                  saveScriptHandler={saveScriptFunction}
                  video={modalVideo}
              />
          }
        </>,
      )}
    </ProtectedPage>
  )
}

export default Conversations
