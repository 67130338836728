import { StepButton, Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Step from '@material-ui/core/Step'
import Stepper from '@material-ui/core/Stepper'
import StepLabel from '@material-ui/core/StepLabel'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { observer } from 'mobx-react-lite'
import React, { FC, useContext } from 'react'
import { StoreContext } from 'App'
import useMedia from 'hooks/useMedia'
import c from 'classnames'
import { setLocalStorage } from 'utils'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  stepper: {
    padding: 0,
    background: 'transparent',
    justifyContent: 'space-between',
  },
  icon: {
    width: 60,
    height: 60,
    zIndex: 1,
    color: '#00008f',
  },
  step: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  activeIcon: {
    color: '#f07662!important',
  },
  connectorLine: {
    width: '0px',
    height: '0px',
    flex: 0,
  },
  stepButton: {
    margin: 0,
    padding: 0,
    textAlign: 'left',
    // width: 220,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  stepLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stepLabelText: {
    color: '#7F7F7F'
  },
  stepLabelRoot: {
    width: '100%',
  },
  stepName: {
    color: 'rgba(0, 0, 0, 0.54)',
    '&active': {
      color: 'color: #333333',
    },
    maxWidth: '100px',
  },
  stepNameActive: {
    color: '#333333',
    maxWidth: '100px',
  },
  circle: {
    background: '#00008f',
    color: 'white',
    borderRadius: 9999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '60px',
    fontSize: '40px',
    fontFamily: '"Open Sans",Helvetica,Arial,sans-serif',
    zIndex: 1,
    marginRight: 8,
  },
  circleActive: {
    background: theme.palette.info.main,
  },
}))

export const STEPS = [
  ['Innere', 'Einstellung'],
  ['Termine'],
  ['Bedarfsanalyse', 'Argumentieren'],
  ['Preisverhandlung', 'Abschluss'],
]

interface LearningHeaderProps {
  onPhaseChange: Function
}

const LearningHeader: FC<LearningHeaderProps> = ({ onPhaseChange }) => {
  const history = useHistory()

  const classes = useStyles()
  const store = useContext(StoreContext)

  const onStepClick = (phaseId) => {
    store.setPhase(phaseId)
    setLocalStorage('selectedPhase', phaseId)
    history.push(`/learn/channelId=${store.selectedChannel.id}/productId=${store.selectedProductCategory.id}/phaseId=${phaseId}`)
  }

  const { md: isDesktop } = useMedia()

  const formatPhaseName = (name: string) => {
    let formatted = name
    if (name.length >= 35) formatted = `${formatted.slice(0, 35)}...`
    return formatted
  }

  return (
    <>
      {!isDesktop && (
        <Box marginY="1rem">
          <Typography variant="h3">{store?.selectedChannel?.code}</Typography>
        </Box>
      )}
      <Stepper
        className={classes.stepper}
        nonLinear
        activeStep={(store.productPhases || []).findIndex(
          (currentPhase) => currentPhase.id === store.phase,
        )}
      >
        {(store.productPhases || []).map((phase, index) => (
          <React.Fragment key={phase.id}>
            <Step className={classes.step}>
              <StepButton
                className={classes.stepButton}
                onClick={() => {
                  onStepClick(phase.id)
                  onPhaseChange()
                }}
              >
                <StepLabel classes={{ label: classes.stepLabel, root: classes.stepLabelRoot }}>
                  <div
                    className={c(
                      classes.circle,
                      store.phase === phase.id ? classes.circleActive : null,
                    )}
                  >
                    {index + 1}
                  </div>
                  {isDesktop && (
                    <div>
                      <div
                        className={
                          store.phase === phase.id ? classes.stepNameActive : classes.stepName
                        }
                      >
                        <Tooltip title={phase.name}>
                          <span className={classes.stepLabelText}>{formatPhaseName(phase.name)}</span>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </StepLabel>
              </StepButton>
            </Step>
            {isDesktop && index !== store.productPhases.length - 1 ? (
              <ArrowForwardIosIcon style={{ color: '#999999' }} />
            ) : null}
          </React.Fragment>
        ))}
      </Stepper>
    </>
  )
}

export default observer(LearningHeader)
