import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { StoreContext } from 'App'
import {useTranslation} from 'react-i18next'
import MaterialSelect from '../../atoms/MaterialSelect/MaterialSelect'
import Text from '../../atoms/Text/Text'

// export interface ChannelSelectboxProps {
//   channel: string
//   setChannel: (channel) => void
// }

const ChannelSelectbox = ({
  channel,
  setChannel,
}) => {
  const { t } = useTranslation()
  const store = useContext(StoreContext) as any

  return (
    <>
      <Grid item xs={12}>
        <Text variant="h4">{t('channel')}</Text>
      </Grid>
      <Grid item xs={12}>
        <MaterialSelect
          value={channel ?? ''}
          onChange={(channelId) => setChannel(channelId)}
          options={[
            {name: t('mentor_dashboard.all_channels'), value: ''},
            ...store.channels.map(({id, name}) => ({name, value: id}))
          ]}
          displayEmpty
        />
      </Grid>
    </>
  )
}

export default observer(ChannelSelectbox)
