import PublicoHeadline from 'fonts/PublicoHeadline-Bold-Web.woff'
import PublicoHeadline2 from 'fonts/PublicoHeadline-Bold-Web.woff2'
import OpenSans7002 from 'fonts/open-sans-v35-latin-700.woff2'
import OpenSansItalic2 from 'fonts/open-sans-v35-latin-italic.woff2'
import OpenSansRegular2 from 'fonts/open-sans-v35-latin-regular.woff2'

const OpenSans700 = require('fonts/open-sans-v35-latin-700.ttf')
const OpenSansItalic = require('fonts/open-sans-v35-latin-italic.ttf')
const OpenSansRegular = require('fonts/open-sans-v35-latin-regular.ttf')
const PublicoHeadlineTTF = require('fonts/PublicoHeadline-Bold-Web.ttf')


const publicoHeadlineBold = {
  fontFamily: 'PublicoHeadline',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `
	local('PublicoHeadlineBoldWeb'),
	local('PublicoHeadline-SemiBold-Web'),
	url(${PublicoHeadline2}) format('woff2'),
	url(${PublicoHeadline}) format('woff')
	url(${PublicoHeadlineTTF}) format('truetype')
	`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
} as any

const openSansRegular = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '400',
  fontStretch: '100%',
  src: `
  url(${OpenSansRegular2}) format('woff2'),
  url(${OpenSansRegular}) format('truetype')
  `,
} as any

const openSansItalic = {
  fontFamily: 'Open Sans',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: '400',
  fontStretch: '100%',
  src: `
  url(${OpenSansItalic2}) format('woff2'),
  url(${OpenSansItalic}) format('truetype')
	`,
} as any

const openSansBold = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `
  url(${OpenSans7002}) format('woff2'),
  url(${OpenSans700}) format('truetype')
	`,
} as any

export { publicoHeadlineBold, openSansRegular, openSansBold, openSansItalic }
