import { API_URL } from '../helpers/configuration'
import Api from '.'

const API = Api()

async function getConversationsByProductId(productId) {
  const res = await API.get(`${API_URL}/conversations/${productId}`, true)
  return res
}

const getConversationsWithFullData = async () => {
  const res = await API.get(`${API_URL}/conversations/full-data`, true)
  return res
}

const addConversation = async (data) => {
  const res = await API.post(`${API_URL}/conversations/add`, data, true)
  return res
}

const editConversation = async (conversationId, data) => {
  const res = await API.post(`${API_URL}/conversations/${conversationId}`, data, true)
  return res
}

const deleteConversation = async (conversationId: number) => {
  const res = await API.remove(`${API_URL}/conversations/${conversationId}`, true)
  return res
}

const ConversationsApi = {
  getConversationsByProductId,
  addConversation,
  editConversation,
  getConversationsWithFullData,
  deleteConversation,
}

export default ConversationsApi
