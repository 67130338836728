import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core'
import MainLayout from 'layouts/MainLayout'

const useStyles = makeStyles({
  authLayoutContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: '#FAFAFA',
  },
})

export interface AuthLayoutProps {}

const AuthLayout = ({ children }) => {
  const classes = useStyles()

  return (
    <MainLayout>
      <div className={classes.authLayoutContainer}>
        {children}
      </div>
    </MainLayout>
  )
}

AuthLayout.getLayout = (page): ReactElement => <AuthLayout>{page}</AuthLayout>

export default AuthLayout
